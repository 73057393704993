import Cookies from 'js-cookie';

import { BOOT_USER, LANG_SET, PT, SET_FEATURE_TOGGLES, SET_SPACEID_PREFERENCE } from 'actions/userActions';
import lang from 'lang';

const defaultLang = Cookies.get('session_lang') || PT;
lang.setLanguage(defaultLang);

const initialState = {
  loggedIn: false,
  data: {},
  lang: defaultLang,
};

lang.setLanguage(initialState.lang);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOT_USER:
      return {
        ...state,
        loggedIn: true,
        data: action.data,
      };
    case LANG_SET:
      return {
        ...state,
        lang: action.lang,
      };
    case SET_FEATURE_TOGGLES:
      return {
        ...state,
        data: {
          ...state.data,
          featureToggles: action.featureToggles,
        },
      };
    case SET_SPACEID_PREFERENCE:
      return {
        ...state,
        data: {
          ...state.data,
          spaceIdPreference: action.spaceId,
        },
      };
    default:
      return state;
  }
};

export default reducer;
