import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  dropdownToggler: {
    backgroundColor: theme.sidebarColor,
    height: '54px',
    width: '187px',
    boxShadow: '0px 4px 4px #0000004D',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  currentSpace: {
    gap: '9px',
    marginLeft: '16px',
    fontWeight: 600,
    fontSize: '14px',
    overflow: 'hidden',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: '1',
    paddingBottom: '1px',
  },
  arrow: {
    display: 'flex',
    fontSize: '10px',
    marginRight: '15px',
  },
  dropdownMenu: {
    width: '100%',
    border: 'none !important',
    borderRadius: '6px',
    backgroundColor: `${theme.sidebarColor} !important`,
    top: 'calc(100% + 9px)',
    boxShadow: '0px 4px 4px #0000004D',
    zIndex: '1',
    overflow: 'hidden',
  },
  dropdownMenuItem: {
    borderBottom: 'none !important',
    margin: '0 !important',
    fontSize: '12px',
    display: 'flex',
    gap: '0.25em',
    '&:nth-last-of-type(1)': {
      borderBottom: 'none !important',
    },
    '&:hover': {
      backgroundColor: `${theme.shade0} !important`,
    },
  },
  iconSlot: {
    width: '20px',
  },
  separator: {
    margin: '3px 0',
    borderBottom: `1px solid ${theme.textColorContrast2}`,
    opacity: 0.1,
  },
  premiumBadgeIcon: {
    color: theme.premiumColor,
    marginRight: '9px',
    transform: 'scale(1.1)',
  },
}));
