import React, { useCallback, useRef, useState } from 'react';
import { useToast, Form, InputText, useForm, FormItem } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import Modal from 'components/common/Modal';

const CreateClassModal = ({ open, close }) => {
  const schoolId = useSelector(selectUserSpaceId);
  const [createSchoolClassRequest] = useApi(api.createClass);
  const iaveToggle = useFeature(toggles.iave);
  const toast = useToast();
  const history = useHistory();
  const form = useForm();

  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const _close = useCallback(() => {
    setTimeout(() => {
      form.reset();
    }, 350);
    close();
  }, [close, form]);

  const onValid = async () => {
    setLoading(true);
    const request = {
      params: {
        schoolId,
      },
      data: {
        name: form.getValues('className'),
      },
    };

    createSchoolClassRequest(request, ({ data }) => {
      if (data.status === 0) {
        history.push(routes.class.ref(data.class.id));
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        setLoading(false);
        toast.error(lang.oops);
      }
    });
  };

  const actions = [
    {
      name: lang.cancel,
      onClick: _close,
      color: 'black',
      loading,
    },
    {
      name: lang.confirm,
      onClick: () => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true })),
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.classes[iaveToggle ? 'iave' : 'schools'].createClass}
      actions={actions}
      center
      transition
      small
    >
      <Form
        {...form}
        onValid={onValid}
        _ref={formRef}
      >
        <FormItem
          name="className"
          label={lang.classes[iaveToggle ? 'iave' : 'schools'].classNameLabel}
        >
          <InputText
            placeholder={lang.classes[iaveToggle ? 'iave' : 'schools'].classNameLabel}
            {...form.register('className', {
              required: {
                value: true,
                message: lang.classes[iaveToggle ? 'iave' : 'schools'].classNameEmpty,
              },
            })}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

CreateClassModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default CreateClassModal;
