import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  examActions: {
    padding: '20px 40px 0 40px',
    '& > * + *': {
      marginTop: '20px',
    },
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  card: {
    padding: '10px 20px',
    color: theme.textColor,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}));
