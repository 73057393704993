import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  downloadContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    gap: '30px',
    flexDirection: 'column',
  },
  downloadButton: {
    width: '200px',
  },
  downloadButtonIcon: {
    fontSize: '16px',
    marginRight: '5px',
  },
  addSuccessIcon: {
    color: theme.successColor,
    fontSize: '40px',
  },
  errors: {
    color: theme.errorColor,
  },
}));
