import React, { useCallback, useEffect, useState } from 'react';
import { faUserPlus, faGear } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import AddStudentsModal from 'components/classes/AddStudentsModal';
import ClassSettingsModal from 'components/classes/ClassSettingsModal';
import ClassStudentsList from 'components/classes/ClassStudentsList';
import DeleteClassesModal from 'components/classes/DeleteClassesModal';
import SecondaryPageHeader from 'components/common/layout/SecondaryPageHeader';
import Loading from 'components/common/Loading';

import { useClassContext } from './context';

const ADD_STUDENTS = 'ADD_STUDENTS';
const DELETE = 'DELETE';

const _Class = () => {
  const iaveToggle = useFeature(toggles.iave);
  const history = useHistory();
  const { loaded, pageLoading, setPageTitle, reload } = usePageLogic(iaveToggle ? lang.appKeywords.examGroups : lang.appKeywords.classes, true);
  const [getClassRequest] = useApi(api.getSchoolClass);
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();
  const [deleteClassesModal, setDeleteClassesModal] = useState(false);
  const { classId } = useParams();
  const [selected, setSelected] = useState([classId]);
  const [addStudentsModal, setAddStudentsModal] = useState(false);
  const [classSettingsModal, setClassSettingsModal] = useState(false);
  const [_class, setClass] = useState(null);
  const { fetchStudents } = useClassContext();

  const getClass = useCallback(() => {
    const request = {
      params: {
        schoolId: schoolId,
        classId: classId,
      },
      query: {
        studentCount: 'true',
      },
    };

    getClassRequest(request, ({ data }) => {
      if (data.status === 0) {
        setClass(data.class);
        setPageTitle(data.class.name);
        loaded();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        loaded();
      }
    });
    reload();
  }, [classId, getClassRequest, loaded, schoolId, setPageTitle, toast, reload]);

  useEffect(() => {
    if (pageLoading) {
      getClass();
    }
  }, [pageLoading, getClass]);

  const refresh = useCallback(() => {
    fetchStudents(1);
    getClass();
  }, [fetchStudents, getClass]);

  if (pageLoading) {
    return (
      <Loading active />
    );
  }

  const actions = [
    {
      key: ADD_STUDENTS,
      label: lang.add,
      icon: faUserPlus,
      onClick: () => setAddStudentsModal(true),
    },
    {
      label: lang.settings,
      icon: faGear,
      onClick: () => setClassSettingsModal(true),
    },
  ];

  const extraActions = [
    {
      key: DELETE,
      label: lang.classes.schools.deleteClass,
      onClick: () => setDeleteClassesModal(true),
    },
  ];
  return (
    <>
      <SecondaryPageHeader
        header={_class.name}
        actions={actions}
        extraInformations={[
          {
            label: `${_class.studentCount} ${_class.studentCount > 1 ? lang.appKeywords.students : lang.appKeywords.student}`,
          },
        ]}
        extraActions={extraActions}
      />
      <ClassSettingsModal
        open={classSettingsModal}
        close={() => setClassSettingsModal(false)}
        currentClass={_class}
        reload={getClass}
      />
      <AddStudentsModal
        open={addStudentsModal}
        close={() => setAddStudentsModal(false)}
        classId={classId}
        refresh={refresh}
      />
      <ClassStudentsList getClass={() => getClass()} />
      <DeleteClassesModal
        open={deleteClassesModal}
        close={() => setDeleteClassesModal(false)}
        classIds={selected}
        onSubmit={() => history.push(routes.classes.ref())}
        setSelected={setSelected}
      />
    </>
  );
};

export default _Class;
