import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';

import useStyles from './styles';

const IconBtn = ({ icon, text, tip, onClick, top, left, right, wrapStyle, iconStyle }) => {
  const classes = useStyles();

  return (
    <Tooltip tip={tip} top={top} left={left} right={right}>
      <div className={classes.wrap} style={wrapStyle} onClick={onClick}>
        <FontAwesomeIcon icon={icon} className={classes.icon} style={iconStyle} />
        <Render when={text}>
          <div className={classes.text}>
            {text}
          </div>
        </Render>
      </div>
    </Tooltip>
  );
};

IconBtn.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  tip: PropTypes.string,
  onClick: PropTypes.func,
  top: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  wrapStyle: PropTypes.object,
  iconStyle: PropTypes.object,
};

export default IconBtn;
