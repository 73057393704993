import { ADMIN, SUPERADMIN } from 'constants/roles';
import lang from 'lang';

const PT = 'pt';
const EN = 'en';
const ES = 'esES';
const BR = 'ptBR';

/**
 * Action constants
 */
const BOOT_USER = 'BOOT_USER';
const LANG_SET = 'LANG_SET';
const SET_FEATURE_TOGGLES = 'SET_FEATURE_TOGGLES';
const SET_SPACEID_PREFERENCE = 'SET_SPACEID_PREFERENCE';

/**
 * Action creators
 */
const bootUser = (data) => ({
  type: BOOT_USER,
  data: data,
});

const langSet = (newLang) => ({
  type: LANG_SET,
  lang: newLang,
});

const setFeatureToggles = (featureToggles) => ({
  type: SET_FEATURE_TOGGLES,
  featureToggles: featureToggles,
});

const setSpaceIdPreference = (newSpaceId) => ({
  type: SET_SPACEID_PREFERENCE,
  spaceId: newSpaceId,
});

/**
 * Selectors
 */
const selectUserData = (state) => {
  return state.user.data;
};

const selectUserFeatureToggles = (state) => {
  return state.user.data.featureToggles;
};

const selectSchoolName = (schoolId) => {
  return (state) => {
    const school = state.user.data.schools.find(school => school.id === schoolId);

    return school ? school.name : lang.appKeywords.personalSpace;
  };
};

const selectUserLang = (state) => {
  return state.user.lang;
};

const selectUserIsTeacher = (state) => {
  return state.user.data.isTeacher;
};

const selectUserLoggedIn = (state) => {
  return state.user.loggedIn;
};

const selectIsAdmin = (state) => {
  return state.user.data.role === ADMIN;
};

const selectIsSuperAdmin = (state) => {
  return state.user.data.role === SUPERADMIN;
};

const selectUserSpaceId = (state) => {
  return state.user.data.spaceIdPreference;
};

const selectUserSchool = (state) => {
  return state.user.data.schools.find(school => state.user.data.spaceIdPreference === school.id);
};

export {
  PT,
  EN,
  ES,
  BR,
  BOOT_USER,
  LANG_SET,
  SET_FEATURE_TOGGLES,
  SET_SPACEID_PREFERENCE,
  bootUser,
  langSet,
  setFeatureToggles,
  setSpaceIdPreference,
  selectUserData,
  selectUserFeatureToggles,
  selectSchoolName,
  selectUserLang,
  selectUserIsTeacher,
  selectUserLoggedIn,
  selectIsAdmin,
  selectIsSuperAdmin,
  selectUserSpaceId,
  selectUserSchool,
};
