import React from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import TeacherRow from '../TeacherRow';

import useStyles from './styles';

const TeachersTable = ({ teachers, resetTeachers, setTeachers }) => {
  const classes = useStyles();
  const iaveToggle = useFeature(toggles.iave);

  return (
    <div>
      <Table className={classes.teachersTable}>
        <TableRow header>
          <TableCell>
            {lang.select}
          </TableCell>
          <Render when={!iaveToggle}>
            <TableCell>
              {lang.teachers.teachersTable.name}
            </TableCell>
          </Render>
          <TableCell>
            {iaveToggle ? lang.teachers.teachersTable.username : lang.teachers.teachersTable.email}
          </TableCell>
          <TableCell>
            {lang.teachers.teachersTable.groups}
          </TableCell>
          <TableCell>
            {lang.teachers.teachersTable.actions}
          </TableCell>
        </TableRow>
        {teachers.map(teacher => (
          <TeacherRow
            key={teacher.id}
            teacher={teacher}
            resetTeachers={resetTeachers}
            setTeachers={setTeachers}
          />
        ))}
      </Table>
    </div>
  );
};

TeachersTable.propTypes = {
  teachers: PropTypes.array,
  resetTeachers: PropTypes.func,
  setTeachers: PropTypes.func,
};

export default TeachersTable;
