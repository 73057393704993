import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import ClassActions from 'components/classes/ClassActions';
import ClassCard from 'components/classes/ClassCard';

import useStyles from './styles';

const ClassesList = ({ classes: _classes, getClasses, reload }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const toggleClassSelection = useCallback((classId) => {
    setSelected(selected => {
      const isSelected = selected.find(selected => selected.id === classId);
      if (isSelected) {
        return selected.filter(selected => selected.id !== classId);
      } else {
        return [...selected, _classes.find(_class => _class.id === classId)];
      }
    });
  }, [_classes, setSelected]);

  return (
    <div>
      <div className={classes.actions}>
        <ClassActions
          entities={_classes}
          selected={selected}
          setSelected={setSelected}
          getClasses={getClasses}
          reload={reload}
        />
      </div>
      <div className={classes.classesList}>
        {_classes.map((_class) => (
          <ClassCard
            key={_class.id}
            _class={_class}
            selectClass={() => toggleClassSelection(_class.id)}
            isSelected={selected.find(selected => selected.id === _class.id)}
            setSelected={setSelected}
            reload={reload}
          />
        ))}
      </div>
    </div>
  );
};

ClassesList.propTypes = {
  classes: PropTypes.array.isRequired,
  getClasses: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
};

export default ClassesList;
