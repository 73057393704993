import React, { useState } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import EventDetailsModal from '../EventDetailsModal';
import Button from 'components/common/Button';

const EventDetails = ({ event }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <EventDetailsModal
        open={open}
        close={() => setOpen(false)}
        event={event}
      />
      <Button onClick={() => setOpen(true)}>
        {lang.events.details}
      </Button>
    </div>
  );
};

EventDetails.propTypes = {
  event: PropTypes.object,
};

export default EventDetails;
