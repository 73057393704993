import React, { useCallback, useState } from 'react';
import { TableCell, TableRow } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserLang, selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import DownloadAttemptsPDFModal from '../DownloadAttemptPDFsModal';
import Button from 'components/common/Button';

import useStyles from './styles';

const GENERATING = 'GENERATING';
const ZIPPING = 'ZIPPING';
const DOWNLOAD = 'DOWNLOAD';

const ExamDownloadRow = ({ exam }) => {
  const classes = useStyles();

  const [exportStudentAttemptsRequest] = useApi(api.exportStudentAttempts);
  const [getPublicationExportRequest] = useApi(api.getPublicationExport);
  const [zipPublicationExportRequest] = useApi(api.zipPublicationExport);
  const toast = useToast();
  const locale = useSelector(selectUserLang);
  const schoolId = useSelector(selectUserSpaceId);
  const validateAttemptsToggle = useFeature(toggles.validateAttempts);
  const adminAttemptsExportToggle = useFeature(toggles.adminAttemptsExport);

  const [exportState, setExportState] = useState(GENERATING);
  const [downloadModal, setDownloadModal] = useState(false);
  const [path, setPath] = useState(null);
  const [totalAttempts, setTotalAttempts] = useState(null);
  const [attemptsExported, setAttemptsExported] = useState(null);

  const checkPublicationExportStatus = useCallback((publicationExportId, intervalId) => {
    const request = {
      params: {
        publicationExportId: publicationExportId,
      },
    };

    getPublicationExportRequest(request, ({ data }) => {
      if (data.status === 0) {
        if (data.publicationExport.status === 'EXPORTED') {
          clearInterval(intervalId);
          setExportState(DOWNLOAD);
        }
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        clearInterval(intervalId);
      }
    });
  }, [getPublicationExportRequest, toast]);

  const zipExport = useCallback((publicationExportId) => {
    const request = {
      params: {
        publicationExportId: publicationExportId,
      },
    };

    zipPublicationExportRequest(request, ({ data }) => {
      if (data.status === 0) {
        const intervalId = setInterval(() => checkPublicationExportStatus(publicationExportId, intervalId), 1000);
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [zipPublicationExportRequest, checkPublicationExportStatus, toast]);

  const checkAttemptExportsStatus = useCallback((publicationExportId, intervalId) => {
    const request = {
      params: {
        publicationExportId: publicationExportId,
      },
    };

    getPublicationExportRequest(request, ({ data }) => {
      if (data.status === 0) {
        setAttemptsExported(data.publicationExport.attemptsExported + data.publicationExport.attemptsWithError);
        if (data.publicationExport.attemptsNotExported === 0) {
          clearInterval(intervalId);
          zipExport(publicationExportId);
          setExportState(ZIPPING);
        }
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        clearInterval(intervalId);
      }
    });
  }, [getPublicationExportRequest, toast, zipExport]);

  const exportAttempts = useCallback(() => {
    const request = {
      params: {
        publicationId: exam.id,
      },
      data: {
        locale: locale,
      },
    };

    exportStudentAttemptsRequest(request, ({ data }) => {
      if (data.status === 0) {
        setExportState(GENERATING);
        setDownloadModal(true);
        setPath(data.publicationExportPath);
        setTotalAttempts(data.totalAttempts);
        const intervalId = setInterval(() => checkAttemptExportsStatus(data.publicationExportId, intervalId), 1000);

      } else if (data.status === 4) {
        toast.warning(lang.exams.exportAttemptsNotAvailable);
      } else if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [exam, locale, exportStudentAttemptsRequest, checkAttemptExportsStatus, toast]);

  return (
    <TableRow>
      <TableCell>
        {exam.name}
      </TableCell>
      {(validateAttemptsToggle || adminAttemptsExportToggle) && (
        <TableCell className={classes.optionsCol}>
          <DownloadAttemptsPDFModal
            open={downloadModal}
            close={() => setDownloadModal(false)}
            exportState={exportState}
            setExportState={setExportState}
            path={path}
            attemptsExported={attemptsExported}
            totalAttempts={totalAttempts}
          />
          {adminAttemptsExportToggle && (
            <Button
              sibling
              onClick={exportAttempts}
            >
              {lang.download}
            </Button>
          )}
          {validateAttemptsToggle && (
            <Button
              sibling
              className={classes.detailsButton}
              onClick={() => window.location = routes.exam.ref(schoolId, exam.testId)}
            >
              {lang.details}
            </Button>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

ExamDownloadRow.propTypes = {
  exam: PropTypes.object,
};

export default ExamDownloadRow;
