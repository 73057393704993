import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  aLink: {
    color: theme.buttonBackground2,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    color: theme.accentColor,
  },
  linkChildren: {
    display: 'inline-block',
    '&.icon': {
      marginLeft: '10px',
    },
  },
}));
