import React, { useCallback, useState } from 'react';
import { TableCell, TableRow } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import useFeature from 'hooks/common/useFeature';
import toggles from 'toggles';

import ClassStudentsListActions from '../ClassStudentsListActions';
import MoveStudentsModal from '../MoveStudentsModal';
import Input from 'components/common/Input';

const ClassStudentsRow = ({ student, onSelect, checked, getClass }) => {
  const iaveToggle = useFeature(toggles.iave);

  const [moveStudentsModal, setMoveStudentsModal] = useState(false);

  const onRowSelect = useCallback((event) => {
    onSelect(student.id, event.target.checked);
  }, [onSelect, student]);

  return (
    <TableRow key={student.id}>
      <MoveStudentsModal
        open={moveStudentsModal}
        close={() => setMoveStudentsModal(false)}
        selectedStudentIds={[student.id]}
        getClass={getClass}
      />
      <TableCell colSpan={1}>
        <Input
          key={student.id}
          type="checkbox"
          onChange={onRowSelect}
          value={checked}
        />
      </TableCell>
      <TableCell>
        {iaveToggle ? student.username : student.fullName}
      </TableCell>
      {!iaveToggle && (
        <TableCell>
          {student.email}
        </TableCell>
      )}
      <TableCell>
        <ClassStudentsListActions studentId={student.id} getClass={getClass} />
      </TableCell>
    </TableRow>
  );
};

ClassStudentsRow.propTypes = {
  student: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  getClass: PropTypes.func.isRequired,
};

export default ClassStudentsRow;
