import React from 'react';
import PropTypes from 'prop-types';

import OptionCard from '../OptionCard';
import Input from 'components/common/Input';

import useStyles from './styles';

const CardSelector = ({ headerLabel, options, selected, setSelected, disabled, dataTour }) => {
  const classes = useStyles();

  if (!selected) {
    return null;
  }

  return (
    <div data-tour={dataTour}>
      <div className={classes.wrapper}>
        {headerLabel}
        <div className={classes.items}>
          {options.filter(option => !option.hide).map((option) => (
            <OptionCard
              key={option.value}
              option={option}
              selected={selected}
              setSelected={setSelected}
              disabled={disabled}
            />
          ))}
        </div>
      </div>
      <Input
        type="select"
        className={classes.mobileOption}
        value={selected}
        label={headerLabel}
        options={options}
        onChange={(value) => setSelected(value)}
      />
    </div>
  );
};

CardSelector.propTypes = {
  headerLabel: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  disabled: PropTypes.bool,
  dataTour: PropTypes.string,
};

export default CardSelector;
