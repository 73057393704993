import React, { useCallback, useEffect, useState } from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import EventFilters from '../EventFilters';
import EventsRow from '../EventRow';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const EventsTable = ({ loaded }) => {
  const [getEventsRequest, loading, abortRequest] = useApi(api.getSchoolEvents);
  const toast = useToast();
  const [events, setEvents] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const schoolId = useSelector(selectUserSpaceId);
  const classes = useStyles();
  const [eventTypeFilters, setEventTypeFilters] = useState([]);
  const [userFilter, setUserFilter] = useState('');

  const PAGE_SIZE = 50;

  const fetchEvents = useCallback((page) => {
    if (!schoolId) {
      return;
    }

    const request = {
      params: {
        schoolId: schoolId,
      },
      query: {
        page: page,
      },
    };

    if (eventTypeFilters.length !== 0) {
      request.query.eventTypes = eventTypeFilters.map(filter => filter.value).join(',');
    }

    if (userFilter) {
      request.query.user = userFilter;
    }

    getEventsRequest(request, ({ data }) => {
      if (data.status === 0) {
        page !== 1
          ? setEvents(events => [...events, ...data.events])
          : setEvents(data.events);
        setHasMore(data.events.length === PAGE_SIZE);
        setPage(page => page + 1);
        loaded();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        loaded();
      }
    });
  }, [loaded, getEventsRequest, toast, schoolId, eventTypeFilters, userFilter]);

  useEffect(() => {
    setPage(1);
    setEvents([]);
    fetchEvents(1);

    return () => {
      abortRequest();
    };
  }, [fetchEvents, abortRequest]);

  return (
    <InfiniteScroll
      dataLength={events.length}
      next={() => fetchEvents(page)}
      hasMore={hasMore}
      loader={<Loading active={loading} />}
    >
      <EventFilters
        eventTypeFilters={eventTypeFilters}
        setEventTypeFilters={setEventTypeFilters}
        userFilter={userFilter}
        setUserFilter={setUserFilter}
      />
      <Table className={classes.eventsTable}>
        <TableRow header>
          <TableCell>
            {lang.events.eventType}
          </TableCell>
          <TableCell>
            {lang.events.category}
          </TableCell>
          <TableCell>
            {lang.events.user}
          </TableCell>
          <TableCell>
            {lang.events.date}
          </TableCell>
          <TableCell textAlign="right">
            {lang.events.details}
          </TableCell>
        </TableRow>
        {events.map(event => (
          <EventsRow
            key={event.id}
            event={event}
          />
        ))}
      </Table>
    </InfiniteScroll>
  );
};

EventsTable.propTypes = {
  loaded: PropTypes.func,
};

export default EventsTable;
