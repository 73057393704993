import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { v4 } from 'uuid';

const Tooltip = ({ tip, place, children }) => {

  const id = `tooltip-${v4()}`;

  return (
    <>
      <div data-tip data-for={id}>
        {children}
      </div>
      <Render when={tip}>
        <ReactTooltip id={id} effect={'solid'} place={place} >
          {tip}
        </ReactTooltip>
      </Render>
    </>
  );
};

Tooltip.propTypes = {
  tip: PropTypes.string,
  place: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  children: PropTypes.any.isRequired,
};

export default Tooltip;
