import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  detailsButton: {
    marginLeft: '15px',
    fontWeight: '500',
    color: theme.buttonBackground2,
    borderColor: theme.buttonBackground2,
    border: ['2px', 'solid'],
    backgroundColor: 'transparent',
    '&:hover:enabled': {
      backgroundColor: `${theme.buttonBackground2}33`,
    },
  },
  optionsCol: {
    textAlign: 'start',
    paddingLeft: '23%',
  },
}));
