import React, { useState, useCallback, useEffect } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import useInput from 'hooks/common/useInput';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import { useClassContext } from 'pages/Class/context';

const MoveStudentsModal = ({ open, close, selectedStudentIds, getClass }) => {
  const toast = useToast();
  const [moveClassStudentRequest] = useApi(api.moveClassStudents);
  const [getClassesRequest] = useApi(api.getSchoolClasses);
  const schoolId = useSelector(selectUserSpaceId);
  const { classId } = useParams();
  const iaveToggle = useFeature(toggles.iave);
  const [classes, setClasses] = useState(null);
  const [loading, setLoading] = useState(false);
  const [destinationClass, setDestinationClass, groupErrors] = useInput(null);
  const [movedStudentsNumber, setMovedStudentsNumber] = useState(0);
  const [alreadyInClassStudents, setAlreadyInClassStudents] = useState([]);
  const [moveSummary, setMoveSummary] = useState(false);
  const { fetchStudents } = useClassContext();

  const getClasses = useCallback(() => {
    const request = {
      params: {
        schoolId: schoolId,
      },
      query: {
        studentCount: 'true',
      },
    };

    getClassesRequest(request, ({ data }) => {
      if (data.status === 0) {
        setClasses(data.classes
          .filter(c => c.id !== classId)
          .map(c => ({ value: c.id, label: c.name })));
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [getClassesRequest, schoolId, toast, classId]);

  useEffect(() => {
    if (open) {
      getClasses();
    }
  }, [getClasses, open]);

  const _close = () => {
    close();
    setTimeout(() => {
      setDestinationClass(null);
      setMoveSummary(false);
    }, 350);
  };

  const moveStudent = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const request = {
      params: {
        schoolId: schoolId,
        classId: classId,
      },
      data: {
        studentIds: selectedStudentIds,
        destinationClassId: destinationClass.value || null,
      },
    };

    moveClassStudentRequest(request, ({ data }) => {
      if (data.status === 0) {
        setMovedStudentsNumber(data.movedStudents.length + data.alreadyInClass.length);
        setAlreadyInClassStudents(data.alreadyInClass);

        setTimeout(() => {
          fetchStudents(1);
          getClass();
          setLoading(false);
          toast.success(data.movedStudents.length + data.alreadyInClass.length === 1 ? lang.students.moveStudents.success : lang.students.moveStudents.successMultiple);
          setMoveSummary(true);
        }, 350);

        return;
      }

      setLoading(false);
      toast.error(lang.oops);
    });
  };

  const actions = [
    {
      name: moveSummary ? lang.close : lang.cancel,
      color: 'black',
      onClick: _close,
    },
    {
      name: lang.students.moveStudents.move,
      onClick: moveStudent,
      loading: loading,
      hide: moveSummary,
    },
  ];

  return (
    <Modal
      open={open}
      close={_close}
      header={lang.students.moveStudents.title}
      actions={actions}
      center
      transition
      small
    >
      {moveSummary ? (
        <div>
          <p>
            {iaveToggle ?
              lang.students.moveStudents.successMoveStudentsIAVE(movedStudentsNumber, destinationClass.label)
              :
              lang.students.moveStudents.successMoveStudents(movedStudentsNumber, destinationClass.label)
            }
          </p>
          {alreadyInClassStudents.length > 0 && (
            <>
              <p>
                {iaveToggle ?
                  lang.students.moveStudents.alreadyInClassIAVE(alreadyInClassStudents.length)
                  :
                  lang.students.moveStudents.alreadyInClass(alreadyInClassStudents.length)
                }
              </p>
              <ul>
                {alreadyInClassStudents.map(student => (
                  <li key={student.id}>
                    {student.fullName}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      ) : (
        <Input
          type="select"
          value={destinationClass}
          label={iaveToggle ? lang.students.moveStudents.moveStudentsDescriptionIAVE : lang.students.moveStudents.moveStudentsDescription}
          placeholder={iaveToggle ? lang.appKeywords.examGroups : lang.appKeywords.class}
          onChange={(option) => setDestinationClass(option)}
          options={classes}
          errors={groupErrors}
        />
      )}
    </Modal>
  );
};

MoveStudentsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  selectedStudentIds: PropTypes.array,
  getClass: PropTypes.func,
};

export default MoveStudentsModal;
