import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  newPassword: {
    fontWeight: '600',
    fontSize: 20,
    textAlign: 'center',
    margin: '25px 0',
  },
  description: {
    textAlign: 'center',
    margin: '25px 0',
  },
}));
