import React, { useState } from 'react';
import { Wrapper } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserFeatureToggles } from 'actions/userActions';

import Error from '../Error';
import Navbar from '../Navbar';
import Sidebar from '../sidebar/Sidebar';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const Layout = ({ children, navbar, sidebar }) => {
  const classes = useStyles();
  const appLoading = useSelector(state => state.page.appLoading);
  const pageLoading = useSelector(state => state.page.pageLoading);
  const error = useSelector(state => state.page.error);
  const layout = useSelector(state => state.page.layout);
  const authenticated = useSelector(state => state.page.authenticated);
  const userFeatureToggles = useSelector(selectUserFeatureToggles);

  const [openSidebar, setOpenSidebar] = useState(false);

  const getPageContent = () => {
    if (error) {
      return (
        <Error code={error} />
      );
    }

    return children;
  };

  const getNavBar = () => {
    if (!appLoading && navbar && layout) {
      return (
        <Navbar open={openSidebar} setOpen={setOpenSidebar} showHamburger={authenticated} />
      );
    }
  };

  const getSideBar = () => {
    if (!appLoading && sidebar && layout && authenticated) {
      return (
        <Sidebar open={openSidebar} setOpen={setOpenSidebar} />
      );
    }
  };

  if (appLoading || !userFeatureToggles) {
    return (
      <Loading active={appLoading} fullPage />
    );
  }

  return (
    <div>
      {getNavBar()}
      {getSideBar()}
      <div className={cx(classes.main, { layoutWidth: !appLoading && layout && authenticated })}>
        <Loading active={pageLoading} fullPage />
        <Wrapper
          fluid
          className={cx(classes.layoutWrapper, {
            [classes.navbarSpacer]: !appLoading && navbar && layout,
            [classes.sidebarSpacer]: !appLoading && sidebar && layout && authenticated,
            [classes.loading]: pageLoading,
          })}
        >
          {getPageContent()}
        </Wrapper>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  navbar: PropTypes.bool,
  sidebar: PropTypes.bool,
};

export default Layout;
