const loginRedirectUri = {
  development: process.env.REACT_APP_DEVELOPMENT_AZURE_LOGIN_REDIRECT_URI,
  staging: process.env.REACT_APP_STAGING_AZURE_LOGIN_REDIRECT_URI,
  production: process.env.REACT_APP_PRODUCTION_AZURE_LOGIN_REDIRECT_URI,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read', 'openid', 'profile', 'offline_access'],
  redirectUri: loginRedirectUri[process.env.REACT_APP_NODE_ENV],
};
