import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  modal: {
    backgroundColor: theme.backgroundColor,
    borderRadius: '24px',
    padding: '0',
    border: 'none',
    overflowY: 'hidden',
  },
  modalHeader: {
    backgroundColor: theme.backgroundColor2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 16px',
    fontSize: '15px',
    fontWeight: '600',
    position: 'sticky',
    top: '0',
    zIndex: '1',
  },
  modalCloseIcon: {
    cursor: 'pointer',
    width: '12px',
    height: '12px',
    padding: '10px',
    color: theme.accentColor,
    opacity: '50%',
    transform: 'scale(2)',
    '&:hover': {
      opacity: '100%',
    },
  },
  modalBody: {
    padding: '16px',
    maxHeight: 'calc(90vh - 84px)',
    overflowY: 'auto',
  },
  modalBtnContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalBtn: {
    marginLeft: '6px',
    padding: '8px 10px',
    backgroundColor: theme.buttonBackground2,
    '&.red:hover': {
      backgroundColor: `${theme.buttonBackgroundHoverRed} !important`,
    },
    '&.black:hover': {
      backgroundColor: `${theme.buttonBackgroundHoverBlack} !important`,
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    modal: {
      width: '90% !important',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    modal: {
      width: '90% !important',
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    modal: {
      width: '90% !important',
    },
  },
}));
