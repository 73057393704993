import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import { INITIAL_STATE, SET_STUDENTS, SET_USER_SEARCH, addStudents, classReducer, setStudents, setStudentsHasMore, setStudentsLoading, setStudentsPage } from './reducer';

const PAGE_SIZE = 50;

export const ClassContext = createContext(null);

export const useClassContext = () => {
  return useContext(ClassContext);
};

export const useFetchStudents = () => {
  return useContext(ClassContext).fetchStudents;
};

export const ClassProvider = ({ children }) => {
  const [_class, dispatch] = useReducer(classReducer, INITIAL_STATE);
  const schoolId = useSelector(selectUserSpaceId);
  const [getSchoolUsersRequest] = useApi(api.getSchoolUsers);
  const toast = useToast();
  const { classId } = useParams();

  const fetchStudents = useCallback((page) => {
    dispatch(setStudentsLoading(true));
    const request = {
      params: {
        schoolId: schoolId,
      },
      query: {
        classIds: [classId],
        page: page,
        pageSize: PAGE_SIZE,
        userSearch: _class.students.userSearch,
        role: 'student',
      },
    };

    getSchoolUsersRequest(request, ({ data }) => {
      dispatch(setStudentsLoading(false));
      if (data.status === 0) {
        page !== 1
          ? dispatch(addStudents(data.users))
          : dispatch(setStudents(data.users));
        dispatch(setStudentsHasMore(data.users.length === PAGE_SIZE));
        dispatch(setStudentsPage(page + 1));
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [_class.students.userSearch, classId, getSchoolUsersRequest, schoolId, toast]);

  const _setStudents = useCallback((students) => {
    dispatch({ type: SET_STUDENTS, students: students });
  }, []);

  const setUserSearch = useCallback((userSearch) => {
    dispatch({ type: SET_USER_SEARCH, userSearch: userSearch });
  }, []);

  const context = useMemo(() => ({
    ..._class,
    fetchStudents,
    setStudents: _setStudents,
    setUserSearch,
  }), [_class, fetchStudents, _setStudents, setUserSearch]);

  return (
    <ClassContext.Provider value={context}>
      {children}
    </ClassContext.Provider>
  );
};

ClassProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
