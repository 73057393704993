import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({

  optionsDropdown: {
    zIndex: '2',
    borderRadius: '18px',
    border: `1px solid ${theme.cardBorder} !important`,
    overflow: 'hidden',
  },
  dropdownItem: {
    margin: 'unset !important',
    borderBottom: 'unset !important',
    backgroundColor: theme.cardBackground,
    '&:hover': {
      backgroundColor: theme.cardColor2,
      cursor: 'pointer',
    },
  },
}));
