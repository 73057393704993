import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Checkbox = ({ label, name, value, defaultValue, onChange, onFocus, onBlur, required, disabled, dark, _ref, id }) => {
  const classes = useStyles();

  return (
    <label
      htmlFor={id}
      className={cx(classes.boxContainer, { dark })}
    >
      <input
        type="checkbox"
        name={name}
        checked={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        ref={_ref}
        id={id}
      />
      <span className={classes.inputLabel}>
        {label}
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
  _ref: PropTypes.object,
  id: PropTypes.string,
};

export default Checkbox;
