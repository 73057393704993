import React from 'react';
import { Spacer } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import { ADD_STUDENTS_FORM, ADDING, ADD_STUDENTS_SUMMARY } from 'constants/addStudents';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const ImportClassStudents = ({ files, setFiles, importStudentsResults, importStudentsState, notFoundLines }) => {
  const iaveToggle = useFeature(toggles.iave);
  const classes = useStyles();
  const totalStudents = importStudentsResults.added?.length + importStudentsResults.repeated?.length + importStudentsResults.notFound?.length;

  const onDrop = (files) => {
    setFiles(files);
  };

  return (
    <>
      {importStudentsState === ADD_STUDENTS_FORM && (
        <>
          <Spacer px={20} />
          {iaveToggle ? (
            <div>
              {lang.students.importStudents.descriptionIAVE}
            </div>
          ) : (
            <>
              <div>
                {lang.students.importStudents.classDescription}
              </div>
              <div className={classes.downloadContainer}>
                <Button
                  onClick={() => window.open(`${process.env.REACT_APP_S3_URL}/resources/class_students.csv`, '_blank')}
                  className={classes.downloadButton}
                >
                  {lang.students.importStudents.exampleDownload}
                </Button>
              </div>
            </>
          )}
          <Spacer px={20} />
          <Input
            type="file"
            label={lang.students.importStudents.importStudentsLabel}
            placeholder={lang.students.importStudents.importStudentsPlaceholder}
            value={files}
            onDrop={onDrop}
            accept=".csv"
          />
          <Spacer px={20} />
        </>
      )}
      {importStudentsState === ADDING && (
        <Loading active />
      )}
      {importStudentsState === ADD_STUDENTS_SUMMARY && (
        <div className={classes.summaryContainer}>
          <ul>
            {importStudentsResults.added.length > 0 &&
              <li>
                {(iaveToggle ?
                  lang.students.importStudents.successImportStudentsIAVE(importStudentsResults.added.length)
                  :
                  lang.students.importStudents.successImportStudents(importStudentsResults.added.length)
                )}
              </li>
            }
            {importStudentsResults.repeated.length > 0 &&
              <li>
                {(iaveToggle ?
                  lang.students.importStudents.alreadyInClassIAVE(importStudentsResults.repeated.length)
                  :
                  lang.students.importStudents.alreadyInClass(importStudentsResults.repeated.length)
                )}
              </li>
            }
            {importStudentsResults.notFound.length > 0 &&
              <li>
                {(iaveToggle
                  ? `${lang.students.importStudents.notFoundStudentsIAVE(importStudentsResults.notFound.length)} ${notFoundLines.join(', ')}.`
                  : `${lang.students.importStudents.notFoundStudents(importStudentsResults.notFound.length)} ${notFoundLines.join(', ')}.`
                )}
              </li>
            }
            {totalStudents &&
              <li>
                {lang.students.importStudents.totalStudents(totalStudents)}
              </li>
            }
          </ul>
        </div>
      )}
    </>
  );
};

ImportClassStudents.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func,
  importStudentsResults: PropTypes.object,
  importStudentsState: PropTypes.string,
  notFoundLines: PropTypes.array,
};

export default ImportClassStudents;
