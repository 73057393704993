import React, { useState } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import CloseAttemptsModal from '../CloseAttemptsModal';
import Button from 'components/common/Button';
import Card from 'components/common/Card';

import useStyles from './styles';

const ExamsActions = ({ ongoingAttemptsCount }) => {
  const classes = useStyles();

  const [closeAttemptsModal, setCloseAttemptsModal] = useState(false);

  return (
    <div className={classes.examActions}>
      <CloseAttemptsModal
        open={closeAttemptsModal}
        close={() => setCloseAttemptsModal(false)}
      />
      <div className={classes.cardWrapper}>
        <Card
          className={classes.card}
          width="25%"
        >
          <div className={classes.linkText}>
            {lang.exams.onGoingAttempts}
          </div>
          <h1>
            {ongoingAttemptsCount}
          </h1>
        </Card>
      </div>
      <div>
        <Button onClick={() => setCloseAttemptsModal(true)}>
          {lang.students.studentActions.closeAttempts}
        </Button>
      </div>
    </div>
  );
};

ExamsActions.propTypes = {
  ongoingAttemptsCount: PropTypes.number.isRequired,
};

export default ExamsActions;
