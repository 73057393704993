import React, { useState } from 'react';
import { TableCell, TableRow } from '@intuitivo/outline';
import { Button, Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectIsSuperAdmin } from 'actions/userActions';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { deepCopy } from 'utils';

import DeleteStudentsModal from '../DeleteStudentsModal';
import RecoverStudentPasswordModal from '../RecoverStudentPasswordModal';
import Input from 'components/common/Input';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const StudentRow = ({ student, resetStudents, setStudents }) => {
  const classes = useStyles();
  const iaveToggle = useFeature(toggles.iave);
  const isSuperadmin = useSelector(selectIsSuperAdmin);

  const [deleteStudentsModal, setDeleteStudentsModal] = useState(false);
  const [recoverPassordModal, setRecoverPassordModal] = useState(false);

  const onSelect = (event) => {
    setStudents(students => {
      const newStudents = deepCopy(students);

      const newStudent = newStudents.find(newStudent => newStudent.id === student.id);
      newStudent.selected = event.target.checked;

      return newStudents;
    });
  };

  return (
    <TableRow>
      <TableCell colSpan={1}>
        <Input
          type="checkbox"
          value={student.selected}
          onChange={onSelect}
        />
      </TableCell>
      <Render when={iaveToggle}>
        <TableCell>
          <div>
            {student.schoolIdentifier}
          </div>
        </TableCell>
      </Render>
      <Render when={!iaveToggle}>
        <TableCell>
          <div>
            {student.fullName}
          </div>
        </TableCell>
      </Render>
      <TableCell>
        {iaveToggle ? student.username : student.email}
      </TableCell>
      <TableCell>
        {student.classes?.map(_class => _class.name).join(', ')}
      </TableCell>
      <TableCell textAlign="center">
        <DeleteStudentsModal
          open={deleteStudentsModal}
          close={() => setDeleteStudentsModal(false)}
          studentIds={[student.id]}
          resetStudents={resetStudents}
        />
        <RecoverStudentPasswordModal
          open={recoverPassordModal}
          close={() => setRecoverPassordModal(false)}
          studentId={student.id}
        />
        <div className={classes.actions}>
          { isSuperadmin && (
            <Button
              onClick={() => setRecoverPassordModal(true)}
              sibling
            >
              {lang.students.studentsTable.recover}
            </Button>
          )}
          <Tooltip tip={lang.students.deleteStudent.title}>
            <Button
              onClick={() => setDeleteStudentsModal(true)}
              sibling
              color="red"
            >
              {lang.delete}
            </Button>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
};

StudentRow.propTypes = {
  student: PropTypes.object,
  resetStudents: PropTypes.func,
  setStudents: PropTypes.func,
};

export default StudentRow;
