import 'core-js/features/promise';

if (
  !('startsWith' in String.prototype) ||
  !('endsWith' in String.prototype) ||
  !('includes' in Array.prototype) ||
  !('assign' in Object) ||
  !('keys' in Object)
) {
  require('core-js/stable');
}
