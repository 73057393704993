import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  dropzone: {
    padding: '50px',
    color: theme.textColor2,
    border: `2px dashed ${theme.textColor2}`,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '&.invalid': {
      border: '2px dashed #fb6045',
    },
  },
  file: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  fileIcon: {
    fontSize: '40px',
    margin: 'auto',
  },
  fileName: {
    textAlign: 'center',
    fontSize: '10px',
    marginTop: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  imageContainer: {
    width: '100%',
    height: '150px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
  },
}));
