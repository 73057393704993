import { combineReducers } from 'redux';

import pageReducer from './pageReducer';
import userReducer from './userReducer';

const appReducer = combineReducers({
  page: pageReducer,
  user: userReducer,
});

export default appReducer;
