import React, { useEffect } from 'react';

import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';

import Error from 'components/common/layout/Error';

const NoMatch = () => {
  const { loaded } = usePageLogic(lang.notFound, true);

  useEffect(() => {
    loaded();
  }, [loaded]);

  return (
    <Error code={'404'} />
  );
};

export default NoMatch;
