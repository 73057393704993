import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  eventFieldContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '10px',
  },
  eventFieldLabel: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  eventFieldValue: {
    width: '100%',
  },
  codeField: {
    backgroundColor: theme.backgroundColor2,
    margin: '0',
    padding: '10px',
    borderRadius: '10px',
  },
}));
