import React from 'react';
import { Card as OLCard } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useStyles from './styles';

const Card = ({ image, header, children, onClick, noPointer, imageHeight, width, shadow, style, className, _ref }) => {
  const classes = useStyles();
  const isDark = useSelector(state => state.page.isDark);

  return (
    <OLCard
      image={image}
      header={header}
      onClick={onClick}
      noPointer={noPointer}
      imageHeight={imageHeight}
      width={width}
      dark={isDark}
      shadow={shadow}
      className={cx(classes.card, className)}
      style={style}
      _ref={_ref}
    >
      {children}
    </OLCard>
  );
};

Card.propTypes = {
  image: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  noPointer: PropTypes.bool,
  imageHeight: PropTypes.string,
  width: PropTypes.string,
  shadow: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  _ref: PropTypes.object,
};

export default Card;
