import React, { useEffect } from 'react';

import routes from 'routes';

import Loading from 'components/common/Loading';

const Logout = () => {
  useEffect(() => {
    localStorage.clear();
    window.parent.postMessage('cleared', routes.auth.ref());
  }, []);

  return (
    <Loading
      active
      fullPage
    />
  );
};

export default Logout;
