import React, { useEffect, useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import DeleteClassesModal from 'components/classes/DeleteClassesModal';
import Loading from 'components/common/Loading';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const ClassActions = ({ selected, setSelected, entities, getClasses, reload }) => {
  const classes = useStyles();
  const [deleteClassesModal, setDeleteClassesModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const actions = [
    {
      icon: faTrash,
      label: lang.delete,
      onClick: () => setDeleteClassesModal(true),
    },
  ];

  const getActions = () => {
    if (!actions || actions.length === 0 || selected.length === 0) {
      return false;
    }

    return actions.map((action) => (
      <div
        key={action.label}
        className={classes.actions}
      >
        <DeleteClassesModal
          open={deleteClassesModal}
          close={() => setDeleteClassesModal(false)}
          classIds={selected.map(selected => selected.id)}
          setSelected={setSelected}
          onSubmit={reload}
        />
        <Tooltip tip={action.tip}>
          <div
            className={cx(classes.optionsBtn, { isPublished: action.isPublished, disabled: action.disabled })}
            onClick={() => {
              if (!action.disabled && !action.loading) {
                action.onClick();
              }
            }}
          >
            <Loading active={action.loading} className={classes.optionLoading} />
            {!action.loading &&
              <Icon
                icon={action.icon}
              />
            }
            <div className={classes.optionText}>
              {action.label}
            </div>
          </div>
        </Tooltip>
      </div>
    ));
  };

  const onClick = () => {
    if (!selectAll) {
      setSelected(entities);
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    const allSelected = entities.every(entity => selected.find(elem => elem.id === entity.id));
    setSelectAll(allSelected);
  }, [selected, entities]);

  return (
    <div className={classes.actionWrapper}>
      <input
        type="checkbox"
        checked={selectAll}
        onClick={onClick}
        className={classes.exerciseCheckbox}
      />
      {(!selected || !selected.length) &&
        <div className={classes.selectAll}>
          {lang.selectAll}
        </div>
      }
      {selected && getActions()}
    </div>
  );
};

ClassActions.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  entities: PropTypes.array,
  getClasses: PropTypes.func,
  reload: PropTypes.func,
};

export default ClassActions;
