import React, { useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

const CloseAttemptsModal = ({ open, close }) => {
  const [closeAttemptsRequest, loading] = useApi(api.closeAttempts);
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();

  const closeAttempts = useCallback(() => {
    const request = {
      params: {
        schoolId,
      },
    };

    closeAttemptsRequest(request, ({ data }) => {
      if (data.status === 0) {
        toast.success(lang.students.studentActions.closeAttemptsSuccess);
        close();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [closeAttemptsRequest, schoolId, toast, close]);

  const actions = [
    {
      name: lang.cancel,
      onClick: close,
      color: 'black',
    },
    {
      name: lang.students.studentActions.closeAttempts,
      onClick: closeAttempts,
      color: 'red',
      loading: loading,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.students.studentActions.closeAttempts}
      actions={actions}
      center
      transition
      small
    >
      {lang.students.studentActions.closeAttemptsDescription}
    </Modal>
  );
};

CloseAttemptsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default CloseAttemptsModal;
