import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { selectIsSuperAdmin } from 'actions/userActions';
import { LOCAL } from 'constants/environments';
import useFeature from 'hooks/common/useFeature';
import routes from 'routes';
import toggles from 'toggles';

import PrivateRoute from 'components/common/PrivateRoute';
import _Class from 'pages/Class';
import { ClassProvider } from 'pages/Class/context';
import Classes from 'pages/Classes';
import Events from 'pages/Events';
import Exams from 'pages/Exams';
import Logout from 'pages/Logout';
import MicrosoftLoginRedirect from 'pages/MicrosoftLoginRedirect';
import NoMatch from 'pages/NoMatch';
import Students from 'pages/Students';
import Synchronization from 'pages/Synchronization';
import Teachers from 'pages/Teachers';

const Router = () => {
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const iaveToggle = useFeature(toggles.iave);
  const classesToggle = useFeature(toggles.classes);
  const studentAccountsToggle = useFeature(toggles.studentAccounts);

  return (
    <Switch>
      <PrivateRoute exact path={routes.home.def}>
        <Redirect to={isSuperAdmin ? routes.teachers.ref() : routes.students.ref()} />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={routes.teachers.def}
        hide={!isSuperAdmin}
      >
        <Teachers />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={routes.students.def}
        hide={!studentAccountsToggle}
      >
        <Students />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={routes.classes.def}
        hide={!classesToggle}
      >
        <Classes />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={routes.class.def}
        hide={!classesToggle}
      >
        <ClassProvider>
          <_Class />
        </ClassProvider>
      </PrivateRoute>
      <PrivateRoute
        exact
        path={routes.events.def}
        hide={!isSuperAdmin || !iaveToggle}
      >
        <Events />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={routes.exams.def}
        hide={!iaveToggle}
      >
        <Exams />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={routes.synchronization.def}
        hide={process.env.REACT_APP_NODE_ENV !== LOCAL}
      >
        <Synchronization />
      </PrivateRoute>
      <Route exact path={routes.loginMicrosoft.def}>
        <MicrosoftLoginRedirect />
      </Route>
      <Route exact path={routes.logout.def}>
        <Logout />
      </Route>
      <Route path={'*'}>
        <NoMatch />
      </Route>
    </Switch>
  );
};

export default Router;
