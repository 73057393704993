import React, { useState } from 'react';
import { InputText, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import useInput from 'hooks/common/useInput';
import lang from 'lang';
import toggles from 'toggles';

import Modal from 'components/common/Modal';

const ClassSettingsModal = ({ open, close, currentClass, reload }) => {
  const toast = useToast();
  const iaveToggle = useFeature(toggles.iave);
  const [editSchoolClassRequest] = useApi(api.editSchoolClass);

  const [loading, setLoading] = useState(false);

  const langKey = iaveToggle ? 'iave' : 'schools';
  const [className, setClassName] = useInput(currentClass.name);

  const cleanupAndClose = () => {
    setTimeout(() => {
      setClassName(currentClass.name);
      close();
    }, 250);
  };

  const save = () => {
    setLoading(true);

    const trimmedClassName = className.trim();

    if (trimmedClassName.length === 0) {
      setTimeout(() => {
        setLoading(false);
        toast.error(lang.classes[langKey].errorNameRequired);
      }, 200);
      return;
    }

    const request = {
      params: {
        schoolId: currentClass.schoolId,
        classId: currentClass.id,
      },
      data: {
        name: trimmedClassName,
      },
    };

    editSchoolClassRequest(request, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        close();
        toast.success(lang.classes[langKey].successEditClass);
        reload();
      } else {
        toast.error(lang.oops);
      }
    });
  };

  const actions = [
    {
      name: lang.cancel,
      onClick: cleanupAndClose,
      color: 'black',
    },
    {
      name: lang.confirm,
      onClick: save,
      loading: loading,
    },
  ];

  return (
    <Modal
      header={lang.classes[langKey].settingsTitle}
      open={open}
      close={close}
      center
      transition
      actions={actions}
    >
      <InputText
        value={className}
        label={lang.classes[langKey].classNameLabel}
        placeholder={lang.classes[langKey].classNameLabel}
        onChange={(event) => setClassName(event.target.value)}
      />
    </Modal>
  );
};

ClassSettingsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  currentClass: PropTypes.object,
  reload: PropTypes.func,
};

export default ClassSettingsModal;
