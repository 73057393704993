import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  button: {
    backgroundColor: theme.sidebarColor,
    boxShadow: '0px 4px 4px #0000004D',
    borderRadius: '6px',
    height: '30px',
    color: theme.sidebarText,
    '&:hover': {
      backgroundColor: theme.shade0,
    },
  },
}));
