import React from 'react';
import PropTypes from 'prop-types';

import { ERROR } from 'constants/eventTypes';
import lang from 'lang';

import Input from 'components/common/Input';

import useStyles from './styles';

const EventFilters = ({ eventTypeFilters, setEventTypeFilters, userFilter, setUserFilter }) => {
  const classes = useStyles();

  const eventTypeFilterOptions = [
    {
      label: lang.events.types.error,
      value: ERROR,
    },
  ];

  return (
    <div className={classes.eventFilters}>
      <Input
        type="select"
        label={lang.events.eventTypes}
        options={eventTypeFilterOptions}
        multiple
        value={eventTypeFilters}
        onChange={newValue => setEventTypeFilters(newValue)}
        className={classes.eventFilter}
      />
      <Input
        type="input"
        label={lang.events.emailOrUsername}
        placeholder={lang.events.emailOrUsername}
        value={userFilter}
        onChange={(event) => setUserFilter(event.target.value)}
        className={classes.eventFilter}
      />
    </div>
  );
};

EventFilters.propTypes = {
  eventTypeFilters: PropTypes.array,
  setEventTypeFilters: PropTypes.func,
  userFilter: PropTypes.string,
  setUserFilter: PropTypes.func,
};

export default EventFilters;
