import LocalizedStrings from 'react-localization';

const lang = new LocalizedStrings({
  en: {
    ok: 'Ok',
    add: 'Add',
    create: 'Create',
    publish: 'Publish',
    published: 'Published',
    submit: 'Submit',
    hide: 'Hide',
    preview: 'Preview',
    edit: 'Edit',
    cancel: 'Cancel',
    erase: 'Erase',
    'delete': 'Delete',
    remove: 'Remove',
    move: 'Move',
    duplicate: 'Duplicate',
    discard: 'Discard',
    clear: 'Clear',
    saveChanges: 'Save changes',
    save: 'Save',
    copy: 'Copy',
    oops: 'Something went wrong. Please try again',
    waitSeconds: 'This process might take a few seconds',
    waitMinutes: 'This process might take a few minutes',
    waitTime: 'This process might take some time',
    date: 'Date',
    previous: 'Previous',
    next: 'Next',
    'export': 'Export',
    en: 'English',
    pt: 'Portuguese',
    esES: 'Spanish',
    ptBR: 'Portuguese (Brazil)',
    unauthorizedMessage: 'Looks like you\'re not yet qualified to be in this area... Maybe next year!',
    notFoundMessage: 'Oh no! It seems that you lost your way around our website... Maybe it\'s not that Intuitive.',
    errorMessage: 'Something wrong happened... We\'ll be looking into fixing it as soon as possible.',
    noPermissionSpace: 'You don\'t have the permissions to access this space.',
    home: 'Home',
    account: 'Account',
    signout: 'Sign out',
    finished: 'Finished',
    example: 'Example',
    'private': 'Private',
    confirm: 'Confirm',
    total: 'Total',
    optional: 'optional',
    workInProgress: 'We are working to improve this feature',
    enable: 'Enable',
    disable: 'Disable',
    noOptionsAvailable: 'No options available',
    close: 'Close',
    'continue': 'Continue',
    'in': 'in',
    goBack: 'Go back',
    name: 'Name',
    email: 'E-mail',
    shortcuts: 'Shortcuts',
    leave: 'Leave',
    send: 'Send',
    comingSoon: 'Coming soon!',
    comment: 'Comment',
    help: 'Help',
    feedback: 'Feedback',
    copied: 'Copied',
    noEmail: 'No email',
    selectAll: 'Select all',
    general: 'General',
    discardWarning: 'If you continue, all the additions will be lost.',
    notFound: 'Not found',
    copyOf: 'Copy of',
    otherOptions: 'Other options',
    reschedule: 'Reschedule',
    results: 'Results',
    'new': 'New',
    filterBy: 'Filter by',
    copies: 'Copies',
    orderBy: 'Order by',
    oldest: 'Oldest',
    newest: 'Newest',
    relevancy: 'Relevancy',
    showMore: 'Show more',
    showLess: 'Show less',
    settings: 'Settings',
    scrollToSides: 'Scroll to the sides',
    download: 'Download',
    details: 'Details',
    options: 'Options',
    fileTooLarge: 'The file can\'t be larger than 10Mb',
    drag: 'Drag',
    dropHere: 'Drop here',
    changeLang: 'Change language',
    logout: 'Log out',
    video: 'Video',
    audio: 'Audio',
    tutorial: 'Tutorials',
    continueWith: 'Continue with',
    or: 'Or',
    notYou: 'Not your account?',
    guest: 'Guest',
    select: 'Select',
    internetWentWrong: 'Something went wrong. Please check your internet connection and try again',
    deleteSelected: 'Delete selected',
    removeselected: 'Remove selected',
    credentials: 'Credentials',
    removeSelected: 'Remove selected',
    moveSelected: 'Move selected',

    errors: 'Errors',
    time: {
      day: 'day',
      days: 'days',
      hour: 'hour',
      hours: 'hours',
      minute: 'minute',
      minutes: 'minutes',
      second: 'second',
      seconds: 'seconds',
    },
    appKeywords: {
      classes: 'Classes',
      tests: 'Tests',
      test: 'Test',
      threads: 'Threads',
      admin: 'Administrator',
      lesson: 'Lesson',
      subject: 'Subject',
      'class': 'Class',
      exercises: 'Exercises',
      exercise: 'Exercise',
      correction: 'Correction',
      answer: 'Answer',
      grade: 'Grade',
      group: 'Group',
      groups: 'Groups',
      personalGroup: 'My content',
      allGroups: 'All groups',
      personalSpace: 'Personal space',
      student: 'Student',
      students: 'Students',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (free trial)',
      premiumInfo: 'Experimental period for an indefinite duration. At the moment, you can use the platform and all its functionalities for free. Later, we will introduce different paid plans for teachers, but there will always be a free plan, despite some limitations in terms of functionalities.',
      rubrics: 'Rubrics',
      rubric: 'Rubric',
      criterion: 'Criteria',
      criteria: 'Criteria',
      classic: 'Classic',
      noClassification: 'No classification',
      worksheet: 'Worksheet',
      assessment: 'Assessment',
      assessments: 'Assessments',
      teachers: 'teachers',
      examGroups: 'Exam groups',
      examGroupsMin: 'Exam groups',
      email: 'Email',
      username: 'Username',
      name: 'Name',
    },
    events: {
      title: 'Events',
      eventType: 'Type',
      eventTypes: 'Types',
      user: 'User',
      category: 'Category',
      message: 'Message',
      date: 'Date',
      details: 'Details',
      noUser: 'No user',
      eventId: 'Event ID',
      service: 'Service',
      url: 'URL',
      query: 'Query Parameters',
      body: 'Request Body',
      stack: 'Error Stack',
      emailOrUsername: 'Username or e-mail',
      types: {
        info: 'Info',
        error: 'Error',
      },
    },
    students: {
      title: 'Students',
      addStudents: {
        header: 'Add students',
        studentFilter: 'Students',
        uploadFile: 'Upload file',
        selectInput: 'Search',
        exampleFile: 'Example file',
        hintTargets: 'Insert the e-mail or username of the student you wish to add to the class',
        success: 'The students were successfully added',
      },
      studentsTable: {
        name: 'Name',
        email: 'E-mail',
        username: 'Username',
        actions: 'Actions',
        schoolIdentifier: 'School identifier',
        password: 'Password',
        recover: 'Recover',
        recoverPassword: 'Recover password',
        recoverPasswordDescription: 'Are you sure you want to recover this student password? This action is not reversible.',
        newPassword: 'New password',
        passwordRecovered: 'The password was successfully recovered',
      },
      importStudents: {
        header: 'Import students',
        description: 'Click in the button below to download the example file for importing students to the platform. Fill the file with your own students and keeping the same file format (.csv).',
        classDescription: 'Click in the button below to download the example file for importing students to the class. Fill the file with your own students and keeping the same file format (.csv).',
        descriptionIAVE: 'Choose the file for uploading the students below.',
        exampleDownload: 'Download example file',
        importStudentsLabel: 'Students file (.csv)',
        importStudentsPlaceholder: 'Click here to choose the file',
        successImport: 'The students were sucessfully imported',
        fullName: 'Name',
        username: 'Username',
        email: 'Email',
        password: 'Password',
        schoolIdentifier: 'School identifier',
        importSuccessDescription: 'The students were successfully imported. Click in the button below to download the credentials',
        nonExistentClassesError: 'There are exam groups in the file that don\'t exist in the school',
        noValidStudentsError: 'No valid students were found in the uploaded file',
        successImportStudents: (number) => `${number} ${number === 1 ? 'student was added' : 'students were added'} to the class.`,
        successImportStudentsIAVE: (number) => `${number} ${number === 1 ? 'student was added' : 'students were added'} to the exam group.`,
        alreadyInClass: (number) => `${number === 1 ? `${number} student was already` : `${number} were already`} present in the class.`,
        alreadyInClassIAVE: (number) => `${number === 1 ? `${number} student was already` : `${number} were already`} present in the exam group.`,
        notFoundStudents: (number) => `${number} ${number === 1 ? 'student was not added' : 'students were not added'} to the class because they contain incorrect information or do not belong to this school. ${number === 1 ? 'Line: ' : 'Lines: '} `,
        notFoundStudentsIAVE: (number) => `${number} ${number === 1 ? 'student was not added' : 'students were not added'} to the exam group because they contain incorrect information or do not belong to this school. ${number === 1 ? 'Line: ' : 'Lines: '}`,
        totalStudents: (number) => `${number} ${number === 1 ? 'total student' : 'total students'} in the file.`,
        duplicateError: {
          email: 'Some e-mails are duplicated',
          username: 'Some usernames are duplicated',
        },
      },
      studentFilters: {
        email: 'E-mail',
        username: 'Username',
        classes: 'Classes',
        groups: 'Groups',
        fullName: 'Name',
      },
      deleteStudent: {
        title: 'Delete student',
        titleMultiple: 'Delete students',
        deleteStudentDescription: 'Are you sure you want to delete this student? This action is not reversible.',
        deleteStudentDescriptionMultiple: 'Are you sure you want to delete these students? This action is not reversible.',
        deleteSuccess: 'The student was successfully deleted',
        deleteSuccessMultiple: 'The students were successfully deleted',
      },
      removeStudent: {
        title: 'Remove student',
        titleMultiple: 'Remove students',
        removeStudentDescription: 'Are you sure you want to remove this student? This action is not reversible.',
        removeStudentDescriptionMultiple: 'Are you sure you want to remove these students? This action is not reversible.',
        removeSuccess: 'The student was successfully removed',
        removeSuccessMultiple: 'The students were successfully removed',
      },
      moveStudents: {
        title: 'Move students',
        moveStudentsDescription: 'Select the class where you want to move the student(s)',
        moveStudentsDescriptionIAVE: 'Select the exam group where you want to move the student(s)',
        move: 'Move',
        success: 'The student was successfully moved',
        successMultiple: 'The students were successfully moved',
        successMoveStudents: (number, _class) => `Successfully moved ${number} ${number === 1 ? 'student' : 'students'} to class ${_class}.`,
        successMoveStudentsIAVE: (number, _class) => `Successfully moved ${number} ${number === 1 ? 'student' : 'students'} to exam group ${_class}.`,
        alreadyInClass: (number) => `${number === 1 ? `${number} student was already` : `${number} were already`} present in the destination class: `,
        alreadyInClassIAVE: (number) => `${number === 1 ? `${number} student was already` : `${number} were already`} present in the destination exam grup: `,

      },
      studentActions: {
        closeAttempts: 'Close exams',
        attemptsOpen: 'exams open',
        closeAttemptsDescription: 'Are you sure you want to close all ongoing exams? All students currently answering an exam will be forced to leave. This action is not reversible.',
        unblockUsers: 'Unblock users',
        unblockUsersDescription: 'Are you sure you want to unblock all currently blocked users?',
        closeAttemptsSuccess: 'The exams were successfully closed',
      },
    },
    synchronization: {
      title: 'Synchronization',
      synchronizeExams: {
        header: 'Synchronize exams',
        description: 'When you click confirm, the exams associated with the students you entered into the platform will be downloaded and stored on the local server to be taken at the scheduled time. If any changes are made to the exam, click again to download the changes made.',
        synchronizeSuccess: 'The exams were successfully synchronized.',
      },
      syncSummary: {
        localServer: 'Local server',
        centralServer: 'Central server',
        students: 'Students',
        exams: 'Exams',
        attempts: 'Attempts',
      },
      synchronizeAttempts: {
        header: 'Synchronize attempts',
        description: 'When you click confirm, the attempts saved on the local server will be synchronized with the central server. If any changes are made to any attempt, click again to synchronize the changes made.',
        synchronizeSuccess: 'The attempts were successfully synchronized.',
      },
    },
    exams: {
      title: 'Exams',
      onGoingAttempts: 'Ongoing exams',
      exportAttemptsNotAvailable: 'There are no attempts to export',
      examsTable: {
        examName: 'Exam name',
        groupName: 'Group name',
        totalStudents: 'Total Students',
        totalAttempts: 'Total started exams',
        totalGeneratedAttempts: 'Total generated exams',
        finishedAttempts: 'Finished exams',
        generatingPDFs: 'Generating the PDFs',
        zippingPDFs: 'Zipping the PDFs',
        generateSuccessDescription: 'The exams were successfully exported to PDF! Click in the button below to download.',
        averageTime: 'Average completion time',
        noAttempts: 'No students',
        examInProgress: 'Exam in progress',
      },
    },
    teachers: {
      title: 'Teachers',
      teachersTable: {
        name: 'Name',
        username: 'Username',
        email: 'E-mail',
        groups: 'Groups',
        actions: 'Actions',
      },
      addTeachers: {
        header: 'Add teachers',
        fullName: 'Nome',
        username: 'Nome de utilizador',
        email: 'E-mail',
        password: 'Palavra-passe',
        teachersAddedSuccess: 'The teachers were successfully added',
        description: 'Click the button below to download the example file. Fill the file with the teachers\' names and e-mails. Insert the file below and click confirm. All the teachers will receive an e-mail invitation to join the institution.',
        descriptionIAVE: 'Click the button below to download the example file. Fill the file with the teachers\' names and usernames. Insert the file below and click confirm.',
        exampleDownload: 'Example file',
        teachersFileLabel: 'Teachers file',
        teachersFilePlaceholder: 'Click here to add a new file',
        addSuccessDescriptionIAVE: 'All the teacher were successfully created. Click the button below to download the credentials. Attention: You will only be able to download the credentials once. Save this file in a secure location.',
        addSuccessDescription: 'The teachers were successfully added. An e-mail was sent to all the new teachers with their credentials. In case a teacher already had an account, he was notified that he was added to the institution space.',
        nameMissingError: 'The teachers\' names can\'t be empty.',
        emailMissingError: 'The teachers\' e-mails can\'t be empty.',
        emailWrongFormatError: 'Some e-mails are not in the correct format.',
        emailDuplicateError: 'Some e-mails are duplicated.',
        totalTeachersSurpassedError: (limit, extra) => `You have surpassed the limit of ${limit} ${limit === 1 ? 'teacher' : 'teachers'} allowed for your institution by ${extra} ${extra === 1 ? 'teacher' : 'teachers'}. Remove teachers from the the school or from the file to proceed.`,
        usernameMissingError: 'The teachers\' username can\'t be empty.',
        usernameDuplicateError: 'Some usernames are duplicated.',
      },
      removeTeacher: {
        removeTitle: 'Remove teacher',
        removeTitleMultiple: 'Remove teachers',
        removeTeacherDescription: 'Are you sure you want to remove this teacher from the institution space? He will lose access to all the contents he created in the space. This action is not reversible.',
        removeTeacherDescriptionMultiple: 'Are you sure you want to remove these teachers from the institution space? They will lose access to all the contents they created in the space. This action is not reversible.',
        removeSuccess: 'The teacher was successfully removed from the institution space',
        removeSuccessMultiple: 'The teachers were successfully removed from the institution space',
        deleteTitle: 'Delete teacher',
        deleteTitleMultiple: 'Delete teachers',
        deleteTeacherDescription: 'Are you sure you want to delete this teacher? This action is not reversible.',
        deleteTeacherDescriptionMultiple: 'Are you sure you want to delete these teachers? This action is not reversible.',
        deleteSuccess: 'The teacher was successfully deleted',
        deleteSuccessMultiple: 'The teachers were successfully deleted',
      },
    },
    classes: {
      schools: {
        createClass: 'Create class',
        classNameLabel: 'Class name',
        classNameEmpty: 'The class name can\'t be empty',
        deleteClass: 'Delete class',
        deleteClasses: 'Delete classes',
        deleteClassDescription: 'Are you sure you want to delete this class? This action is not reversible.',
        deleteClassesDescription: 'Are you sure you want to delete these classes? This action is not reversible.',
        successDeleteClass: 'The class was successfully deleted',
        successDeleteClasses: 'The classes were successfully deleted',
        settingsTitle: 'Class settings',
        errorNameRequired: 'The class name is required',
        successEditClass: 'The class was successfully edited',
      },
      iave: {
        createClass: 'Create exam group',
        classNameLabel: 'Group name',
        classNameEmpty: 'The group name can\'t be empty',
        deleteClass: 'Delete exam group',
        deleteClasses: 'Delete exam groups',
        deleteClassDescription: 'Are you sure you want to delete this exam group? This action is not reversible.',
        deleteClassesDescription: 'Are you sure you want to delete these exam groups? This action is not reversible.',
        successDeleteClass: 'The exam group was successfully deleted',
        successDeleteClasses: 'The exam groups were successfully deleted',
        settingsTitle: 'Exam group settings',
        errorNameRequired: 'The exam group name is required',
        successEditClass: 'The exam group was successfully edited',
      },
    },
  },
  pt: {
    ok: 'Ok',
    add: 'Adicionar',
    create: 'Criar',
    publish: 'Publicar',
    published: 'Publicado',
    submit: 'Submeter',
    hide: 'Esconder',
    preview: 'Visualizar',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Apagar',
    'delete': 'Apagar',
    remove: 'Remover',
    move: 'Mover',
    duplicate: 'Duplicar',
    discard: 'Descartar',
    clear: 'Limpar',
    saveChanges: 'Guardar alterações',
    save: 'Gravar',
    copy: 'Copiar',
    oops: 'Algo não correu bem. Por favor tente novamente',
    waitSeconds: 'Este processo pode demorar alguns segundos',
    waitMinutes: 'Este processo pode demorar alguns minutos',
    waitTime: 'Este processo pode demorar algum tempo',
    date: 'Data',
    previous: 'Anterior',
    next: 'Seguinte',
    'export': 'Exportar',
    en: 'Inglês',
    pt: 'Português',
    esES: 'Espanhol',
    ptBR: 'Português (Brasil)',
    unauthorizedMessage: 'Parece que ainda não é suficientemente qualificado para estar nesta área... Talvez no próximo ano!',
    notFoundMessage: 'Oh não! Parece que se perdeu no nosso site... Talvez não seja assim tão Intuitivo.',
    errorMessage: 'Algo de errado aconteceu... Vamos verificar e corrigir o mais rápido possível.',
    noPermissionSpace: 'Não tem permissões para aceder a este espaço.',
    home: 'Início',
    account: 'Conta',
    signout: 'Sair',
    finished: 'Terminado',
    example: 'Exemplo',
    'private': 'Privado',
    confirm: 'Confirmar',
    total: 'Total',
    optional: 'opcional',
    workInProgress: 'Estamos a trabalhar para melhorar esta funcionalidade',
    enable: 'Ativar',
    disable: 'Desativar',
    noOptionsAvailable: 'Nenhuma opção disponível',
    close: 'Fechar',
    'continue': 'Continuar',
    'in': 'em',
    goBack: 'Voltar atrás',
    name: 'Nome',
    email: 'E-mail',
    shortcuts: 'Atalhos',
    leave: 'Sair',
    send: 'Enviar',
    comingSoon: 'Em breve!',
    comment: 'Comentário',
    help: 'Ajuda',
    feedback: 'Sugestões',
    copied: 'Copiado',
    noEmail: 'Sem email',
    selectAll: 'Selecionar tudo',
    general: 'Geral',
    discardWarning: 'Se continuar, todas as adições serão perdidas.',
    notFound: 'Não encontrado',
    copyOf: 'Cópia de',
    otherOptions: 'Outras opções',
    reschedule: 'Reagendar',
    results: 'Resultados',
    'new': 'Novo',
    filterBy: 'Filtrar por',
    copies: 'Cópias',
    orderBy: 'Ordenar por',
    oldest: 'Mais antigo',
    newest: 'Mais recente',
    relevancy: 'Relevância',
    showMore: 'Ver mais',
    showLess: 'Ver menos',
    settings: 'Definições',
    scrollToSides: 'Move para os lados',
    download: 'Descarregar',
    details: 'Detalhes',
    options: 'Opções',
    fileTooLarge: 'O ficheiro não pode ser maior que 10Mb',
    drag: 'Arrastar',
    dropHere: 'Arrasta para aqui',
    changeLang: 'Mudar linguagem',
    logout: 'Terminar sessão',
    video: 'Vídeo',
    audio: 'Áudio',
    tutorial: 'Tutoriais',
    continueWith: 'Continuar com',
    or: 'Ou',
    notYou: 'Não é a sua conta?',
    guest: 'Convidado',
    select: 'Selecionar',
    internetWentWrong: 'Algo não correu bem. Por favor verifique a sua conexão à internet e tente novamente',
    deleteSelected: 'Eliminar selecionados',
    removeselected: 'Remover selecionados',
    credentials: 'Credenciais',
    removeSelected: 'Remover selecionados',
    moveSelected: 'Mover selecionados',
    errors: 'Erros',
    time: {
      day: 'dia',
      days: 'dias',
      hour: 'hora',
      hours: 'horas',
      minute: 'minuto',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
    },
    appKeywords: {
      classes: 'Turmas',
      tests: 'Testes',
      test: 'Teste',
      threads: 'Fóruns',
      admin: 'Administrador',
      lesson: 'Aula',
      subject: 'Matéria',
      'class': 'Turma',
      exercises: 'Exercícios',
      exercise: 'Exercício',
      correction: 'Correção',
      answer: 'Resposta',
      grade: 'Nota',
      group: 'Grupo',
      groups: 'Grupos',
      personalGroup: 'Meus conteúdos',
      allGroups: 'Todos os grupos',
      personalSpace: 'Espaço pessoal',
      student: 'Estudante',
      students: 'Estudantes',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (período experimental)',
      premiumInfo: 'Período experimental por duração indefinida. Neste momento, pode utilizar a plataforma e todas as suas funcionalidades de forma gratuita e ilimitada. Mais tarde, iremos introduzir diferentes planos para professores, sendo que haverá sempre um plano gratuito apesar de ter certas limitações em termos de funcionalidades.',
      rubrics: 'Rubricas',
      rubric: 'Rubrica',
      criterion: 'Critério',
      criteria: 'Critérios',
      classic: 'Clássico',
      noClassification: 'Sem classificação',
      worksheet: 'Ficha',
      assessment: 'Avaliação',
      assessments: 'Avaliações',
      teachers: 'professores',
      examGroups: 'Grupos de provas',
      examGroupsMin: 'Grupos Provas',
      email: 'Email',
      username: 'Nome de utilizador',
      name: 'Nome',
    },
    events: {
      title: 'Eventos',
      eventType: 'Tipo',
      eventTypes: 'Tipos',
      user: 'Utilizador',
      category: 'Categoria',
      message: 'Mensagem',
      date: 'Data',
      details: 'Detalhes',
      noUser: 'Sem utilizador',
      eventId: 'ID do evento',
      service: 'Serviço',
      url: 'URL',
      query: 'Parametros de pesquisa',
      body: 'Corpo do pedido',
      stack: 'Pilha de erro',
      emailOrUsername: 'Nome de utilizador ou e-mail',
      types: {
        info: 'Info',
        error: 'Erro',
      },
    },
    students: {
      title: 'Estudantes',
      addStudents: {
        header: 'Adicionar estudantes',
        studentFilter: 'Estudantes',
        uploadFile: 'Importar ficheiro',
        selectInput: 'Procurar',
        exampleFile: 'Ficheiro exemplo',
        hintTargets: 'Insira o e-mail ou nome de utilizador do estudante que deseja adicionar à turma',
        success: 'Os estudantes foram adicionados com sucesso',
      },
      studentsTable: {
        name: 'Nome',
        email: 'E-mail',
        username: 'Nome de utilizador',
        actions: 'Ações',
        schoolIdentifier: 'Identificador da escola',
        password: 'Palavra-passe',
        recover: 'Recuperar',
        recoverPassword: 'Recuperar palavra-passe',
        recoverPasswordDescription: 'Tem a certeza que deseja recuperar a palavra-passe deste estudante? Esta ação não é reversível.',
        newPassword: 'Nova palavra-passe',
        passwordRecovered: 'A palavra-passe foi recuperada com sucesso',
      },
      importStudents: {
        header: 'Importar estudantes',
        description: 'Clique no botão abaixo para fazer download de um ficheiro exemplo de importação de estudantes para a plataforma. Preencha o ficheiro com os seus estudantes mantendo sempre o mesmo formato do ficheiro (.csv).',
        classDescription: 'Clique no botão abaixo para fazer download de um ficheiro exemplo de importação de estudantes para a turma. Preencha o ficheiro com os seus estudantes mantendo sempre o mesmo formato do ficheiro (.csv).',
        descriptionIAVE: 'Escolha o ficheiro para importar os estudantes abaixo.',
        exampleDownload: 'Download do ficheiro exemplo',
        importStudentsLabel: 'Ficheiro de estudantes (.csv)',
        importStudentsPlaceholder: 'Clique aqui para escolher o ficheiro',
        successImport: 'Os estudantes foram importados com sucesso',
        fullName: 'Nome',
        username: 'Nome de utilizador',
        email: 'Email',
        password: 'Palavra-passe',
        schoolIdentifier: 'Identificador da escola',
        importSuccessDescription: 'Os estudantes foram importados com sucesso. Clique no botão abaixo para fazer download das credenciais.',
        nonExistentClassesError: 'Existem grupos de provas no ficheiro que não existem na escola',
        noValidStudentsError: 'Não foram encontrados estudantes válidos no ficheiro importado',
        successImportStudents: (number) => `${number} ${number === 1 ? 'estudante foi adicionado' : 'estudantes foram adicionados'} à turma.`,
        successImportStudentsIAVE: (number) => `${number} ${number === 1 ? 'estudante foi adicionado' : 'estudantes foram adicionados'} ao grupo de provas.`,
        alreadyInClass: (number) => `${number === 1 ? `${number} estudante já estava presente` : `${number} estudantes já estavam presentes`} na turma.`,
        alreadyInClassIAVE: (number) => `${number === 1 ? `${number} estudante já estava presente` : `${number} estudantes já estavam presentes`} no grupo de provas.`,
        notFoundStudents: (number) => `${number} ${number === 1 ? 'estudante não foi adicionado' : 'estudantes não foram adicionados'} à turma porque contêm informação incorreta ou não pertencem a esta escola. ${number === 1 ? 'Linha: ' : 'Linhas: '}`,
        notFoundStudentsIAVE: (number) => `${number} ${number === 1 ? 'estudante não foi adicionado' : 'estudantes não foram adicionados'} ao grupo de provas porque contêm informação incorreta ou não pertencem a esta escola. ${number === 1 ? 'Linha: ' : 'Linhas: '}`,
        totalStudents: (number) => `${number} ${number === 1 ? 'estudante total' : 'estudantes totais'} no ficheiro.`,
        duplicateError: {
          email: 'Alguns e-mails estão duplicados',
          username: 'Alguns nomes de utilizador estão duplicados',
        },
      },
      studentFilters: {
        email: 'E-mail',
        username: 'Nome de utilizador',
        classes: 'Turmas',
        groups: 'Grupos',
        fullName: 'Nome',
      },
      deleteStudent: {
        title: 'Eliminar estudante',
        titleMultiple: 'Eliminar estudantes',
        deleteStudentDescription: 'Tem a certeza que deseja eliminar este estudante? Esta ação não é reversível.',
        deleteStudentDescriptionMultiple: 'Tem a certeza que deseja eliminar estes estudantes? Esta ação não é reversível.',
        deleteSuccess: 'O estudante foi eliminado com sucesso',
        deleteSuccessMultiple: 'Os estudantes foram eliminados com sucesso',
      },
      removeStudent: {
        title: 'Remover estudante',
        titleMultiple: 'Remover estudantes',
        removeStudentDescription: 'Tem a certeza que deseja remover este estudante? Esta ação não é reversível.',
        removeStudentDescriptionMultiple: 'Tem a certeza que deseja remover estes estudantes? Esta ação não é reversível.',
        removeSuccess: 'O estudante foi removido com sucesso',
        removeSuccessMultiple: 'Os estudantes foram removidos com sucesso',
      },
      moveStudents: {
        title: 'Mover estudantes',
        moveStudentsDescription: 'Selecione a turma para onde deseja mover o(s) estudante(s)',
        moveStudentsDescriptionIAVE: 'Selecione o grupo de provas para onde deseja mover o(s) estudante(s)',
        move: 'Mover',
        success: 'O estudante foi movido com sucesso',
        successMultiple: 'Os estudantes foram movidos com sucesso',
        successMoveStudents: (number, _class) => `${number === 1 ? 'Movido' : 'Movidos'} com sucesso ${number} ${number === 1 ? 'estudante' : 'estudantes'} para a turma ${_class}.`,
        successMoveStudentsIAVE: (number, _class) => `${number === 1 ? 'Movido' : 'Movidos'} com sucesso ${number} ${number === 1 ? 'estudante' : 'estudantes'} para o grupo de provas ${_class}.`,
        alreadyInClass: (number) => `${number === 1 ? `${number} estudante já estava presente` : `${number} estudantes já estavam presentes`} na turma de destino: `,
        alreadyInClassIAVE: (number) => `${number === 1 ? `${number} estudante já estava presente` : `${number} estudantes já estavam presentes`} no grupo de provas de destino: `,
      },
      studentActions: {
        closeAttempts: 'Fechar provas',
        attemptsOpen: 'provas abertas',
        closeAttemptsDescription: 'Tem a certeza que quer fechar todas as provas em curso? Todos os alunos a realizar a prova neste momento vão ser forçados a sair. Esta ação não é reversível.',
        unblockUsers: 'Desbloquear utilizadores',
        unblockUsersDescription: 'Tem a certeza que quer desbloquear todos os utilizadores atualmente bloqueados?',
        closeAttemptsSuccess: 'As provas foram fechadas com sucesso',
      },
    },
    synchronization: {
      title: 'Sincronização',
      synchronizeExams: {
        header: 'Sincronizar exames',
        description: 'Ao clicar confirmar, os exames associados com os alunos que introduziu na plataforma serão descarregados e guardados no servidor local para serem realizados na altura programada. Se alguma alteração for feita ao exame, clique de novo para descarregar as mudanças realizadas.',
        synchronizeSuccess: 'Os exames foram sincronizados com sucesso.',
      },
      syncSummary: {
        localServer: 'Servidor local',
        centralServer: 'Servidor central',
        students: 'Estudantes',
        exams: 'Exames',
        attempts: 'Respostas',
      },
      synchronizeAttempts: {
        header: 'Sincronizar respostas',
        description: 'Ao clicar confirmar, as respostas guardadas no servidor local serão sincronizadas com o servidor central. Se alguma alteração for feita a alguma resposta, clique de novo para sincronizar as mudanças realizadas.',
        synchronizeSuccess: 'As respostas foram sincronizadas com sucesso.',
      },
    },
    exams: {
      title: 'Exames',
      onGoingAttempts: 'Provas em curso',
      exportAttemptsNotAvailable: 'Não existem provas para exportar',
      examsTable: {
        examName: 'Nome da prova',
        groupName: 'Nome do grupo',
        totalStudents: 'Total de estudantes',
        totalAttempts: 'Total de provas começadas',
        totalGeneratedAttempts: 'Total de provas geradas',
        finishedAttempts: 'Provas terminadas',
        generatingPDFs: 'A gerar os PDFs',
        zippingPDFs: 'A criar o ficheiro zip',
        generateSuccessDescription: 'As provas foram exportadas para PDF com sucesso! Clique no botão abaixo para fazer download.',
        averageTime: 'Tempo médio de conclusão',
        noAttempts: 'Sem estudantes',
        examInProgress: 'Prova em curso',
      },
    },
    teachers: {
      title: 'Professores',
      teachersTable: {
        name: 'Nome',
        username: 'Nome de utilizador',
        email: 'E-mail',
        groups: 'Grupos',
        actions: 'Ações',
      },
      addTeachers: {
        header: 'Adicionar professores',
        fullName: 'Nome',
        username: 'Nome de utilizador',
        email: 'E-mail',
        password: 'Palavra-passe',
        teachersAddedSuccess: 'Os professores foram adicionados com sucesso',
        description: 'Clique no botão abaixo para fazer download do ficheiro exemplo. Preencha o ficheiro com os nomes e e-mails dos professores. Coloque o ficheiro abaixo e clique confirmar. Todos os professores receberão um convite por e-mail para se juntar à instituição.',
        descriptionIAVE: 'Clique no botão abaixo para fazer download do ficheiro exemplo. Preencha o ficheiro com os nomes e nomes de utilizador dos professores. Coloque o ficheiro abaixo e clique confirmar.',
        exampleDownload: 'Ficheiro exemplo',
        teachersFileLabel: 'Ficheiro de professores',
        teachersFilePlaceholder: 'Clique aqui para adicionar um ficheiro',
        addSuccessDescriptionIAVE: 'Os professores foram criados com sucesso. Clique no botão abaixo para fazer download das credenciais. Atenção: Só vai poder fazer o download das credenciais uma única vez. Guarde este ficheiro num local seguro.',
        addSuccessDescription: 'Os professores foram adicionados com sucesso. Um e-mail foi enviado a todos os novos professores com as suas credenciais. No caso em que um professor já tinha conta, o professor foi notificado que foi adicionado ao espaço da instituição.',
        nameMissingError: 'Os nomes dos professores não podem estar vazios.',
        emailMissingError: 'Os e-mails dos professores não podem estar vazios.',
        emailWrongFormatError: 'Alguns e-mails não estão no formato correto.',
        emailDuplicateError: 'Alguns e-mails são duplicados.',
        totalTeachersSurpassedError: (limit, extra) => `Ultrapassou em ${extra} ${extra === 1 ? 'professor' : 'professores'} o limite de ${limit} ${limit === 1 ? 'professor permitido' : 'professores permitidos'} para a sua instituição. Remova professores da instituição ou do ficheiro para continuar.`,
        usernameMissingError: 'Os nomes de utilizador dos professores não podem estar vazios.',
        usernameDuplicateError: 'Alguns nomes de utilizador são duplicados.',
      },
      removeTeacher: {
        removeTitle: 'Remover professor',
        removeTitleMultiple: 'Remover professores',
        removeTeacherDescription: 'Tem a certeza que quer remover este professor do espaço da instituição? Ele vai perder acesso a todos os conteúdos que ele criou dentro do espaço. Esta ação não é reversível.',
        removeTeacherDescriptionMultiple: 'Tem a certeza que quer remover estes professores do espaço da instituição? Eles vão perder acesso a todos os conteúdos que eles criaram dentro do espaço. Esta ação não é reversível.',
        removeSuccess: 'O professor foi removido do espaço da instituição com sucesso',
        removeSuccessMultiple: 'Os professores foram removidos do espaço da instituição com sucesso',
        deleteTitle: 'Eliminar professor',
        deleteTitleMultiple: 'Eliminar professores',
        deleteTeacherDescription: 'Tem a certeza que quer eliminar este professor? Esta ação não é reversível.',
        deleteTeacherDescriptionMultiple: 'Tem a certeza que quer eliminar estes professores? Esta ação não é reversível.',
        deleteSuccess: 'O professor foi eliminado com sucesso',
        deleteSuccessMultiple: 'Os professores foram eliminados com sucesso',
      },
    },
    classes: {
      schools: {
        createClass: 'Criar turma',
        classNameLabel: 'Nome da turma',
        classNameEmpty: 'O nome da turma não pode estar vazio',
        deleteClass: 'Apagar turma',
        deleteClasses: 'Apagar turmas',
        deleteClassDescription: 'Tem a certeza que deseja apagar esta turma? Esta ação não é reversível.',
        deleteClassesDescription: 'Tem a certeza que deseja apagar estas turmas? Esta ação não é reversível.',
        successDeleteClass: 'A turma foi apagada com sucesso',
        successDeleteClasses: 'As turmas foram apagadas com sucesso',
        settingsTitle: 'Definições da turma',
        errorNameRequired: 'O nome da turma é obrigatório',
        successEditClass: 'A turma foi editada com sucesso',
      },
      iave: {
        createClass: 'Criar grupo de provas',
        classNameLabel: 'Nome do grupo',
        deleteClass: 'Apagar grupo de provas',
        deleteClasses: 'Apagar grupos de provas',
        deleteClassDescription: 'Tem a certeza que deseja apagar este grupo de provas? Esta ação não é reversível.',
        deleteClassesDescription: 'Tem a certeza que deseja apagar estes grupos de provas? Esta ação não é reversível.',
        successDeleteClass: 'O grupo de provas foi apagado com sucesso',
        successDeleteClasses: 'Os grupos de provas foram apagados com sucesso',
        classNameEmpty: 'O nome do grupo de provas não pode estar vazio',
        settingsTitle: 'Definições do grupo de provas',
        errorNameRequired: 'O nome do grupo de provas é obrigatório',
        successEditClass: 'O grupo de provas foi editado com sucesso',
      },
    },
  },
  esES: {
    ok: 'Ok',
    add: 'Añadir',
    create: 'Crear',
    publish: 'Publicar',
    published: 'Publicado',
    submit: 'Enviar',
    hide: 'Ocultar',
    preview: 'Vista previa',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Borrar',
    'delete': 'Eliminar',
    remove: 'Quitar',
    move: 'Mover',
    duplicate: 'Duplicar',
    discard: 'Descartar',
    clear: 'Limpiar',
    saveChanges: 'Guardar cambios',
    save: 'Guardar',
    copy: 'Copiar',
    oops: 'Algo salió mal. Por favor intenta de nuevo',
    waitSeconds: 'Este proceso puede tardar unos segundos',
    waitMinutes: 'Este proceso puede tardar unos minutos',
    waitTime: 'Este proceso puede tardar un tiempo',
    date: 'Fecha',
    previous: 'Anterior',
    next: 'Siguiente',
    'export': 'Exportar',
    en: 'Inglés',
    pt: 'Portugués',
    esES: 'Español',
    ptBR: 'Portugués (Brasil)',
    unauthorizedMessage: 'Parece que aún no estás calificado para estar en esta área... ¡Tal vez el próximo año!',
    notFoundMessage: '¡Oh no! Parece que te perdiste en nuestra página web... Quizás no es tan intuitiva.',
    errorMessage: 'Algo salió mal... Lo solucionaremos lo antes posible.',
    noPermissionSpace: 'No tienes permisos para acceder a este espacio.',
    home: 'Inicio',
    account: 'Cuenta',
    signout: 'Cerrar sesión',
    finished: 'Terminado',
    example: 'Ejemplo',
    'private': 'Privado',
    confirm: 'Confirmar',
    total: 'Total',
    optional: 'opcional',
    workInProgress: 'Estamos trabajando para mejorar esta función',
    enable: 'Habilitar',
    disable: 'Deshabilitar',
    noOptionsAvailable: 'No hay opciones disponibles',
    close: 'Cerrar',
    'continue': 'Continuar',
    'in': 'en',
    goBack: 'Volver',
    name: 'Nombre',
    email: 'Correo electrónico',
    shortcuts: 'Atajos',
    leave: 'Salir',
    send: 'Enviar',
    comingSoon: '¡Próximamente!',
    comment: 'Comentario',
    help: 'Ayuda',
    feedback: 'Comentarios',
    copied: 'Copiado',
    noEmail: 'Sin correo',
    selectAll: 'Seleccionar todo',
    general: 'General',
    discardWarning: 'Si continúas, todas las adiciones se perderán.',
    notFound: 'No encontrado',
    copyOf: 'Copia de',
    otherOptions: 'Otras opciones',
    reschedule: 'Reprogramar',
    results: 'Resultados',
    'new': 'Nuevo',
    filterBy: 'Filtrar por',
    copies: 'Copias',
    orderBy: 'Ordenar por',
    oldest: 'Más antiguo',
    newest: 'Más reciente',
    relevancy: 'Relevancia',
    showMore: 'Mostrar más',
    showLess: 'Mostrar menos',
    settings: 'Configuraciones',
    scrollToSides: 'Desplazar a los lados',
    download: 'Descargar',
    details: 'Detalles',
    options: 'Opciones',
    fileTooLarge: 'El archivo no puede ser mayor a 10Mb',
    drag: 'Arrastrar',
    dropHere: 'Soltar aquí',
    changeLang: 'Cambiar idioma',
    logout: 'Cerrar sesión',
    video: 'Video',
    audio: 'Audio',
    tutorial: 'Tutoriales',
    continueWith: 'Continuar con',
    or: 'O',
    notYou: '¿No es tu cuenta?',
    guest: 'Invitado',
    select: 'Seleccionar',
    internetWentWrong: 'Algo salió mal. Verifica tu conexión a internet y vuelve a intentarlo',
    deleteSelected: 'Eliminar seleccionados',
    removeselected: 'Quitar seleccionados',
    credentials: 'Credenciales',
    removeSelected: 'Quitar seleccionados',
    moveSelected: 'Mover seleccionados',

    errors: 'Errores',
    time: {
      day: 'día',
      days: 'días',
      hour: 'hora',
      hours: 'horas',
      minute: 'minuto',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
    },
    appKeywords: {
      classes: 'Clases',
      tests: 'Pruebas',
      test: 'Prueba',
      threads: 'Hilos',
      admin: 'Administrador',
      lesson: 'Lección',
      subject: 'Asignatura',
      'class': 'Clase',
      exercises: 'Ejercicios',
      exercise: 'Ejercicio',
      correction: 'Corrección',
      answer: 'Respuesta',
      grade: 'Calificación',
      group: 'Grupo',
      groups: 'Grupos',
      personalGroup: 'Mi contenido',
      allGroups: 'Todos los grupos',
      personalSpace: 'Espacio personal',
      student: 'Estudiante',
      students: 'Estudiantes',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (prueba gratuita)',
      premiumInfo: 'Período experimental de duración indefinida. Por el momento, puedes usar la plataforma y todas sus funcionalidades de forma gratuita. Más adelante, introduciremos diferentes planes de pago para profesores, pero siempre habrá un plan gratuito, a pesar de algunas limitaciones en cuanto a funcionalidades.',
      rubrics: 'Rúbricas',
      rubric: 'Rúbrica',
      criterion: 'Criterios',
      criteria: 'Criterios',
      classic: 'Clásico',
      noClassification: 'Sin clasificación',
      worksheet: 'Hoja de trabajo',
      assessment: 'Evaluación',
      assessments: 'Evaluaciones',
      teachers: 'profesores',
      examGroups: 'Grupos de exámenes',
      examGroupsMin: 'Grupos de exámenes',
      email: 'Correo electrónico',
      username: 'Nombre de usuario',
      name: 'Nombre',
    },
    events: {
      title: 'Eventos',
      eventType: 'Tipo',
      eventTypes: 'Tipos',
      user: 'Usuario',
      category: 'Categoría',
      message: 'Mensaje',
      date: 'Fecha',
      details: 'Detalles',
      noUser: 'Sin usuario',
      eventId: 'ID del evento',
      service: 'Servicio',
      url: 'URL',
      query: 'Parámetros de consulta',
      body: 'Cuerpo de solicitud',
      stack: 'Pila de errores',
      emailOrUsername: 'Nombre de usuario o correo electrónico',
      types: {
        info: 'Info',
        error: 'Error',
      },
    },
    students: {
      title: 'Estudiantes',
      addStudents: {
        header: 'Añadir estudiantes',
        studentFilter: 'Estudiantes',
        uploadFile: 'Subir archivo',
        selectInput: 'Buscar',
        exampleFile: 'Archivo de ejemplo',
        hintTargets: 'Introduce el correo electrónico o nombre de usuario del estudiante que deseas agregar a la clase',
        success: 'Los estudiantes se añadieron con éxito',
      },
      studentsTable: {
        name: 'Nombre',
        email: 'Correo electrónico',
        username: 'Nombre de usuario',
        actions: 'Acciones',
        schoolIdentifier: 'Identificador escolar',
        password: 'Contraseña',
        recover: 'Recuperar',
        recoverPassword: 'Recuperar contraseña',
        recoverPasswordDescription: '¿Estás seguro de que deseas recuperar la contraseña de este estudiante? Esta acción no es reversible.',
        newPassword: 'Nueva contraseña',
        passwordRecovered: 'La contraseña se recuperó con éxito',
      },
      importStudents: {
        header: 'Importar estudiantes',
        description: 'Haz clic en el botón de abajo para descargar el archivo de ejemplo para importar estudiantes a la plataforma. Llena el archivo con tus propios estudiantes manteniendo el mismo formato de archivo (.csv).',
        classDescription: 'Haz clic en el botón de abajo para descargar el archivo de ejemplo para importar estudiantes a la clase. Llena el archivo con tus propios estudiantes manteniendo el mismo formato de archivo (.csv).',
        descriptionIAVE: 'Elige el archivo para cargar los estudiantes a continuación.',
        exampleDownload: 'Descargar archivo de ejemplo',
        importStudentsLabel: 'Archivo de estudiantes (.csv)',
        importStudentsPlaceholder: 'Haz clic aquí para elegir el archivo',
        successImport: 'Los estudiantes se importaron con éxito',
        fullName: 'Nombre',
        username: 'Nombre de usuario',
        email: 'Correo electrónico',
        password: 'Contraseña',
        schoolIdentifier: 'Identificador escolar',
        importSuccessDescription: 'Los estudiantes se importaron con éxito. Haz clic en el botón de abajo para descargar las credenciales',
        nonExistentClassesError: 'Hay grupos de examen en el archivo que no existen en la escuela',
        noValidStudentsError: 'No se encontraron estudiantes válidos en el archivo subido',
        successImportStudents: (number) => `${number} ${number === 1 ? 'estudiante fue añadido' : 'estudiantes fueron añadidos'} a la clase.`,
        successImportStudentsIAVE: (number) => `${number} ${number === 1 ? 'estudiante fue añadido' : 'estudiantes fueron añadidos'} al grupo de examen.`,
        alreadyInClass: (number) => `${number === 1 ? `${number} estudiante ya estaba` : `${number} ya estaban`} presentes en la clase.`,
        alreadyInClassIAVE: (number) => `${number === 1 ? `${number} estudiante ya estaba` : `${number} ya estaban`} presentes en el grupo de examen.`,
        notFoundStudents: (number) => `${number} ${number === 1 ? 'estudiante no fue añadido' : 'estudiantes no fueron añadidos'} a la clase porque contienen información incorrecta o no pertenecen a esta escuela. ${number === 1 ? 'Línea: ' : 'Líneas: '} `,
        notFoundStudentsIAVE: (number) => `${number} ${number === 1 ? 'estudiante no fue añadido' : 'estudiantes no fueron añadidos'} al grupo de examen porque contienen información incorrecta o no pertenecen a esta escuela. ${number === 1 ? 'Línea: ' : 'Líneas: '}`,
        totalStudents: (number) => `${number} ${number === 1 ? 'total de estudiante' : 'total de estudiantes'} en el archivo.`,
        duplicateError: {
          email: 'Algunos correos electrónicos están duplicados',
          username: 'Algunos nombres de usuario están duplicados',
        },
      },
      studentFilters: {
        email: 'Correo electrónico',
        username: 'Nombre de usuario',
        classes: 'Clases',
        groups: 'Grupos',
        fullName: 'Nombre completo',
      },
      deleteStudent: {
        title: 'Eliminar estudiante',
        titleMultiple: 'Eliminar estudiantes',
        deleteStudentDescription: '¿Estás seguro de que deseas eliminar a este estudiante? Esta acción no es reversible.',
        deleteStudentDescriptionMultiple: '¿Estás seguro de que deseas eliminar a estos estudiantes? Esta acción no es reversible.',
        deleteSuccess: 'El estudiante fue eliminado con éxito',
        deleteSuccessMultiple: 'Los estudiantes fueron eliminados con éxito',
      },
      removeStudent: {
        title: 'Quitar estudiante',
        titleMultiple: 'Quitar estudiantes',
        removeStudentDescription: '¿Estás seguro de que deseas quitar a este estudiante? Esta acción no es reversible.',
        removeStudentDescriptionMultiple: '¿Estás seguro de que deseas quitar a estos estudiantes? Esta acción no es reversible.',
        removeSuccess: 'El estudiante fue quitado con éxito',
        removeSuccessMultiple: 'Los estudiantes fueron quitados con éxito',
      },
      moveStudents: {
        title: 'Mover estudiantes',
        moveStudentsDescription: 'Selecciona la clase a la que deseas mover al(los) estudiante(s)',
        moveStudentsDescriptionIAVE: 'Selecciona el grupo de examen a donde deseas mover al(los) estudiante(s)',
        move: 'Mover',
        success: 'El estudiante fue movido con éxito',
        successMultiple: 'Los estudiantes fueron movidos con éxito',
        successMoveStudents: (number, _class) => `Se movieron con éxito ${number} ${number === 1 ? 'estudiante' : 'estudiantes'} a la clase ${_class}.`,
        successMoveStudentsIAVE: (number, _class) => `Se movieron con éxito ${number} ${number === 1 ? 'estudiante' : 'estudiantes'} al grupo de examen ${_class}.`,
        alreadyInClass: (number) => `${number === 1 ? `${number} estudiante ya estaba` : `${number} ya estaban`} presentes en la clase de destino: `,
        alreadyInClassIAVE: (number) => `${number === 1 ? `${number} estudiante ya estaba` : `${number} ya estaban`} presentes en el grupo de examen de destino: `,

      },
      studentActions: {
        closeAttempts: 'Cerrar exámenes',
        attemptsOpen: 'exámenes abiertos',
        closeAttemptsDescription: '¿Estás seguro de que deseas cerrar todos los exámenes en curso? Todos los estudiantes que estén respondiendo un examen serán obligados a salir. Esta acción no es reversible.',
        unblockUsers: 'Desbloquear usuarios',
        unblockUsersDescription: '¿Estás seguro de que deseas desbloquear a todos los usuarios actualmente bloqueados?',
        closeAttemptsSuccess: 'Los exámenes fueron cerrados con éxito',
      },
    },
    synchronization: {
      title: 'Sincronización',
      synchronizeExams: {
        header: 'Sincronizar exámenes',
        description: 'Cuando hagas clic en confirmar, los exámenes asociados con los estudiantes que ingresaste en la plataforma se descargarán y almacenarán en el servidor local para ser tomados en el horario programado. Si se realizan cambios en el examen, haz clic nuevamente para descargar los cambios realizados.',
        synchronizeSuccess: 'Los exámenes se sincronizaron con éxito.',
      },
      syncSummary: {
        localServer: 'Servidor local',
        centralServer: 'Servidor central',
        students: 'Estudiantes',
        exams: 'Exámenes',
        attempts: 'Intentos',
      },
      synchronizeAttempts: {
        header: 'Sincronizar intentos',
        description: 'Cuando hagas clic en confirmar, los intentos guardados en el servidor local se sincronizarán con el servidor central. Si se realizan cambios en algún intento, haz clic nuevamente para sincronizar los cambios realizados.',
        synchronizeSuccess: 'Los intentos se sincronizaron con éxito.',
      },
    },
    exams: {
      title: 'Exámenes',
      onGoingAttempts: 'Exámenes en curso',
      exportAttemptsNotAvailable: 'No hay intentos para exportar',
      examsTable: {
        examName: 'Nombre del examen',
        groupName: 'Nombre del grupo',
        totalStudents: 'Total de estudiantes',
        totalAttempts: 'Total de exámenes iniciados',
        totalGeneratedAttempts: 'Total de exámenes generados',
        finishedAttempts: 'Exámenes finalizados',
        generatingPDFs: 'Generando los PDFs',
        zippingPDFs: 'Comprimiendo los PDFs',
        generateSuccessDescription: '¡Los exámenes se exportaron con éxito a PDF! Haz clic en el botón de abajo para descargar.',
        averageTime: 'Tiempo promedio de finalización',
        noAttempts: 'Sin estudiantes',
        examInProgress: 'Examen en curso',
      },
    },
    teachers: {
      title: 'Profesores',
      teachersTable: {
        name: 'Nombre',
        username: 'Nombre de usuario',
        email: 'Correo electrónico',
        groups: 'Grupos',
        actions: 'Acciones',
      },
      addTeachers: {
        header: 'Añadir profesores',
        fullName: 'Nombre',
        username: 'Nombre de usuario',
        email: 'Correo electrónico',
        password: 'Contraseña',
        teachersAddedSuccess: 'Los profesores se añadieron con éxito',
        description: 'Haz clic en el botón de abajo para descargar el archivo de ejemplo. Llena el archivo con los nombres y correos electrónicos de los profesores. Inserta el archivo a continuación y haz clic en confirmar. Todos los profesores recibirán una invitación por correo electrónico para unirse a la institución.',
        descriptionIAVE: 'Haz clic en el botón de abajo para descargar el archivo de ejemplo. Llena el archivo con los nombres y nombres de usuario de los profesores. Inserta el archivo a continuación y haz clic en confirmar.',
        exampleDownload: 'Archivo de ejemplo',
        teachersFileLabel: 'Archivo de profesores',
        teachersFilePlaceholder: 'Haz clic aquí para añadir un nuevo archivo',
        addSuccessDescriptionIAVE: 'Todos los profesores se crearon con éxito. Haz clic en el botón de abajo para descargar las credenciales. Atención: Solo podrás descargar las credenciales una vez. Guarda este archivo en un lugar seguro.',
        addSuccessDescription: 'Los profesores se añadieron con éxito. Se envió un correo electrónico a todos los nuevos profesores con sus credenciales. En caso de que un profesor ya tuviera una cuenta, fue notificado de que fue añadido al espacio de la institución.',
        nameMissingError: 'Los nombres de los profesores no pueden estar vacíos.',
        emailMissingError: 'Los correos electrónicos de los profesores no pueden estar vacíos.',
        emailWrongFormatError: 'Algunos correos electrónicos no tienen el formato correcto.',
        emailDuplicateError: 'Algunos correos electrónicos están duplicados.',
        totalTeachersSurpassedError: (limit, extra) => `Has superado el límite de ${limit} ${limit === 1 ? 'profesor' : 'profesores'} permitido para tu institución en ${extra} ${extra === 1 ? 'profesor' : 'profesores'}. Elimina profesores de la escuela o del archivo para continuar.`,
        usernameMissingError: 'Los nombres de usuario de los profesores no pueden estar vacíos.',
        usernameDuplicateError: 'Algunos nombres de usuario están duplicados.',
      },
      removeTeacher: {
        removeTitle: 'Quitar profesor',
        removeTitleMultiple: 'Quitar profesores',
        removeTeacherDescription: '¿Estás seguro de que deseas quitar a este profesor del espacio de la institución? Perderá acceso a todo el contenido que creó en el espacio. Esta acción no es reversible.',
        removeTeacherDescriptionMultiple: '¿Estás seguro de que deseas quitar a estos profesores del espacio de la institución? Perderán acceso a todo el contenido que crearon en el espacio. Esta acción no es reversible.',
        removeSuccess: 'El profesor fue quitado con éxito del espacio de la institución',
        removeSuccessMultiple: 'Los profesores fueron quitados con éxito del espacio de la institución',
        deleteTitle: 'Eliminar profesor',
        deleteTitleMultiple: 'Eliminar profesores',
        deleteTeacherDescription: '¿Estás seguro de que deseas eliminar a este profesor? Esta acción no es reversible.',
        deleteTeacherDescriptionMultiple: '¿Estás seguro de que deseas eliminar a estos profesores? Esta acción no es reversible.',
        deleteSuccess: 'El profesor fue eliminado con éxito',
        deleteSuccessMultiple: 'Los profesores fueron eliminados con éxito',
      },
    },
    classes: {
      schools: {
        createClass: 'Crear clase',
        classNameLabel: 'Nombre de la clase',
        classNameEmpty: 'El nombre de la clase no puede estar vacío',
        deleteClass: 'Eliminar clase',
        deleteClasses: 'Eliminar clases',
        deleteClassDescription: '¿Estás seguro de que deseas eliminar esta clase? Esta acción no es reversible.',
        deleteClassesDescription: '¿Estás seguro de que deseas eliminar estas clases? Esta acción no es reversible.',
        successDeleteClass: 'La clase fue eliminada con éxito',
        successDeleteClasses: 'Las clases fueron eliminadas con éxito',
        settingsTitle: 'Configuraciones de la clase',
        errorNameRequired: 'Se requiere el nombre de la clase',
        successEditClass: 'La clase fue editada con éxito',
      },
      iave: {
        createClass: 'Crear grupo de examen',
        classNameLabel: 'Nombre del grupo',
        classNameEmpty: 'El nombre del grupo no puede estar vacío',
        deleteClass: 'Eliminar grupo de examen',
        deleteClasses: 'Eliminar grupos de examen',
        deleteClassDescription: '¿Estás seguro de que deseas eliminar este grupo de examen? Esta acción no es reversible.',
        deleteClassesDescription: '¿Estás seguro de que deseas eliminar estos grupos de examen? Esta acción no es reversible.',
        successDeleteClass: 'El grupo de examen fue eliminado con éxito',
        successDeleteClasses: 'Los grupos de examen fueron eliminados con éxito',
        settingsTitle: 'Configuraciones del grupo de examen',
        errorNameRequired: 'Se requiere el nombre del grupo de examen',
        successEditClass: 'El grupo de examen fue editado con éxito',
      },
    },
  },
  ptBR: {
    ok: 'Ok',
    add: 'Adicionar',
    create: 'Criar',
    publish: 'Publicar',
    published: 'Publicado',
    submit: 'Enviar',
    hide: 'Ocultar',
    preview: 'Pré-visualização',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Apagar',
    'delete': 'Excluir',
    remove: 'Remover',
    move: 'Mover',
    duplicate: 'Duplicar',
    discard: 'Descartar',
    clear: 'Limpar',
    saveChanges: 'Salvar mudanças',
    save: 'Salvar',
    copy: 'Copiar',
    oops: 'Algo deu errado. Por favor, tente novamente',
    waitSeconds: 'Este processo pode levar alguns segundos',
    waitMinutes: 'Este processo pode levar alguns minutos',
    waitTime: 'Este processo pode levar algum tempo',
    date: 'Data',
    previous: 'Anterior',
    next: 'Próximo',
    'export': 'Exportar',
    en: 'Inglês',
    pt: 'Português',
    esES: 'Espanhol',
    ptBR: 'Português (Brasil)',
    unauthorizedMessage: 'Parece que você ainda não está qualificado para estar nesta área... Talvez no próximo ano!',
    notFoundMessage: 'Oh não! Parece que você se perdeu no nosso site... Talvez não seja tão intuitivo.',
    errorMessage: 'Algo deu errado... Vamos trabalhar para resolver isso o mais rápido possível.',
    noPermissionSpace: 'Você não tem permissões para acessar este espaço.',
    home: 'Início',
    account: 'Conta',
    signout: 'Sair',
    finished: 'Finalizado',
    example: 'Exemplo',
    'private': 'Privado',
    confirm: 'Confirmar',
    total: 'Total',
    optional: 'opcional',
    workInProgress: 'Estamos trabalhando para melhorar este recurso',
    enable: 'Habilitar',
    disable: 'Desabilitar',
    noOptionsAvailable: 'Nenhuma opção disponível',
    close: 'Fechar',
    'continue': 'Continuar',
    'in': 'em',
    goBack: 'Voltar',
    name: 'Nome',
    email: 'E-mail',
    shortcuts: 'Atalhos',
    leave: 'Sair',
    send: 'Enviar',
    comingSoon: 'Em breve!',
    comment: 'Comentário',
    help: 'Ajuda',
    feedback: 'Feedback',
    copied: 'Copiado',
    noEmail: 'Sem e-mail',
    selectAll: 'Selecionar tudo',
    general: 'Geral',
    discardWarning: 'Se você continuar, todas as adições serão perdidas.',
    notFound: 'Não encontrado',
    copyOf: 'Cópia de',
    otherOptions: 'Outras opções',
    reschedule: 'Reagendar',
    results: 'Resultados',
    'new': 'Novo',
    filterBy: 'Filtrar por',
    copies: 'Cópias',
    orderBy: 'Ordenar por',
    oldest: 'Mais antigo',
    newest: 'Mais recente',
    relevancy: 'Relevância',
    showMore: 'Mostrar mais',
    showLess: 'Mostrar menos',
    settings: 'Configurações',
    scrollToSides: 'Rolar para os lados',
    download: 'Baixar',
    details: 'Detalhes',
    options: 'Opções',
    fileTooLarge: 'O arquivo não pode ser maior que 10Mb',
    drag: 'Arrastar',
    dropHere: 'Solte aqui',
    changeLang: 'Mudar idioma',
    logout: 'Sair',
    video: 'Vídeo',
    audio: 'Áudio',
    tutorial: 'Tutoriais',
    continueWith: 'Continuar com',
    or: 'Ou',
    notYou: 'Não é sua conta?',
    guest: 'Convidado',
    select: 'Selecionar',
    internetWentWrong: 'Algo deu errado. Por favor, verifique sua conexão com a internet e tente novamente',
    deleteSelected: 'Excluir selecionados',
    removeselected: 'Remover selecionados',
    credentials: 'Credenciais',
    removeSelected: 'Remover selecionados',
    moveSelected: 'Mover selecionados',

    errors: 'Erros',
    time: {
      day: 'dia',
      days: 'dias',
      hour: 'hora',
      hours: 'horas',
      minute: 'minuto',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
    },
    appKeywords: {
      classes: 'Aulas',
      tests: 'Testes',
      test: 'Teste',
      threads: 'Tópicos',
      admin: 'Administrador',
      lesson: 'Lição',
      subject: 'Disciplina',
      'class': 'Turma',
      exercises: 'Exercícios',
      exercise: 'Exercício',
      correction: 'Correção',
      answer: 'Resposta',
      grade: 'Nota',
      group: 'Grupo',
      groups: 'Grupos',
      personalGroup: 'Meu conteúdo',
      allGroups: 'Todos os grupos',
      personalSpace: 'Espaço pessoal',
      student: 'Estudante',
      students: 'Estudantes',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (teste gratuito)',
      premiumInfo: 'Período experimental de duração indefinida. No momento, você pode usar a plataforma e todas as suas funcionalidades gratuitamente. Mais tarde, introduziremos diferentes planos pagos para professores, mas sempre haverá um plano gratuito, embora com algumas limitações em termos de funcionalidades.',
      rubrics: 'Rubricas',
      rubric: 'Rubrica',
      criterion: 'Critério',
      criteria: 'Critérios',
      classic: 'Clássico',
      noClassification: 'Sem classificação',
      worksheet: 'Folha de trabalho',
      assessment: 'Avaliação',
      assessments: 'Avaliações',
      teachers: 'professores',
      examGroups: 'Grupos de exame',
      examGroupsMin: 'Grupos de exame',
      email: 'E-mail',
      username: 'Nome de usuário',
      name: 'Nome',
    },
    events: {
      title: 'Eventos',
      eventType: 'Tipo',
      eventTypes: 'Tipos',
      user: 'Usuário',
      category: 'Categoria',
      message: 'Mensagem',
      date: 'Data',
      details: 'Detalhes',
      noUser: 'Sem usuário',
      eventId: 'ID do evento',
      service: 'Serviço',
      url: 'URL',
      query: 'Parâmetros da consulta',
      body: 'Corpo da requisição',
      stack: 'Pilha de erros',
      emailOrUsername: 'Nome de usuário ou e-mail',
      types: {
        info: 'Informação',
        error: 'Erro',
      },
    },
    students: {
      title: 'Estudantes',
      addStudents: {
        header: 'Adicionar estudantes',
        studentFilter: 'Estudantes',
        uploadFile: 'Carregar arquivo',
        selectInput: 'Buscar',
        exampleFile: 'Arquivo de exemplo',
        hintTargets: 'Insira o e-mail ou nome de usuário do estudante que deseja adicionar à turma',
        success: 'Os estudantes foram adicionados com sucesso',
      },
      studentsTable: {
        name: 'Nome',
        email: 'E-mail',
        username: 'Nome de usuário',
        actions: 'Ações',
        schoolIdentifier: 'Identificador escolar',
        password: 'Senha',
        recover: 'Recuperar',
        recoverPassword: 'Recuperar senha',
        recoverPasswordDescription: 'Você tem certeza de que deseja recuperar a senha deste estudante? Esta ação não é reversível.',
        newPassword: 'Nova senha',
        passwordRecovered: 'A senha foi recuperada com sucesso',
      },
      importStudents: {
        header: 'Importar estudantes',
        description: 'Clique no botão abaixo para baixar o arquivo de exemplo para importar estudantes para a plataforma. Preencha o arquivo com seus próprios estudantes mantendo o mesmo formato de arquivo (.csv).',
        classDescription: 'Clique no botão abaixo para baixar o arquivo de exemplo para importar estudantes para a turma. Preencha o arquivo com seus próprios estudantes mantendo o mesmo formato de arquivo (.csv).',
        descriptionIAVE: 'Escolha o arquivo para carregar os estudantes abaixo.',
        exampleDownload: 'Baixar arquivo de exemplo',
        importStudentsLabel: 'Arquivo de estudantes (.csv)',
        importStudentsPlaceholder: 'Clique aqui para escolher o arquivo',
        successImport: 'Os estudantes foram importados com sucesso',
        fullName: 'Nome completo',
        username: 'Nome de usuário',
        email: 'E-mail',
        password: 'Senha',
        schoolIdentifier: 'Identificador escolar',
        importSuccessDescription: 'Os estudantes foram importados com sucesso. Clique no botão abaixo para baixar as credenciais',
        nonExistentClassesError: 'Existem grupos de exame no arquivo que não existem na escola',
        noValidStudentsError: 'Nenhum estudante válido foi encontrado no arquivo enviado',
        successImportStudents: (number) => `${number} ${number === 1 ? 'estudante foi adicionado' : 'estudantes foram adicionados'} à turma.`,
        successImportStudentsIAVE: (number) => `${number} ${number === 1 ? 'estudante foi adicionado' : 'estudantes foram adicionados'} ao grupo de exame.`,
        alreadyInClass: (number) => `${number === 1 ? `${number} estudante já estava` : `${number} já estavam`} presente na turma.`,
        alreadyInClassIAVE: (number) => `${number === 1 ? `${number} estudante já estava` : `${number} já estavam`} presente no grupo de exame.`,
        notFoundStudents: (number) => `${number} ${number === 1 ? 'estudante não foi adicionado' : 'estudantes não foram adicionados'} à turma porque contêm informações incorretas ou não pertencem a esta escola. ${number === 1 ? 'Linha: ' : 'Linhas: '} `,
        notFoundStudentsIAVE: (number) => `${number} ${number === 1 ? 'estudante não foi adicionado' : 'estudantes não foram adicionados'} ao grupo de exame porque contêm informações incorretas ou não pertencem a esta escola. ${number === 1 ? 'Linha: ' : 'Linhas: '}`,
        totalStudents: (number) => `${number} ${number === 1 ? 'total de estudante' : 'total de estudantes'} no arquivo.`,
        duplicateError: {
          email: 'Alguns e-mails estão duplicados',
          username: 'Alguns nomes de usuário estão duplicados',
        },
      },
      studentFilters: {
        email: 'E-mail',
        username: 'Nome de usuário',
        classes: 'Aulas',
        groups: 'Grupos',
        fullName: 'Nome completo',
      },
      deleteStudent: {
        title: 'Excluir estudante',
        titleMultiple: 'Excluir estudantes',
        deleteStudentDescription: 'Você tem certeza de que deseja excluir este estudante? Esta ação não é reversível.',
        deleteStudentDescriptionMultiple: 'Você tem certeza de que deseja excluir esses estudantes? Esta ação não é reversível.',
        deleteSuccess: 'O estudante foi excluído com sucesso',
        deleteSuccessMultiple: 'Os estudantes foram excluídos com sucesso',
      },
      removeStudent: {
        title: 'Remover estudante',
        titleMultiple: 'Remover estudantes',
        removeStudentDescription: 'Você tem certeza de que deseja remover este estudante? Esta ação não é reversível.',
        removeStudentDescriptionMultiple: 'Você tem certeza de que deseja remover esses estudantes? Esta ação não é reversível.',
        removeSuccess: 'O estudante foi removido com sucesso',
        removeSuccessMultiple: 'Os estudantes foram removidos com sucesso',
      },
      moveStudents: {
        title: 'Mover estudantes',
        moveStudentsDescription: 'Selecione a turma para onde deseja mover o(s) estudante(s)',
        moveStudentsDescriptionIAVE: 'Selecione o grupo de exame para onde deseja mover o(s) estudante(s)',
        move: 'Mover',
        success: 'O estudante foi movido com sucesso',
        successMultiple: 'Os estudantes foram movidos com sucesso',
        successMoveStudents: (number, _class) => `Movido com sucesso ${number} ${number === 1 ? 'estudante' : 'estudantes'} para a turma ${_class}.`,
        successMoveStudentsIAVE: (number, _class) => `Movido com sucesso ${number} ${number === 1 ? 'estudante' : 'estudantes'} para o grupo de exame ${_class}.`,
        alreadyInClass: (number) => `${number === 1 ? `${number} estudante já estava` : `${number} já estavam`} presente na turma de destino: `,
        alreadyInClassIAVE: (number) => `${number === 1 ? `${number} estudante já estava` : `${number} já estavam`} presente no grupo de exame de destino: `,

      },
      studentActions: {
        closeAttempts: 'Fechar exames',
        attemptsOpen: 'exames abertos',
        closeAttemptsDescription: 'Você tem certeza de que deseja fechar todos os exames em andamento? Todos os estudantes que estiverem respondendo a um exame serão obrigados a sair. Esta ação não é reversível.',
        unblockUsers: 'Desbloquear usuários',
        unblockUsersDescription: 'Você tem certeza de que deseja desbloquear todos os usuários atualmente bloqueados?',
        closeAttemptsSuccess: 'Os exames foram fechados com sucesso',
      },
    },
    synchronization: {
      title: 'Sincronização',
      synchronizeExams: {
        header: 'Sincronizar exames',
        description: 'Ao clicar em confirmar, os exames associados aos estudantes que você inseriu na plataforma serão baixados e armazenados no servidor local para serem feitos no horário programado. Se forem feitas alterações no exame, clique novamente para baixar as alterações feitas.',
        synchronizeSuccess: 'Os exames foram sincronizados com sucesso.',
      },
      syncSummary: {
        localServer: 'Servidor local',
        centralServer: 'Servidor central',
        students: 'Estudantes',
        exams: 'Exames',
        attempts: 'Tentativas',
      },
      synchronizeAttempts: {
        header: 'Sincronizar tentativas',
        description: 'Ao clicar em confirmar, as tentativas salvas no servidor local serão sincronizadas com o servidor central. Se forem feitas alterações em qualquer tentativa, clique novamente para sincronizar as alterações feitas.',
        synchronizeSuccess: 'As tentativas foram sincronizadas com sucesso.',
      },
    },
    exams: {
      title: 'Exames',
      onGoingAttempts: 'Exames em andamento',
      exportAttemptsNotAvailable: 'Não há tentativas para exportar',
      examsTable: {
        examName: 'Nome do exame',
        groupName: 'Nome do grupo',
        totalStudents: 'Total de estudantes',
        totalAttempts: 'Total de exames iniciados',
        totalGeneratedAttempts: 'Total de exames gerados',
        finishedAttempts: 'Exames finalizados',
        generatingPDFs: 'Gerando os PDFs',
        zippingPDFs: 'Compactando os PDFs',
        generateSuccessDescription: 'Os exames foram exportados para PDF com sucesso! Clique no botão abaixo para baixar.',
        averageTime: 'Tempo médio de conclusão',
        noAttempts: 'Sem estudantes',
        examInProgress: 'Exame em andamento',
      },
    },
    teachers: {
      title: 'Professores',
      teachersTable: {
        name: 'Nome',
        username: 'Nome de usuário',
        email: 'E-mail',
        groups: 'Grupos',
        actions: 'Ações',
      },
      addTeachers: {
        header: 'Adicionar professores',
        fullName: 'Nome',
        username: 'Nome de usuário',
        email: 'E-mail',
        password: 'Senha',
        teachersAddedSuccess: 'Os professores foram adicionados com sucesso',
        description: 'Clique no botão abaixo para baixar o arquivo de exemplo. Preencha o arquivo com os nomes e e-mails dos professores. Insira o arquivo abaixo e clique em confirmar. Todos os professores receberão um e-mail com convite para ingressar na instituição.',
        descriptionIAVE: 'Clique no botão abaixo para baixar o arquivo de exemplo. Preencha o arquivo com os nomes e nomes de usuário dos professores. Insira o arquivo abaixo e clique em confirmar.',
        exampleDownload: 'Arquivo de exemplo',
        teachersFileLabel: 'Arquivo de professores',
        teachersFilePlaceholder: 'Clique aqui para adicionar um novo arquivo',
        addSuccessDescriptionIAVE: 'Todos os professores foram criados com sucesso. Clique no botão abaixo para baixar as credenciais. Atenção: Você só poderá baixar as credenciais uma vez. Salve este arquivo em um local seguro.',
        addSuccessDescription: 'Os professores foram adicionados com sucesso. Um e-mail foi enviado para todos os novos professores com suas credenciais. Caso um professor já tivesse uma conta, ele foi notificado de que foi adicionado ao espaço da instituição.',
        nameMissingError: 'Os nomes dos professores não podem estar vazios.',
        emailMissingError: 'Os e-mails dos professores não podem estar vazios.',
        emailWrongFormatError: 'Alguns e-mails não estão no formato correto.',
        emailDuplicateError: 'Alguns e-mails estão duplicados.',
        totalTeachersSurpassedError: (limit, extra) => `Você ultrapassou o limite de ${limit} ${limit === 1 ? 'professor' : 'professores'} permitido para sua instituição em ${extra} ${extra === 1 ? 'professor' : 'professores'}. Remova professores da escola ou do arquivo para continuar.`,
        usernameMissingError: 'Os nomes de usuário dos professores não podem estar vazios.',
        usernameDuplicateError: 'Alguns nomes de usuário estão duplicados.',
      },
      removeTeacher: {
        removeTitle: 'Remover professor',
        removeTitleMultiple: 'Remover professores',
        removeTeacherDescription: 'Você tem certeza de que deseja remover este professor do espaço da instituição? Ele perderá acesso a todo o conteúdo que criou no espaço. Esta ação não é reversível.',
        removeTeacherDescriptionMultiple: 'Você tem certeza de que deseja remover esses professores do espaço da instituição? Eles perderão acesso a todo o conteúdo que criaram no espaço. Esta ação não é reversível.',
        removeSuccess: 'O professor foi removido com sucesso do espaço da instituição',
        removeSuccessMultiple: 'Os professores foram removidos com sucesso do espaço da instituição',
        deleteTitle: 'Excluir professor',
        deleteTitleMultiple: 'Excluir professores',
        deleteTeacherDescription: 'Você tem certeza de que deseja excluir este professor? Esta ação não é reversível.',
        deleteTeacherDescriptionMultiple: 'Você tem certeza de que deseja excluir esses professores? Esta ação não é reversível.',
        deleteSuccess: 'O professor foi excluído com sucesso',
        deleteSuccessMultiple: 'Os professores foram excluídos com sucesso',
      },
    },
    classes: {
      schools: {
        createClass: 'Criar turma',
        classNameLabel: 'Nome da turma',
        classNameEmpty: 'O nome da turma não pode estar vazio',
        deleteClass: 'Excluir turma',
        deleteClasses: 'Excluir turmas',
        deleteClassDescription: 'Você tem certeza de que deseja excluir esta turma? Esta ação não é reversível.',
        deleteClassesDescription: 'Você tem certeza de que deseja excluir essas turmas? Esta ação não é reversível.',
        successDeleteClass: 'A turma foi excluída com sucesso',
        successDeleteClasses: 'As turmas foram excluídas com sucesso',
        settingsTitle: 'Configurações da turma',
        errorNameRequired: 'O nome da turma é obrigatório',
        successEditClass: 'A turma foi editada com sucesso',
      },
      iave: {
        createClass: 'Criar grupo de exame',
        classNameLabel: 'Nome do grupo',
        classNameEmpty: 'O nome do grupo não pode estar vazio',
        deleteClass: 'Excluir grupo de exame',
        deleteClasses: 'Excluir grupos de exame',
        deleteClassDescription: 'Você tem certeza de que deseja excluir este grupo de exame? Esta ação não é reversível.',
        deleteClassesDescription: 'Você tem certeza de que deseja excluir esses grupos de exame? Esta ação não é reversível.',
        successDeleteClass: 'O grupo de exame foi excluído com sucesso',
        successDeleteClasses: 'Os grupos de exame foram excluídos com sucesso',
        settingsTitle: 'Configurações do grupo de exame',
        errorNameRequired: 'O nome do grupo de exame é obrigatório',
        successEditClass: 'O grupo de exame foi editado com sucesso',
      },
    },
  },
});

export default lang;
