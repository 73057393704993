import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Loading from 'components/common/Loading';
import ExamsActions from 'components/exams/ExamsActions';
import ExamsTable from 'components/exams/ExamsTable';

const ExamsList = ({ loaded }) => {
  const [getSchoolPublicationsRequest, loading, abortRequest] = useApi(api.getSchoolPublications);
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();

  const [exams, setExams] = useState([]);
  const [ongoingAttemptsCount, setOngoingAttemptsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(1);

  const fetchExams = useCallback((page) => {
    if (!schoolId) {
      return;
    }

    const request = {
      params: {
        schoolId: schoolId,
      },
      query: {
        page: page,
      },
    };

    getSchoolPublicationsRequest(request, ({ data }) => {
      if (data.status === 0) {
        page !== 1
          ? setExams(exams => [...exams, ...data.publications])
          : setExams(data.publications);
        setOngoingAttemptsCount(data.ongoingAttemptsCount);
        setPage(page => page + 1);
        setHasMore(!data.isLastPage);
        loaded();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        loaded();
      }
    });
  }, [loaded, getSchoolPublicationsRequest, toast, schoolId]);

  useEffect(() => {
    fetchExams(1);

    return () => {
      abortRequest();
    };
  }, [fetchExams, abortRequest]);

  return (
    <div>
      <ExamsActions ongoingAttemptsCount={ongoingAttemptsCount} />
      <InfiniteScroll
        dataLength={exams.length}
        next={() => fetchExams(page)}
        hasMore={hasMore}
        loader={<Loading active={loading} />}
      >
        <ExamsTable
          exams={exams}
        />
      </InfiniteScroll>
    </div>
  );
};

ExamsList.propTypes = {
  loaded: PropTypes.func,
};

export default ExamsList;
