import React from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import lang from 'lang';

import ExamRow from '../ExamRow';

import useStyles from './styles';

const ExamsTable = ({ exams }) => {
  const classes = useStyles();

  return (
    <div>
      <Table className={classes.studentsTable}>
        <TableRow header>
          <TableCell>
            {lang.exams.examsTable.examName}
          </TableCell>
          <TableCell>
            {lang.exams.examsTable.groupName}
          </TableCell>
          <TableCell>
            {lang.exams.examsTable.totalStudents}
          </TableCell>
          <TableCell>
            {lang.exams.examsTable.totalGeneratedAttempts}
          </TableCell>
          <TableCell>
            {lang.exams.examsTable.totalAttempts}
          </TableCell>
          <TableCell>
            {lang.exams.examsTable.finishedAttempts}
          </TableCell>
          <TableCell>
            {lang.exams.examsTable.averageTime}
          </TableCell>
        </TableRow>
        {exams.map(exam => (
          <ExamRow
            key={exam.id}
            exam={exam}
          />
        ))}
      </Table>
    </div>
  );
};

ExamsTable.propTypes = {
  exams: PropTypes.array,
};

export default ExamsTable;
