import { APP_LOADED, PAGE_LOADING, PAGE_LOADED, PAGE_ERROR, TOGGLE_DARK, SHOW_LAYOUT, SET_AUTHENTICATED, SET_CLOCK } from 'actions/pageActions';

const initialState = {
  appLoading: true,
  pageLoading: true,
  error: null,
  toastVisible: false,
  toastMsg: '',
  isDark: false,
  clockOffset: null,
  layout: false,
  authenticated: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOADED:
      return {
        ...state,
        appLoading: false,
      };
    case PAGE_LOADING:
      return {
        ...state,
        pageLoading: true,
      };
    case PAGE_LOADED:
      return {
        ...state,
        pageLoading: false,
      };
    case PAGE_ERROR:
      return {
        ...state,
        error: action.code,
      };
    case TOGGLE_DARK:
      return {
        ...state,
        isDark: !state.isDark,
      };
    case SHOW_LAYOUT:
      return {
        ...state,
        layout: action.layout,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
      };
    case SET_CLOCK:
      return {
        ...state,
        clockOffset: action.clockOffset,
      };
    default:
      return state;
  }
};

export default reducer;
