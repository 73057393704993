import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';

/**
 * This plugins allows custom formatting like "DD-MM-YYYY"
 */
dayjs.extend(customParseFormat);
dayjs.extend(duration);

/**
 * Human friendly formats shown to the user in the UI
 */
export const HUMAN_DATE_FORMAT_DD_MM = 'DD-MM-YYYY';
export const HUMAN_DATE_FORMAT_MM_DD = 'MM-DD-YYYY';
export const HUMAN_DATE_TIME_FORMAT_DD_MM = `${HUMAN_DATE_FORMAT_DD_MM} HH:mm`;
export const HUMAN_DATE_TIME_FORMAT_MM_DD = `${HUMAN_DATE_FORMAT_MM_DD} HH:mm`;
export const HUMAN_DATE_VERBOSE_FORMAT_D_MMM = 'D MMM YYYY';
export const HUMAN_DATE_VERBOSE_FORMAT_MMM_D = 'MMM D YYYY';
export const HUMAN_DATE_TIME_VERBOSE_FORMAT_D_MMM = `${HUMAN_DATE_VERBOSE_FORMAT_D_MMM} HH:mm`;
export const HUMAN_DATE_TIME_VERBOSE_FORMAT_MMM_D = `${HUMAN_DATE_VERBOSE_FORMAT_MMM_D} HH:mm`;

/**
 * Data formats to be stored and manipulated
 */
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const SIMPLE_TIME_FORMAT = 'HH:mm';

const SUPPORTED_LOCALES = ['pt', 'en', 'esES', 'ptBR'];

const DATE_TIME_FORMATS = [DATE_FORMAT, DATE_TIME_FORMAT];

const DATE_TIME_FORMAT_MAP = {
  datetime: DATE_TIME_FORMAT,
  date: DATE_FORMAT,
};

const HUMAN_DATE_TIME_FORMAT_MAP = {
  datetime: {
    pt: HUMAN_DATE_TIME_FORMAT_DD_MM,
    en: HUMAN_DATE_TIME_FORMAT_MM_DD,
    esES: HUMAN_DATE_TIME_FORMAT_DD_MM,
    ptBR: HUMAN_DATE_TIME_FORMAT_DD_MM,
  },
  date: {
    pt: HUMAN_DATE_FORMAT_DD_MM,
    en: HUMAN_DATE_FORMAT_MM_DD,
    esES: HUMAN_DATE_FORMAT_DD_MM,
    ptBR: HUMAN_DATE_FORMAT_DD_MM,
  },
};

const HUMAN_DATE_TIME_FORMAT_VERBOSE_MAP = {
  datetime: {
    pt: HUMAN_DATE_TIME_VERBOSE_FORMAT_D_MMM,
    en: HUMAN_DATE_TIME_VERBOSE_FORMAT_MMM_D,
    esES: HUMAN_DATE_TIME_VERBOSE_FORMAT_D_MMM,
    ptBR: HUMAN_DATE_TIME_VERBOSE_FORMAT_D_MMM,
  },
  date: {
    pt: HUMAN_DATE_VERBOSE_FORMAT_D_MMM,
    en: HUMAN_DATE_VERBOSE_FORMAT_MMM_D,
    esES: HUMAN_DATE_VERBOSE_FORMAT_D_MMM,
    ptBR: HUMAN_DATE_VERBOSE_FORMAT_D_MMM,
  },
};

const DEFAULT_DATE_TIME_TYPE = 'datetime';
const DEFAULT_LOCALE = 'pt';

const isValidDateTimeType = (type) => Object.keys(DATE_TIME_FORMAT_MAP).includes(type);

const isValidLocate = (locale) => SUPPORTED_LOCALES.includes(locale);

/**
 *
 * @param {string} value
 * @returns {dayjs.Dayjs | null}
 */
export const getDayJSValue = (value) => value ? dayjs(value, DATE_TIME_FORMATS) : null;

/**
 *
 * @param {keyof DATE_TIME_FORMAT_MAP} type
 * @param {'pt' | 'en'} locale
 * @returns {string}
 */
export const getHumanDatetimeFormat = (type = DEFAULT_DATE_TIME_TYPE, locale = DEFAULT_LOCALE) => {
  if (!isValidDateTimeType(type)) {
    throw Error(`${type} is not a valid type`);
  }

  if (!isValidLocate(locale)) {
    throw Error(`${locale} is not a valid locale`);
  }

  return HUMAN_DATE_TIME_FORMAT_MAP[type][locale];
};

/**
 *
 * @param {keyof DATE_TIME_FORMAT_MAP} type
 * @returns {string}
 */
export const getNowDatetimeString = (type = DEFAULT_DATE_TIME_TYPE) => {
  if (!isValidDateTimeType(type)) {
    throw Error(`${type} is not a valid type`);
  }

  return dayjs().format(DATE_TIME_FORMAT_MAP[type]);
};

/**
 * @param {number} value
 * @param {'pt' | 'en'} locale
 * @param {boolean} verbose
 * @returns {string}
 */
export const getEpochDatetimeString = (value, locale = DEFAULT_LOCALE, verbose = false) => {
  if (!isValidLocate(locale)) {
    throw Error(`${locale} is not a valid locale`);
  }

  const format = verbose
    ? HUMAN_DATE_TIME_FORMAT_VERBOSE_MAP.datetime[locale]
    : HUMAN_DATE_TIME_FORMAT_MAP.datetime[locale];

  return dayjs(value).format(format);
};

/**
 * @param {string} value
 * @returns {number | null}
 */
export const getEpochFromString = (value) => getDayJSValue(value)?.valueOf() ?? null;

/**
 *
 * @param {number} value
 * @param {'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds'} dimension
 * @param {string} format
 * @returns
 */
export const getDuration = (value, dimension, format) =>
  dayjs.duration(value, dimension).format(format);

/**
 *
 * @param {number} offset
 * @returns {number}
 */
export const localToServerMillis = (offset) => Date.now() - offset;

/**
 *
 * @param {number} server
 * @param {number} offset
 * @returns {number}
 */
export const serverToLocalMillis = (server, offset) => server + offset;

/**
 *
 * @param {number} seconds
 * @returns {number}
 */
export const secondsToMillis = (seconds) => seconds * 60 * 1000;

/**
 *
 * @param {number} millis
 * @returns {number}
 */
export const millisToSeconds = (millis) => Math.round(millis / 1000 / 60);
