import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  eventTypeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  eventTypeIcon: {
    color: theme.infoColor,
    '&.error': {
      color: theme.errorColor,
    },
  },
}));
