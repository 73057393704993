import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.fullPage': {
      height: '100vh',
    },
  },
  loadingSpinner: {
    padding: '20px 0',
    width: '30px',
    height: '30px',
    '&.fullPage': {
      color: theme.accentColor,
      padding: '0',
      width: '50px',
      height: '50px',
    },
  },
}));
