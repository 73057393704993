import React, { useCallback, useMemo } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';
import { useClassContext } from 'pages/Class/context';

const RemoveStudentsModal = ({ open, close, studentIds, getClass }) => {
  const [removeClassStudentsRequest, loading] = useApi(api.removeClassStudents);
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();
  const { classId } = useParams();
  const { fetchStudents } = useClassContext();

  const multipleStudents = useMemo(() => {
    return studentIds?.length > 1;
  }, [studentIds]);

  const deleteStudents = useCallback(() => {
    const request = {
      params: {
        schoolId,
        classId,
      },
      data: {
        studentIds,
      },
    };

    removeClassStudentsRequest(request, ({ data }) => {
      if (data.status === 0) {
        toast.success(multipleStudents ? lang.students.removeStudent.removeSuccessMultiple : lang.students.removeStudent.removeSuccess);
        fetchStudents(1);
        getClass();
        close();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [schoolId, classId, studentIds, removeClassStudentsRequest, toast, multipleStudents, fetchStudents, getClass, close]);

  const actions = [
    {
      name: lang.cancel,
      onClick: close,
      color: 'black',
    },
    {
      name: lang.remove,
      onClick: deleteStudents,
      color: 'red',
      loading: loading,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={multipleStudents ? lang.students.removeStudent.titleMultiple : lang.students.removeStudent.title}
      actions={actions}
      center
      transition
      small
    >
      {multipleStudents ? lang.students.removeStudent.removeStudentDescriptionMultiple : lang.students.removeStudent.removeStudentDescription}
    </Modal>
  );
};

RemoveStudentsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  studentIds: PropTypes.array,
  getClass: PropTypes.func,
};

export default RemoveStudentsModal;
