import { useState } from 'react';

const useInput = (initialValue, initialErrors) => {
  const [value, setValue] = useState(initialValue);
  const [errors, setErrors] = useState(initialErrors || []);

  return [value, setValue, errors, setErrors];
};

export default useInput;
