import React, { useCallback, useEffect, useState } from 'react';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Render, useToast } from '@intuitivo-pt/outline-ui';

import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';
import routes from 'routes';

import PageHeader from 'components/common/layout/PageHeader';
import Loading from 'components/common/Loading';
import SyncAttemptsModal from 'components/synchronization/SyncAttemptsModal';
import SyncExamsModal from 'components/synchronization/SyncExamsModal';
import SyncSummaryTable from 'components/synchronization/SyncSummaryTable';

const Synchronization = () => {
  const { loaded } = usePageLogic(lang.synchronization.title, true);
  const [getSyncSummaryRequest, syncLoading] = useApi(api.getSyncSummary);
  const [getLocalSyncSummaryRequest, localSyncLoading] = useApi(api.getLocalSyncSummary);
  const toast = useToast();

  const [importExamModal, setImportExamModal] = useState(false);
  const [syncAttemptsModal, setSyncAttemptsModal] = useState(false);
  const [remoteSyncData, setRemoteSyncData] = useState({});
  const [localSyncData, setLocalSyncData] = useState({});

  const getSyncSummary = useCallback(() => {
    getSyncSummaryRequest({}, ({ data }) => {
      if (data.status === 0) {
        setRemoteSyncData(data.syncData);
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [getSyncSummaryRequest, toast]);

  const getLocalSyncSummary = useCallback(() => {
    getLocalSyncSummaryRequest({}, ({ data }) => {
      if (data.status === 0) {
        setLocalSyncData(data.syncData);
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [getLocalSyncSummaryRequest, toast]);

  const fetchSummary = useCallback(() => {
    getSyncSummary();
    getLocalSyncSummary();
  }, [getSyncSummary, getLocalSyncSummary]);

  useEffect(() => {
    loaded();
    fetchSummary();
  }, [loaded, fetchSummary]);

  const path = [
    {
      type: 'link',
      text: lang.synchronization.title,
      to: routes.synchronization.ref(),
    },
  ];

  const actions = [
    {
      icon: faDownload,
      label: lang.synchronization.synchronizeExams.header,
      onClick: () => setImportExamModal(true),
    },
    {
      icon: faUpload,
      label: lang.synchronization.synchronizeAttempts.header,
      onClick: () => setSyncAttemptsModal(true),
    },
  ];

  return (
    <div>
      <PageHeader
        path={path}
        actions={actions}
      />
      <SyncExamsModal
        open={importExamModal}
        close={() => setImportExamModal(false)}
        fetchSummary={fetchSummary}
      />
      <SyncAttemptsModal
        open={syncAttemptsModal}
        close={() => setSyncAttemptsModal(false)}
        fetchSummary={fetchSummary}
      />
      <Loading active={syncLoading || localSyncLoading} />
      <Render when={!syncLoading && !localSyncLoading}>
        <SyncSummaryTable
          remoteSyncData={remoteSyncData}
          localSyncData={localSyncData}
        />
      </Render>
    </div>
  );
};

export default Synchronization;
