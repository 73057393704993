import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  card: {
    backgroundColor: theme.cardBackground3,
    border: `1px solid ${theme.cardBorder3}`,
    borderRadius: '18px',
    boxShadow: '0 4px 4px #00000040',
    overflow: 'hidden',
    '& > .otl-cardContentContainer': {
      padding: '0',
      '& > .otl-cardHeader': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0px',
        backgroundColor: theme.cardHeaderBackground3,
        padding: '10px 20px',
        fontSize: '16px',
        fontWeight: '600',
      },
    },
  },
}));
