import React, { useState, useCallback } from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import lang from 'lang';
import routes from 'routes';

import ClassSettingsModal from '../ClassSettingsModal';
import DeleteClassesModal from '../DeleteClassesModal';
import AddStudentsModal from 'components/classes/AddStudentsModal';
import Card from 'components/common/Card';
import Input from 'components/common/Input';

import useStyles from './styles';

const ADD_STUDENTS = 'ADD_STUDENTS';
const DELETE = 'DELETE';
const EDIT = 'EDIT';

const ClassCard = ({ _class, isSelected, selectClass, setSelected, reload }) => {
  const classes = useStyles();
  const history = useHistory();

  const [addStudentsModal, setAddStudentsModal] = useState(false);
  const [deleteClassesModal, setDeleteClassesModal] = useState(false);
  const [classSettingsModal, setClassSettingsModal] = useState(false);

  const onItemClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    if (key === ADD_STUDENTS) {
      setAddStudentsModal(true);
    }
    if (key === DELETE) {
      setDeleteClassesModal(true);
    }
    if (key === EDIT) {
      setClassSettingsModal(true);
    }
  };

  const actions = [
    {
      key: ADD_STUDENTS,
      label: lang.students.addStudents.header,
    },
    {
      key: DELETE,
      label: lang.classes.schools.deleteClass,
    },
    {
      key: EDIT,
      label: lang.classes.schools.settingsTitle,
    },
  ];

  const selectClassById = useCallback((event) => {
    event.stopPropagation();
    selectClass();
  }, [selectClass]);

  return (
    <>
      <AddStudentsModal
        open={addStudentsModal}
        close={() => setAddStudentsModal(false)}
        classId={_class.id}
        refresh={reload}
      />
      <DeleteClassesModal
        open={deleteClassesModal}
        close={() => setDeleteClassesModal(false)}
        classIds={[_class.id]}
        setSelected={setSelected}
        onSubmit={reload}
      />
      <ClassSettingsModal
        open={classSettingsModal}
        close={() => setClassSettingsModal(false)}
        currentClass={_class}
        reload={reload}
      />
      <Card
        className={classes.classCard}
        onClick={() => history.push(routes.class.ref(_class.id))}
      >
        <Dropdown
          menu={{ items: actions, onClick: onItemClick }}
          trigger="click"
        >
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className={cx(classes.classActionsIcon, classes.settings)}
            onClick={(e) => e.stopPropagation()}
          />
        </Dropdown>
        <div
          className={cx(classes.classActionsIcon, classes.selector)}
          onClick={selectClassById}
        >
          <Input
            type="checkbox"
            value={isSelected}
            className={classes.selectorInput}
          />
        </div>
        <div
          className={classes.className}
          title={_class.name}
        >
          {_class.name}
        </div>
        <div className={classes.studentCount}>
          {_class.studentCount}
          {' '}
          {_class.studentCount === '1' ? lang.appKeywords.student : lang.appKeywords.students}
        </div>
      </Card>
    </>
  );
};

ClassCard.propTypes = {
  _class: PropTypes.object,
  selectClass: PropTypes.func,
  isSelected: PropTypes.bool,
  setSelected: PropTypes.func,
  reload: PropTypes.func,
};

export default ClassCard;
