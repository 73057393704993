import React from 'react';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ERROR } from 'constants/eventTypes';
import lang from 'lang';

import useStyles from './styles';

const EventType = ({ type }) => {
  const classes = useStyles();

  const icons = {
    error: faCircleExclamation,
  };

  return (
    <div className={classes.eventTypeContainer}>
      <FontAwesomeIcon
        icon={icons[type]}
        className={cx(classes.eventTypeIcon, { error: type === ERROR })}
      />
      {lang.events.types[type]}
    </div>
  );
};

EventType.propTypes = {
  type: PropTypes.string,
};

export default EventType;
