import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setFeatureToggles } from 'actions/userActions';
import api from 'api';

import useApi from './useApi';

const usePublicFeatureToggles = () => {
  const [togglesRequest] = useApi(api.toggles, true);
  const dispatch = useDispatch();

  useEffect(() => {
    togglesRequest({}, ({ data }) => {
      dispatch(setFeatureToggles(data.featureToggles));
    });
  }, [togglesRequest, dispatch]);
};

export default usePublicFeatureToggles;
