import { useSelector } from 'react-redux';

import { selectUserFeatureToggles } from 'actions/userActions';

const useFeature = (feature) => {
  const userFeatureToggles = useSelector(selectUserFeatureToggles);

  if (!userFeatureToggles) {
    return false;
  }

  const enabled = !!userFeatureToggles.find(toggle => toggle.name === feature);

  return enabled;
};

export default useFeature;
