import React from 'react';
import PropTypes from 'prop-types';

import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';

import useStyles from './styles';

const TeacherFilters = ({ userFilter, setUserFilter }) => {
  const classes = useStyles();
  const iaveToggle = useFeature(toggles.iave);

  return (
    <div className={classes.teacherFilters}>
      <Input
        type="input"
        label={iaveToggle ? lang.teachers.teachersTable.username : lang.teachers.teachersTable.email}
        placeholder={iaveToggle ? lang.teachers.teachersTable.username : lang.teachers.teachersTable.email}
        value={userFilter}
        onChange={(event) => setUserFilter(event.target.value)}
        className={classes.teacherFilter}
      />
    </div>
  );
};

TeacherFilters.propTypes = {
  userFilter: PropTypes.string,
  setUserFilter: PropTypes.func,
};

export default TeacherFilters;
