import React, { useCallback, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Modal from 'components/common/Modal';

const DeleteClassesModal = ({ open, close, classIds, onSubmit, setSelected }) => {
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();
  const [deleteClassesRequest] = useApi(api.deleteSchoolClasses);
  const [loading, setLoading] = useState(false);
  const iaveToggle = useFeature(toggles.iave);
  const isSingleClass = classIds.length === 1;
  const classes = lang.classes[iaveToggle ? 'iave' : 'schools'];
  const headerLangKey = isSingleClass ? 'deleteClass' : 'deleteClasses';
  const descriptionLangKey = isSingleClass ? 'deleteClassDescription' : 'deleteClassesDescription';

  const deleteClasses = useCallback(() => {
    setLoading(true);
    const request = {
      params: {
        schoolId,
      },
      data: {
        classIds: classIds,
      },
    };

    deleteClassesRequest(request, ({ data }) => {
      const successMessage = isSingleClass ? classes.successDeleteClass : classes.successDeleteClasses;
      if (data.status === 0) {
        close();
        toast.success(successMessage);
        setLoading(false);
        onSubmit();
        setSelected([]);
      } else {
        setLoading(false);
        toast.error(lang.oops);
      }
    });
  }, [schoolId, classIds, deleteClassesRequest, isSingleClass, classes, close, toast, onSubmit, setSelected]);

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
    {
      name: lang.delete,
      color: 'red',
      onClick: deleteClasses,
      loading: loading,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={classes[headerLangKey]}
      actions={actions}
      center
      transition
      small
    >
      <div>
        {classes[descriptionLangKey]}
      </div>
    </Modal>
  );
};

DeleteClassesModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  classIds: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func,
  setSelected: PropTypes.func,
};

export default DeleteClassesModal;
