import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { selectIsAdmin, selectIsSuperAdmin } from 'actions/userActions';
import routes from 'routes';

import NoMatch from 'pages/NoMatch';

const PrivateRoute = ({ exact, path, children, hide }) => {
  const isAuthenticated = useSelector(state => state.user.loggedIn);
  const appLoading = useSelector(state => state.page.appLoading);
  const isAdmin = useSelector(selectIsAdmin);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  if ((!isAuthenticated || (!isAdmin && !isSuperAdmin)) && !appLoading) {
    window.location = routes.auth.ref();
  }

  if (hide) {
    return (
      <Route path={path} exact={exact}>
        <NoMatch />
      </Route>
    );
  }

  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  children: PropTypes.any,
  hide: PropTypes.bool,
};

export default PrivateRoute;
