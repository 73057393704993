import React, { useCallback, useMemo, useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import RemoveTeachersModal from '../RemoveTeachersModal';
import IconBtn from 'components/common/IconBtn';
import Input from 'components/common/Input';

import useStyles from './styles';

const TeacherActions = ({ teachers, setTeachers, resetTeachers }) => {
  const classes = useStyles();
  const iaveToggle = useFeature(toggles.iave);

  const [removeTeachersModal, setRemoveTeachersModal] = useState(false);

  const teachersSelectedIds = useMemo(() => {
    return teachers
      .filter(teacher => teacher.selected)
      .map(teacher => teacher.id);
  }, [teachers]);

  const selectAll = useCallback((event) => {
    const newTeachers = teachers
      .map(teacher => ({ ...teacher, selected: event.target.checked }));

    setTeachers(newTeachers);
  }, [teachers, setTeachers]);

  return (
    <div className={classes.teacherFilters}>
      <RemoveTeachersModal
        open={removeTeachersModal}
        close={() => setRemoveTeachersModal(false)}
        teacherIds={teachersSelectedIds}
        resetTeachers={resetTeachers}
      />
      <Input
        type="checkbox"
        label={lang.selectAll}
        onChange={selectAll}
      />
      <Render when={teachersSelectedIds.length !== 0}>
        <IconBtn
          icon={faTrash}
          text={iaveToggle ? lang.deleteSelected : lang.removeSelected}
          onClick={() => setRemoveTeachersModal(true)}
        />
      </Render>
    </div>
  );
};

TeacherActions.propTypes = {
  teachers: PropTypes.array,
  setTeachers: PropTypes.func,
  resetTeachers: PropTypes.func,
};

export default TeacherActions;
