import React, { useCallback, useEffect, useState } from 'react';
import { faCheck, faChevronDown, faChevronUp, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';

import { selectUserData, selectUserSpaceId, setSpaceIdPreference } from 'actions/userActions';
import api from 'api';
import { ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import useStyles from './styles';

const SchoolDropdown = () => {
  const classes = useStyles();
  const user = useSelector(selectUserData);
  const spaceIdPreference = useSelector(selectUserSpaceId);
  const toast = useToast();
  const [updateSpaceIdPreference] = useApi(api.updateSpaceIdPreference);
  const dispatch = useDispatch();

  const [dropdown, setDropdown] = useState(false);

  const updateSpaceId = useCallback((spaceId) => {
    const request = {
      data: {
        spaceId: spaceId,
      },
    };

    updateSpaceIdPreference(request, ({ data }) => {
      if (data.status === 0) {
        dispatch(setSpaceIdPreference(spaceId));
        return;
      }

      if (data.status !== ERROR) {
        toast.error(lang.oops);
      }
    });
  }, [dispatch, toast, updateSpaceIdPreference]);

  const handleChangeSpace = (spaceId) => {
    const currentSpaceId = spaceIdPreference;

    if (currentSpaceId !== spaceId) {
      updateSpaceId(spaceId);
    }

    setDropdown(false);
  };

  useEffect(() => {
    if (spaceIdPreference) {
      return;
    }

    let spaceId;

    if (user.hasPersonalSpace) {
      spaceId = 'p';
    }

    if (!user.hasPersonalSpace && user.schools?.length !== 0) {
      spaceId = user.schools[0].id;
    }

    updateSpaceId(spaceId);
  }, [user, spaceIdPreference, updateSpaceId]);

  const getCurrentSpace = () => {
    let currentSpace;

    if (spaceIdPreference === 'p') {
      currentSpace = 'p';
    }

    if (spaceIdPreference !== 'p' && user.schools?.length !== 0) {
      currentSpace = user.schools.find(school => school.id === spaceIdPreference);
    }

    return (
      <div className={classes.currentSpace}>
        {
          currentSpace !== 'p' ?
            <FontAwesomeIcon
              icon={faStar}
              className={classes.premiumBadgeIcon}
            />
            : null
        }
        {currentSpace !== 'p' ? currentSpace?.name : lang.appKeywords.personalSpace}
      </div>
    );
  };

  if (!spaceIdPreference) {
    return null;
  }

  return (
    <Dropdown
      close={() => setDropdown(false)}
      className={classes.dropdown}
      top
    >
      <div
        onClick={() => setDropdown(!dropdown)}
        className={classes.dropdownToggler}
        role="button"
      >
        {getCurrentSpace()}
        <div className={classes.arrow}>
          <FontAwesomeIcon icon={dropdown ? faChevronUp : faChevronDown} />
        </div>
      </div>
      <DropdownMenu
        open={dropdown}
        className={classes.dropdownMenu}
      >
        <Render when={user.isTeacher && user.hasPersonalSpace}>
          <DropdownMenuItem
            className={classes.dropdownMenuItem}
            onClick={() => handleChangeSpace('p')}
          >
            <div className={classes.iconSlot}>
              <Render when={spaceIdPreference === 'p' || spaceIdPreference === null}>
                <FontAwesomeIcon icon={faCheck} />
              </Render>
            </div>
            {lang.appKeywords.personalSpace}
          </DropdownMenuItem>
        </Render>
        {user.schools ? user.schools.map(school => (
          <DropdownMenuItem
            className={classes.dropdownMenuItem}
            onClick={() => handleChangeSpace(school.id)}
            key={school.id}
          >
            <div className={classes.iconSlot}>
              <Render when={spaceIdPreference === school.id}>
                <FontAwesomeIcon icon={faCheck} />
              </Render>
            </div>
            {school.name}
            <FontAwesomeIcon
              icon={faStar}
              className={classes.premiumBadgeIcon}
            />
          </DropdownMenuItem>
        )) : []}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SchoolDropdown;
