import { createUseStyles } from 'react-jss';

export default createUseStyles({
  eventMessage: {
    lineClamp: '1',
    overflow: 'hidden',
    display: 'box',
    boxOrient: 'vertical',
  },
});
