import React, { useCallback, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const SYNC = 'SYNC';
const SYNCING = 'SYNCING';
const SYNC_SUCCESS = 'SYNC_SUCCESS';

const SyncExamsModal = ({ open, close, fetchSummary }) => {
  const classes = useStyles();
  const [synchronizeExamsRequest] = useApi(api.synchronizeExams);
  const toast = useToast();

  const [syncState, setSyncState] = useState(SYNC);

  const close_ = () => {
    setTimeout(() => {
      setSyncState(SYNC);
    }, 300);
    close();
  };

  const confirm = useCallback(() => {
    setSyncState(SYNCING);
    synchronizeExamsRequest({}, ({ data }) => {
      if (data.status === 0) {
        setSyncState(SYNC_SUCCESS);
        fetchSummary();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        setSyncState(SYNC);
        toast.error(lang.oops);
      }
    });
  }, [synchronizeExamsRequest, toast, fetchSummary]);

  const actions = [
    {
      name: lang.cancel,
      onClick: close_,
      color: 'black',
      hide: syncState !== SYNC,
    },
    {
      name: lang.confirm,
      onClick: confirm,
      hide: syncState !== SYNC,
    },
  ];

  return (
    <Modal
      open={open}
      close={close_}
      header={lang.synchronization.synchronizeExams.header}
      actions={actions}
      center
      transition
      medium
    >
      <Render when={syncState === SYNC}>
        <div>
          {lang.synchronization.synchronizeExams.description}
        </div>
        <Spacer px={20} />
      </Render>
      <Render when={syncState === SYNCING}>
        <Loading active />
      </Render>
      <Render when={syncState === SYNC_SUCCESS}>
        <div className={classes.importContainer}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={classes.importSuccessIcon}
          />
          <div>
            {lang.synchronization.synchronizeExams.synchronizeSuccess}
          </div>
        </div>
      </Render>
    </Modal>
  );
};

SyncExamsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  fetchSummary: PropTypes.func,
};

export default SyncExamsModal;
