import React, { useCallback, useMemo } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

const DeleteStudentsModal = ({ open, close, studentIds, resetStudents }) => {
  const [deleteSchoolUsersRequest, loading] = useApi(api.deleteSchoolUsers);
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();

  const multipleStudents = useMemo(() => {
    return studentIds?.length > 1;
  }, [studentIds]);

  const deleteStudents = useCallback(() => {
    const request = {
      params: {
        schoolId: schoolId,
      },
      data: {
        userIds: studentIds,
      },
    };

    deleteSchoolUsersRequest(request, ({ data }) => {
      if (data.status === 0) {
        toast.success(multipleStudents ? lang.students.deleteStudent.deleteSuccessMultiple : lang.students.deleteStudent.deleteSuccess);
        resetStudents();
        close();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [schoolId, studentIds, deleteSchoolUsersRequest, close, toast, resetStudents, multipleStudents]);

  const actions = [
    {
      name: lang.cancel,
      onClick: close,
      color: 'black',
    },
    {
      name: lang.delete,
      onClick: deleteStudents,
      color: 'red',
      loading: loading,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={multipleStudents ? lang.students.deleteStudent.titleMultiple : lang.students.deleteStudent.title}
      actions={actions}
      center
      transition
      small
    >
      {multipleStudents ? lang.students.deleteStudent.deleteStudentDescriptionMultiple : lang.students.deleteStudent.deleteStudentDescription}
    </Modal>
  );
};

DeleteStudentsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  studentIds: PropTypes.array,
  resetStudents: PropTypes.func,
};

export default DeleteStudentsModal;
