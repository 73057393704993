import React from 'react';
import { Toast } from '@intuitivo/outline';
import { useSelector } from 'react-redux';

const ToastHandler = () => {
  const toastVisible = useSelector(state => state.page.toastVisible);
  const toastMsg = useSelector(state => state.page.toastMsg);
  const toastType = useSelector(state => state.page.toastType);

  return (
    <Toast
      msg={toastMsg}
      visible={toastVisible}
      success={toastType === 'success'}
      warning={toastType === 'warning'}
      error={toastType === 'error'}
    />
  );
};

export default ToastHandler;
