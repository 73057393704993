import React, { useState } from 'react';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import lang from 'lang';

import MoveStudentsModal from '../MoveStudentsModal';
import RemoveStudentsModal from '../RemoveStudentsModal';

import useStyles from './styles';

const ClassStudentsListActions = ({ studentId, getClass }) => {
  const classes = useStyles();
  const [dropdown, setDropdown] = useState(false);
  const [moveStudentsModal, setMoveStudentsModal] = useState(false);
  const [removeStudentModal, setRemoveStudentModal] = useState(false);

  const actions = [
    {
      name: lang.move,
      onClick: () => {
        setMoveStudentsModal(true);
      },
    },
    {
      name: lang.remove,
      onClick: () => {
        setRemoveStudentModal(true);
      },
    },
  ];

  const _action = (action, studentId) => {
    setDropdown(false);
    action.onClick(studentId);
  };

  return (
    <Dropdown
      close={() => setDropdown(false)}
      top
      right
    >
      <MoveStudentsModal
        open={moveStudentsModal}
        close={() => setMoveStudentsModal(false)}
        selectedStudentIds={[studentId]}
        getClass={getClass}
      />
      <RemoveStudentsModal
        open={removeStudentModal}
        close={() => setRemoveStudentModal(false)}
        studentIds={[studentId]}
        getClass={getClass}
      />
      <div className={classes.testOptionsBtnBig} onClick={() => setDropdown(!dropdown)}>
        <FontAwesomeIcon
          icon={faEllipsisH}
        />
      </div>
      <DropdownMenu
        open={dropdown}
        className={classes.optionsDropdown}
      >
        {actions.map((action) => (
          <DropdownMenuItem
            key={action.name}
            className={classes.dropdownItem}
            onClick={() => _action(action, studentId)}
          >
            {action.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

ClassStudentsListActions.propTypes = {
  studentId: PropTypes.string,
  getClass: PropTypes.func,
};

export default ClassStudentsListActions;
