const GET = 'GET';
const POST = 'POST';
const PUT = 'PUT';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

export {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
};
