import { createUseStyles } from 'react-jss';

export default createUseStyles({
  boxContainer: {
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3px',
    '&.dark': {
      borderColor: '#f5f5f599',
    },
  },
  inputLabel: {
    paddingLeft: '10px',
  },
});
