import React from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import lang from 'lang';

import useStyles from './styles';

const SyncSummaryTable = ({ localSyncData, remoteSyncData }) => {
  const classes = useStyles();

  const dataRows = [
    {
      name: lang.synchronization.syncSummary.students,
      local: localSyncData.students,
      remote: remoteSyncData.students,
    },
    {
      name: lang.synchronization.syncSummary.exams,
      local: localSyncData.publications,
      remote: remoteSyncData.publications,
    },
    {
      name: lang.synchronization.syncSummary.attempts,
      local: localSyncData.attempts,
      remote: remoteSyncData.attempts,
    },
  ];

  return (
    <div>
      <Table className={classes.syncSummaryTable}>
        <TableRow header>
          <TableCell />
          <TableCell textAlign="right">
            {lang.synchronization.syncSummary.localServer}
          </TableCell>
          <TableCell textAlign="right">
            {lang.synchronization.syncSummary.centralServer}
          </TableCell>
        </TableRow>
        {dataRows.map(row => (
          <TableRow key={row.name}>
            <TableCell>
              {row.name}
            </TableCell>
            <TableCell textAlign="right">
              {row.local}
            </TableCell>
            <TableCell textAlign="right">
              {row.remote}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

SyncSummaryTable.propTypes = {
  localSyncData: PropTypes.object,
  remoteSyncData: PropTypes.object,
};

export default SyncSummaryTable;
