import React, { useCallback, useMemo } from 'react';
import { faChalkboardUser, faChild, faCircleInfo, faComments, faFileCircleCheck, faGear, faGraduationCap, faLayerGroup, faQuestionCircle, faRotate, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar as OLNavbar } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { pageError } from 'actions/pageActions';
import { selectIsAdmin, selectIsSuperAdmin, selectUserSpaceId } from 'actions/userActions';
import { LOCAL } from 'constants/environments';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import LogoutButton from '../LogoutButton';
import SchoolDropdown from '../SchoolDropdown';
import ALink from 'components/common/ALink';
import Button from 'components/common/Button';
import LangSelector from 'components/common/LangSelector';
import Logo from 'components/common/Logo';
import Tooltip from 'components/common/Tooltip';

import { ReactComponent as LogoIAVE } from './logo_iave.module.svg';
import useStyles from './styles';

const Sidebar = ({ open, setOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector(state => state.user.data);
  const dispatch = useDispatch();
  const isSuperadmin = useSelector(selectIsSuperAdmin);
  const isAdmin = useSelector(selectIsAdmin);
  const spaceId = useSelector(selectUserSpaceId);
  const iaveToggle = useFeature(toggles.iave);
  const adminGroupsToggle = useFeature(toggles.adminGroups);
  const classesToggle = useFeature(toggles.classes);
  const studentAccountsToggle = useFeature(toggles.studentAccounts);

  const page = useMemo(() => history.location.pathname.split('/').pop().split('?')[0], [history.location.pathname]);

  const changePage = useCallback((route, isExternal) => {
    setOpen(false);
    dispatch(pageError(null));

    if (route === page) {
      return;
    }

    if (isExternal) {
      window.location = routes[route].ref(spaceId);
      return;
    }

    history.push(routes[route].ref());
  }, [dispatch, history, page, setOpen, spaceId]);

  const handleAccountRedirect = () => {
    window.location = routes.profile.ref();
  };

  const pageLinks = [
    {
      icon: faUser,
      label: lang.teachers.title,
      page: 'teachers',
      hide: !isSuperadmin,
    },
    {
      icon: faChild,
      label: lang.students.title,
      page: 'students',
      hide: !studentAccountsToggle,
    },
    {
      icon: faUsers,
      label: lang.appKeywords.groups,
      page: 'groups',
      hide: !isSuperadmin || !adminGroupsToggle,
      isExternal: true,
    },
    {
      icon: iaveToggle ? faLayerGroup : faChalkboardUser,
      label: iaveToggle ? lang.appKeywords.examGroupsMin : lang.appKeywords.classes,
      page: 'classes',
      hide: !isSuperadmin || !classesToggle,
    },
    {
      icon: faCircleInfo,
      label: lang.events.title,
      page: 'events',
      hide: !isSuperadmin || !iaveToggle,
    },
    {
      icon: faFileCircleCheck,
      label: lang.exams.title,
      page: 'exams',
      hide: !iaveToggle || (!isSuperadmin && !isAdmin),
    },
    {
      icon: faRotate,
      label: lang.synchronization.title,
      page: 'synchronization',
      hide: process.env.REACT_APP_NODE_ENV !== LOCAL,
    },
  ];

  return (
    <>
      <OLNavbar className={cx(classes.sidebar, { open })}>
        <div className={classes.logoWrapper}>
          {iaveToggle ?
            <LogoIAVE className={classes.iaveLogo} />
            :
            <Logo small />
          }
        </div>
        {pageLinks.map((pageLink) => (
          !pageLink.hide && (
            <div
              key={pageLink.label}
              className={cx(classes.pageLink, 'otl-noselect', { selected: page === pageLink.page })}
              onClick={() => changePage(pageLink.page, pageLink.isExternal)}
            >
              <div className={classes.iconWrapper}>
                <FontAwesomeIcon
                  icon={pageLink.icon}
                  className={classes.pageIcon}
                />
              </div>
              <div className={classes.pageLabel}>
                {pageLink.label}
              </div>
            </div>
          )
        ))}
        <div className={classes.separator}></div>
        <ALink
          href={routes.feedback.ref()}
          target="_blank"
        >
          <div
            className={cx(classes.pageLink, 'otl-noselect')}
          >
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon
                icon={faComments}
                className={classes.pageIcon}
              />
            </div>
            <div className={classes.pageLabel}>
              {lang.feedback}
            </div>
          </div>
        </ALink>
        <ALink
          href={routes.help.ref()}
          target="_blank"
        >
          <div
            className={cx(classes.pageLink, 'otl-noselect')}
          >
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className={classes.pageIcon}
              />
            </div>
            <div className={classes.pageLabel}>
              {lang.help}
            </div>
          </div>
        </ALink>
        <ALink
          href={routes.tutorial.ref()}
          target="_blank"
        >
          <div
            className={cx(classes.pageLink, 'otl-noselect')}
          >
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon
                icon={faGraduationCap}
                className={classes.pageIcon}
              />
            </div>
            <div className={classes.pageLabel}>
              {lang.tutorial}
            </div>
          </div>
        </ALink>
        <div className={classes.profileWrapper}>
          <div className={classes.userName}>
            {user.fullName && user.fullName.split(' ').length > 1 ? `${user.fullName.split(' ')[0]} ${user.fullName.split(' ')[user.fullName.split(' ').length - 1]}` : user.fullName}
          </div>
          <div className={classes.userEmail}>
            {user.email}
          </div>
          <SchoolDropdown />
          <div className={classes.buttons}>
            <LangSelector />
            {process.env.REACT_APP_NODE_ENV !== LOCAL &&
              <Tooltip tip={lang.account}>
                <Button className={cx(classes.button, classes.account)} onClick={handleAccountRedirect}>
                  <FontAwesomeIcon icon={faGear} />
                </Button>
              </Tooltip>
            }
            <LogoutButton />
          </div>
        </div>
      </OLNavbar>
    </>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Sidebar;
