import { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';

import routes from 'routes';

const MicrosoftLoginRedirect = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      history.push(routes.home.ref());
    }
  }, [isAuthenticated, inProgress, history]);

  return null;
};

export default MicrosoftLoginRedirect;
