import { createUseStyles } from 'react-jss';

export default createUseStyles({
  eventFilters: {
    display: 'flex',
    padding: '20px 40px 0 40px',
    gap: '20px',
  },
  eventFilter: {
    minWidth: '250px',
  },
});
