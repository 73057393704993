import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsAdmin, selectIsSuperAdmin } from 'actions/userActions';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';
import routes from 'routes';

import PageHeader from 'components/common/layout/PageHeader';
import ExamsDownload from 'components/exams/ExamsDownload';
import ExamsList from 'components/exams/ExamsList';

const Exams = () => {
  const { loaded } = usePageLogic(lang.exams.title, true);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isAdmin = useSelector(selectIsAdmin);

  const path = [
    {
      type: 'link',
      text: lang.exams.title,
      to: routes.exams.ref(),
    },
  ];

  return (
    <div>
      <PageHeader
        path={path}
      />
      {isSuperAdmin &&
        <ExamsList
          loaded={loaded}
        />
      }
      {isAdmin &&
        <ExamsDownload
          loaded={loaded}
        />
      }
    </div>
  );
};

export default Exams;
