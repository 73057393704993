import { createUseStyles } from 'react-jss';

export default createUseStyles({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
    },
  },
  icon: {
    fontSize: '24px',
  },
  text: {
    fontSize: '14px',
  },
});
