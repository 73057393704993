import React from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const GENERATING = 'GENERATING';
const ZIPPING = 'ZIPPING';
const DOWNLOAD = 'DOWNLOAD';

const DownloadAttemptsPDFModal = ({ open, close, exportState, setExportState, path, attemptsExported, totalAttempts }) => {
  const classes = useStyles();

  const close_ = () => {
    close();
    setTimeout(() => {
      setExportState(GENERATING);
    }, 300);
  };

  return (
    <Modal
      open={open}
      close={close_}
      header={lang.exams.examsTable.exportAttemptPDFs}
      center
      transition
      medium
    >
      <Render when={exportState === GENERATING}>
        <div className={classes.downloadContainer}>
          <div>
            {lang.exams.examsTable.generatingPDFs}
          </div>
          <progress
            value={attemptsExported}
            max={totalAttempts}
            style={{ width: '500px' }}
          />
          <Loading active />
        </div>
      </Render>
      <Render when={exportState === ZIPPING}>
        <div className={classes.downloadContainer}>
          <div>
            {lang.exams.examsTable.zippingPDFs}
          </div>
          <Loading active />
        </div>
      </Render>
      <Render when={exportState === DOWNLOAD}>
        <div className={classes.downloadContainer}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={classes.exportSuccessIcon}
          />
          <div>
            {lang.exams.examsTable.generateSuccessDescription}
          </div>
          <Button
            onClick={() => window.open(routes.s3.ref(path), '_blank')}
            className={classes.downloadButton}
          >
            {lang.download}
          </Button>
        </div>
      </Render>
    </Modal>
  );
};

DownloadAttemptsPDFModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  exportState: PropTypes.string,
  setExportState: PropTypes.func,
  path: PropTypes.string,
  attemptsExported: PropTypes.number,
  totalAttempts: PropTypes.number,
};

export default DownloadAttemptsPDFModal;
