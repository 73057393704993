import React from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import StudentRow from '../StudentRow';

import useStyles from './styles';

const StudentsTable = ({ students, resetStudents, setStudents }) => {
  const classes = useStyles();
  const iaveToggle = useFeature(toggles.iave);

  return (
    <div>
      <Table className={classes.studentsTable}>
        <TableRow header>
          <TableCell>
            {lang.select}
          </TableCell>
          <Render when={iaveToggle}>
            <TableCell>
              {lang.students.studentsTable.schoolIdentifier}
            </TableCell>
          </Render>
          <Render when={!iaveToggle}>
            <TableCell>
              {lang.students.studentsTable.name}
            </TableCell>
          </Render>
          <TableCell>
            {iaveToggle ? lang.students.studentsTable.username : lang.students.studentsTable.email}
          </TableCell>
          <TableCell>
            {iaveToggle ? lang.appKeywords.examGroups : lang.appKeywords.classes}
          </TableCell>
          <TableCell>
            {lang.students.studentsTable.actions}
          </TableCell>
        </TableRow>
        {students.map(student => (
          <StudentRow
            key={student.id}
            student={student}
            resetStudents={resetStudents}
            setStudents={setStudents}
          />
        ))}
      </Table>
    </div>
  );
};

StudentsTable.propTypes = {
  students: PropTypes.array,
  resetStudents: PropTypes.func,
  setStudents: PropTypes.func,
};

export default StudentsTable;
