import React, { useCallback, useState } from 'react';
import { faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import lang from 'lang';

import MoveStudentsModal from '../MoveStudentsModal';
import RemoveStudentsModal from '../RemoveStudentsModal';
import IconBtn from 'components/common/IconBtn';
import Input from 'components/common/Input';
import { useClassContext } from 'pages/Class/context';

import useStyles from './styles';

const ClassStudentsActions = ({ getClass }) => {
  const classes = useStyles();

  const [moveStudentsModal, setMoveStudentsModal] = useState(false);
  const [removeStudentsModal, setRemoveStudentsModal] = useState(false);
  const { setStudents, students } = useClassContext();
  const allSelected = students.data.every(student => student.selected);
  const selectedStudentIds = students.data.filter(student => student.selected).map(student => student.id);

  const onSelectAll = useCallback(
    (event) => setStudents(students.data.map(student => ({ ...student, selected: event.target.checked }))),
    [setStudents, students.data],
  );

  return (
    <div className={classes.studentFilters}>
      <MoveStudentsModal
        open={moveStudentsModal}
        close={() => setMoveStudentsModal(false)}
        selectedStudentIds={selectedStudentIds}
        getClass={getClass}
      />
      <RemoveStudentsModal
        open={removeStudentsModal}
        close={() => setRemoveStudentsModal(false)}
        studentIds={selectedStudentIds}
        getClass={getClass}
      />
      <Input
        type="checkbox"
        label={lang.selectAll}
        onChange={onSelectAll}
        value={allSelected}
      />
      {selectedStudentIds.length !== 0 &&
      <div className={classes.actions}>
        <IconBtn
          icon={faPaperPlane}
          text={lang.moveSelected}
          onClick={() => setMoveStudentsModal(true)}
        />
        <IconBtn
          icon={faTrash}
          text={lang.removeSelected}
          onClick={() => setRemoveStudentsModal(true)}
        />
      </div>
      }
    </div>
  );
};

ClassStudentsActions.propTypes = {
  getClass: PropTypes.func,
};

export default ClassStudentsActions;
