import React from 'react';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const LogoutButton = () => {
  const classes = useStyles();

  const handleLogout = () => {
    window.location = routes.authLogout.ref();
  };

  return (
    <Tooltip tip={lang.logout}>
      <Button
        className={classes.button}
        onClick={handleLogout}
      >
        <FontAwesomeIcon icon={faRightFromBracket} />
      </Button>
    </Tooltip>
  );
};

export default LogoutButton;
