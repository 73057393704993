import React from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import ExamDownloadRow from '../ExamDownloadRow';

import useStyles from './styles';

const ExamsDownloadTable = ({ exams }) => {
  const classes = useStyles();
  const validateAttemptsToggle = useFeature(toggles.validateAttempts);
  const adminAttemptsExportToggle = useFeature(toggles.adminAttemptsExport);

  return (
    <div>
      <Table className={classes.examsTable}>
        <TableRow header>
          <TableCell>
            {lang.exams.examsTable.examName}
          </TableCell>
          {(validateAttemptsToggle || adminAttemptsExportToggle) && (
            <TableCell className={classes.optionsCol}>
              {lang.options}
            </TableCell>
          )}
        </TableRow>
        {exams.map(exam => (
          <ExamDownloadRow
            key={exam.id}
            exam={exam}
          />
        ))}
      </Table>
    </div>
  );
};

ExamsDownloadTable.propTypes = {
  exams: PropTypes.array,
};

export default ExamsDownloadTable;
