import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as LogoIntuitivoGreen } from './logo_intuitivo_green.module.svg';
import { ReactComponent as LogoIntuitivoMidnightGreen } from './logo_intuitivo_midnight_green.module.svg';
import useStyles from './styles';

const Logo = ({ small, medium, large, dark }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.logo, 'otl-noselect', { small, medium, large })}
    >
      <Render when={!dark}>
        <LogoIntuitivoMidnightGreen />
      </Render>
      <Render when={dark}>
        <LogoIntuitivoGreen />
      </Render>
    </div>
  );
};

Logo.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  dark: PropTypes.bool,
};

export default Logo;
