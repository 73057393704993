const route = (def, ref) => ({
  def: def,
  ref: ref || (() => def),
});

const getAppUrl = (app) => {
  if (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'local') {
    const appUrl = `${window.origin.slice(0, window.origin.length - 4)}${app}${window.origin.slice(window.origin.length - 3)}`;
    return appUrl;
  } else {
    const appUrl = `${window.origin.slice(0, window.origin.indexOf('//') + 2)}${app}${window.origin.slice(window.origin.indexOf('.'))}`;
    return appUrl;
  }
};

const routes = {
  help: route(`${process.env.REACT_APP_WEBSITE_URL}/suporte`),
  feedback: route(`${process.env.REACT_APP_WEBSITE_URL}/feedback`),
  tutorial: route(`${process.env.REACT_APP_WEBSITE_URL}/tutoriais`),

  s3: route(null, (path) => `${process.env.REACT_APP_S3_URL}/${path}`),

  auth: route(null, () => getAppUrl(process.env.REACT_APP_AUTH_APP)),
  profile: route(null, () => `${getAppUrl(process.env.REACT_APP_AUTH_APP)}/user`),
  authLogout: route(null, () => `${getAppUrl(process.env.REACT_APP_AUTH_APP)}/logout`),

  groups: route(null, (spaceId) => `${getAppUrl(process.env.REACT_APP_TESTS_APP)}/s/${spaceId}/groups`),
  exam: route(null, (spaceId, testId) => `${getAppUrl(process.env.REACT_APP_TESTS_APP)}/s/${spaceId}/test/${testId}`),

  home: route('/'),
  events: route('/events'),
  teachers: route('/teachers'),
  students: route('/students'),
  exams: route('/exams'),
  examDetails: route('/exams/:examId', (examId) => `/exams/${examId}`),
  synchronization: route('/synchronization'),
  loginMicrosoft: route('/login/microsoft'),
  logout: route('/logout'),
  classes: route('/classes'),
  'class': route('/classes/:classId', (classId) => `/classes/${classId}`),
};

export default routes;
