import React, { useCallback, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

import useStyles from './styles';

const RecoverStudentPasswordModal = ({ open, close, studentId }) => {
  const [recoverSchoolUserPasswordRequest, loading] = useApi(api.recoverSchoolUserPassword);
  const schoolId = useSelector(selectUserSpaceId);
  const classes = useStyles();
  const toast = useToast();

  const [newPassword, setNewPassword] = useState(null);

  const _close = () => {
    close();
    setNewPassword(null);
  };

  const recoverPassword = useCallback(() => {
    const request = {
      params: {
        schoolId: schoolId,
        userId: studentId,
      },
    };

    recoverSchoolUserPasswordRequest(request, ({ data }) => {
      if (data.status === 0) {
        toast.success(lang.students.studentsTable.passwordRecovered);
        setNewPassword(data.password);
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [schoolId, studentId, recoverSchoolUserPasswordRequest, toast]);

  const actions = [
    {
      name: lang.close,
      onClick: _close,
      color: 'black',
    },
  ];

  if (!newPassword) {
    actions.push({
      name: lang.students.studentsTable.recover,
      onClick: recoverPassword,
      color: 'blue',
      loading: loading,
    });
  }

  return (
    <Modal
      open={open}
      close={_close}
      header={newPassword ? lang.students.studentsTable.newPassword : lang.students.studentsTable.recoverPassword}
      actions={actions}
      center
      transition
      small
    >
      {newPassword ? (
        <div className={classes.newPassword}>
          {newPassword}
        </div>
      )
        :
        <div className={classes.description}>
          {lang.students.studentsTable.recoverPasswordDescription}
        </div>
      }

    </Modal>
  );
};

RecoverStudentPasswordModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  studentId: PropTypes.string,
};

export default RecoverStudentPasswordModal;
