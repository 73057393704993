import React from 'react';
import { InputText, InputArea } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { useSelector } from 'react-redux';

import lang from 'lang';

import Checkbox from '../Checkbox';
import Dropzone from '../Dropzone';
import Select from '../Select';

import useStyles from './styles';

const Input = ({ label, hint, errors, optional, type, name, placeholder, isAsync, creatable, loadOptions, value, defaultValue, rows, onDrop, multiple, options, minSize, maxSize, step, pattern, locale, isValidDate, readOnly, onChange, onFocus, onBlur, required, disabled, accept, large, className, _ref, id }) => {
  const classes = useStyles();
  const isDark = useSelector(state => state.page.isDark);

  const getHint = () => {
    if (!hint) {
      return null;
    }

    return (
      <div className={cx(classes.headerItem, classes.hint)}>
        {hint}
      </div>
    );
  };

  const getInput = () => {
    if (type === 'date' || type === 'datetime') {
      return (
        <Datetime
          strictParsing={false}
          locale={locale}
          isValidDate={isValidDate}
          onChange={onChange}
          className={classes.datepicker}
          inputProps={{
            value: value ? (type === 'date' ? moment(value).format(pattern || 'DD-MM-YYYY') : moment(value).format(pattern || 'DD-MM-YYYY HH:mm')) : '',
            defaultValue: defaultValue ? (type === 'date' ? moment(defaultValue).format(pattern || 'DD-MM-YYYY') : moment(defaultValue).format(pattern || 'DD-MM-YYYY HH:mm')) : undefined,
            name: name,
            placeholder: placeholder,
            onFocus: onFocus,
            onBlur: onBlur,
            required: required,
            disabled: disabled,
            autoComplete: 'no',
            readOnly: readOnly ? 'readonly' : undefined,
            ref: _ref,
            id: id,
            className: cx('otl-inputText', { dark: isDark, invalid: errors && errors.length !== 0, large }),
          }}
        />
      );
    }

    if (type === 'textarea') {
      return (
        <InputArea
          name={name}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          rows={rows}
          minLength={minSize}
          maxLength={maxSize}
          pattern={pattern}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          invalid={errors && errors.length !== 0}
          disabled={disabled}
          large={large}
          dark={isDark}
          _ref={_ref}
          id={id}
          className={classes.inputText}
        />
      );
    }

    if (type === 'file') {
      return (
        <Dropzone
          placeholder={placeholder}
          value={value}
          minSize={minSize}
          maxSize={maxSize}
          invalid={errors && errors.length !== 0}
          disabled={disabled}
          onDrop={onDrop}
          multiple={multiple}
          accept={accept}
        />
      );
    }

    if (type === 'select') {
      return (
        <Select
          name={name}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          multiple={multiple}
          options={options}
          isAsync={isAsync}
          creatable={creatable}
          loadOptions={loadOptions}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          invalid={errors && errors.length !== 0}
          disabled={disabled}
          ref={_ref}
          id={id}
        />
      );
    }

    return (
      <InputText
        type={type}
        name={name}
        placeholder={placeholder}
        value={(value || value === 0) ? value : ''}
        defaultValue={defaultValue}
        minLength={minSize}
        maxLength={maxSize}
        min={minSize}
        max={maxSize}
        step={step}
        pattern={pattern}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        invalid={errors && errors.length !== 0}
        disabled={disabled}
        large={large}
        dark={isDark}
        _ref={_ref}
        id={id}
        className={classes.inputText}
      />
    );
  };

  const getErrors = () => {
    if (!errors || typeof errors === 'boolean' || errors.length === 0) {
      return;
    }

    return (
      <div className={classes.errorsContainer}>
        {errors?.map(error => (
          <div key={error}>
            {error}
          </div>
        ))}
      </div>
    );
  };

  if (type === 'checkbox') {
    return (
      <div className={className}>
        <Checkbox
          name={name}
          label={label}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          invalid={errors === true || (errors && errors.length !== 0)}
          disabled={disabled}
          dark={isDark}
          _ref={_ref}
          id={id}
        />
        {getErrors()}
        {getHint()}
      </div>
    );
  }

  return (
    <div className={className}>
      <Render when={label}>
        <div className={classes.headerItem}>
          <label
            className={classes.headerItem}
            htmlFor={id}
          >
            {label}
            <Render when={optional}>
              <span className={classes.optional}>
                {' ('}
                {lang.optional}
                {')'}
              </span>
            </Render>
          </label>
          {getHint()}
        </div>
      </Render>
      {getInput()}
      {getErrors()}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
  ]),
  optional: PropTypes.bool,

  type: PropTypes.oneOf(['text', 'number', 'password', 'email', 'date', 'datetime', 'select', 'textarea', 'file', 'checkbox']),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,

  rows: PropTypes.number,

  onDrop: PropTypes.func,
  multiple: PropTypes.bool,

  options: PropTypes.arrayOf(PropTypes.object),

  isAsync: PropTypes.bool,
  loadOptions: PropTypes.func,
  creatable: PropTypes.bool,

  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  step: PropTypes.number,
  pattern: PropTypes.string,
  locale: PropTypes.string,
  isValidDate: PropTypes.func,
  readOnly: PropTypes.bool,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,

  required: PropTypes.bool,
  disabled: PropTypes.bool,
  accept: PropTypes.array,

  large: PropTypes.bool,

  className: PropTypes.string,
  _ref: PropTypes.object,
  id: PropTypes.string,
};

export default Input;
