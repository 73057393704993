import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  actionWrapper: {
    height: '50px',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  optionsBtn: {
    fontSize: '20px',
    height: '50px',
    width: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '10px',
    opacity: 0.6,
    '&:hover': {
      opacity: 0.9,
      cursor: 'pointer',
    },
    '& svg': {
      marginTop: '9px',
    },
    '&.disabled': {
      opacity: 0.3,
      cursor: 'default',
    },
  },
  optionLoading: {
    padding: 0,
    height: '30px',
    width: '30px',
  },
  optionText: {
    marginTop: '5px',
    fontSize: '12px',
  },
  selectAll: {
    marginLeft: '5px',
    fontSize: '12px',
    color: theme.textColor3,
  },
}));
