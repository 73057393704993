import React, { useCallback, useMemo, useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectIsSuperAdmin } from 'actions/userActions';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import DeleteStudentsModal from '../DeleteStudentsModal';
import UnblockUsersModal from '../UnblockUsersModal';
import Button from 'components/common/Button';
import IconBtn from 'components/common/IconBtn';
import Input from 'components/common/Input';

import useStyles from './styles';

const StudentActions = ({ students, setStudents, resetStudents }) => {
  const classes = useStyles();
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const iaveToggle = useFeature(toggles.iave);

  const [deleteStudentsModal, setDeleteStudentsModal] = useState(false);
  const [unblockUsersModal, setUnblockUsersModal] = useState(false);

  const studentsSelectedIds = useMemo(() => {
    return students
      .filter(student => student.selected)
      .map(student => student.id);
  }, [students]);

  const selectAll = useCallback((event) => {
    const newStudents = students
      .map(student => ({ ...student, selected: event.target.checked }));

    setStudents(newStudents);
  }, [students, setStudents]);

  return (
    <div className={classes.studentFilters}>
      <DeleteStudentsModal
        open={deleteStudentsModal}
        close={() => setDeleteStudentsModal(false)}
        studentIds={studentsSelectedIds}
        resetStudents={resetStudents}
      />
      <UnblockUsersModal
        open={unblockUsersModal}
        close={() => setUnblockUsersModal(false)}
      />
      <Input
        type="checkbox"
        label={lang.selectAll}
        onChange={selectAll}
      />
      <Render when={studentsSelectedIds.length !== 0}>
        <IconBtn
          icon={faTrash}
          text={lang.deleteSelected}
          onClick={() => setDeleteStudentsModal(true)}
        />
      </Render>
      <Render when={isSuperAdmin && iaveToggle}>
        <Button
          onClick={() => setUnblockUsersModal(true)}
        >
          {lang.students.studentActions.unblockUsers}
        </Button>
      </Render>
    </div>
  );
};

StudentActions.propTypes = {
  students: PropTypes.array,
  setStudents: PropTypes.func,
  resetStudents: PropTypes.func,
};

export default StudentActions;
