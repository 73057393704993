import React, { useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Modal from 'components/common/Modal';

const UnblockUsersModal = ({ open, close }) => {
  const [unblockUsersRequest, loading] = useApi(api.unblockUsers);
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();

  const unblockUsers = useCallback(() => {
    const request = {
      params: {
        schoolId,
      },
    };

    unblockUsersRequest(request, ({ data }) => {
      if (data.status === 0) {
        close();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [unblockUsersRequest, schoolId, toast, close]);

  const actions = [
    {
      name: lang.cancel,
      onClick: close,
      color: 'black',
    },
    {
      name: lang.students.studentActions.unblockUsers,
      onClick: unblockUsers,
      color: 'red',
      loading: loading,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.students.studentActions.unblockUsers}
      actions={actions}
      center
      transition
      small
    >
      {lang.students.studentActions.unblockUsersDescription}
    </Modal>
  );
};

UnblockUsersModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default UnblockUsersModal;
