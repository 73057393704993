import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  pageContainer: {
    height: 'calc(100vh - 200px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  code: {
    fontSize: '150px',
    color: theme.accentColor,
    paddingBottom: '20px',
    fontFamily: theme.logoFont,
  },
  message: {
    fontSize: '25px',
    textAlign: 'center',
    paddingBottom: '30px',
    fontFamily: theme.logoFont,
  },
  homeLink: {
    color: theme.textColor,
    textDecoration: 'none',
  },
  homeButton: {
    fontSize: '20px',
    fontWeight: 600,
    padding: '25px 50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  homeButtonIcon: {
    paddingRight: '10px',
  },
}));
