import { faFilePdf, faFileImage as faFileImageR, faFileExcel, faFileWord, faFilePowerpoint } from '@fortawesome/free-regular-svg-icons';
import { faPencilRuler, faUsers, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { utils } from 'xlsx';

import { LOCAL } from 'constants/environments';

const hasDuplicates = (arr) => {
  return arr.some(x => arr.indexOf(x) !== arr.lastIndexOf(x));
};

const getErrorCode = (error) => {
  switch (error) {
    case -2:
      return '403';
    case 1:
      return '404';
    default:
      return '500';
  }
};

const urlToFile = async (url, filename, mimeType) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};

const isValidURL = (str) => {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return regex.test(str);
};

const getIcon = (element) => {
  if (element === 'Tests' || element === 'Testes') {
    return faFileAlt;
  } else if (element === 'Exercises' || element === 'Exercícios') {
    return faPencilRuler;
  } else if (element === 'Groups' || element === 'Grupos') {
    return faUsers;
  }
};

const getFileIcon = (fileType) => {
  const fileTypeNormalized = fileType.toLowerCase();

  if (fileTypeNormalized === 'pdf') {
    return faFilePdf;
  }

  if (fileTypeNormalized === 'jpg' || fileTypeNormalized === 'png' || fileTypeNormalized === 'jpeg') {
    return faFileImageR;
  }

  if (fileTypeNormalized === 'xlsx' || fileTypeNormalized === 'csv' || fileTypeNormalized === 'tsv' || fileTypeNormalized === 'ods') {
    return faFileExcel;
  }

  if (fileTypeNormalized === 'docx' || fileTypeNormalized === 'odt') {
    return faFileWord;
  }

  if (fileTypeNormalized === 'pptx' || fileTypeNormalized === 'odp') {
    return faFilePowerpoint;
  }

  return faFileAlt;
};

const getFileType = (fileType) => {
  const fileTypeNormalized = fileType.toLowerCase();

  if (fileTypeNormalized === 'pdf') {
    return 'pdf';
  }

  if (fileTypeNormalized === 'jpg' || fileTypeNormalized === 'png' || fileTypeNormalized === 'jpeg') {
    return 'image';
  }

  if (fileTypeNormalized === 'xlsx' || fileTypeNormalized === 'csv' || fileTypeNormalized === 'tsv' || fileTypeNormalized === 'ods') {
    return 'spreadsheet';
  }

  if (fileTypeNormalized === 'docx' || fileTypeNormalized === 'odt') {
    return 'text';
  }

  if (fileTypeNormalized === 'pptx' || fileTypeNormalized === 'odp') {
    return 'slides';
  }

  return 'default';
};

const shuffleArr = (array) => {
  let m = array.length, t, i;
  while (m) {
    i = Math.floor(Math.random() * m--);

    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
};

const shuffle = a => {
  return a.reduce((l, e, i) => {
    const j = Math.floor(Math.random() * (a.length - i) + i); // j is in [i, a.length[
    [a[i], a[j]] = [a[j], a[i]];
    return a;
  }, a);
};

const localToServerMillis = (local, offset) => {
  return local - offset;
};

const serverToLocalMillis = (server, offset) => {
  return server + offset;
};

const secondsToMillis = (seconds) => {
  return seconds * 60 * 1000;
};

const millisToSeconds = (millis) => {
  return Math.round(millis / 1000 / 60);
};

const isJson = (item) => {
  item = typeof item !== 'string'
    ? JSON.stringify(item)
    : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === 'object' && item !== null) {
    return true;
  }

  return false;
};

const deepCopy = (obj) => {
  if (Object.prototype.toString.call(obj) === '[object Array]') {
    const out = [];
    let i = 0;
    const len = obj.length;
    for (; i < len; i++) {
      out[i] = deepCopy(obj[i]);
    }
    return out;
  }

  if (obj === null) {
    return null;
  }

  if (typeof obj === 'object') {
    const out = {};
    let i;
    for (i in obj) {
      if (i !== 'ref') {
        out[i] = deepCopy(obj[i]);
      }
    }
    return out;
  }
  return obj;
};

const getCookie = (key) => {
  return Cookies.get(key);
};

const addCookie = (key, value, expires) => {
  const expiresDefault = new Date();
  expiresDefault.setMonth(expiresDefault.getMonth() + 1);

  let domain = process.env.REACT_APP_INTUITIVO_DOMAIN;
  if (process.env.REACT_APP_NODE_ENV === LOCAL) {
    const hostname = window.location.hostname;
    domain = hostname;
  }

  Cookies.set(key, value, { domain: domain, expires: expires || expiresDefault });
};

const removeCookie = (key) => {
  let domain = process.env.REACT_APP_INTUITIVO_DOMAIN;
  if (process.env.REACT_APP_NODE_ENV === LOCAL) {
    const hostname = window.location.hostname;
    domain = hostname;
  }

  Cookies.remove(key, { domain: domain });
};

const simpleCompare = (a, b) => {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};

const readCSV = (fileText) => {
  const text = fileText.toString();
  let delimiter = ',';

  if (text.includes(';')) {
    delimiter = ';';
  }

  const rows = text.split('\n');
  const csvObject = rows.map(row => row.trim().split(delimiter));
  return csvObject;
};

const readWorkbook = (workbook) => {
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  const arrayOfRows = [];
  const range = utils.decode_range(sheet['!ref']);

  for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
    const row = [];
    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const cellAddress = utils.encode_cell({ r: rowNum, c: colNum });
      const cellValue = sheet[cellAddress] ? sheet[cellAddress].v : undefined;
      row.push(cellValue);
    }
    arrayOfRows.push(row);
  }

  return arrayOfRows;
};

export {
  hasDuplicates,
  getErrorCode,
  urlToFile,
  isValidURL,
  getFileIcon,
  getFileType,
  getIcon,
  shuffleArr,
  shuffle,
  localToServerMillis,
  serverToLocalMillis,
  secondsToMillis,
  millisToSeconds,
  isJson,
  deepCopy,
  addCookie,
  getCookie,
  removeCookie,
  simpleCompare,
  readCSV,
  readWorkbook,
};
