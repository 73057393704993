import React from 'react';

import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';
import routes from 'routes';

import PageHeader from 'components/common/layout/PageHeader';
import EventsTable from 'components/events/EventsTable';

const Events = () => {
  const { loaded } = usePageLogic(lang.events.title, true);

  const path = [
    {
      type: 'link',
      text: lang.events.title,
      to: routes.events.ref(),
    },
  ];

  return (
    <div>
      <PageHeader
        path={path}
      />
      <EventsTable
        loaded={loaded}
      />
    </div>
  );
};

export default Events;
