import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import ClassesList from 'components/classes/ClassesList';
import CreateClassModal from 'components/classes/CreateClassModal';
import Input from 'components/common/Input';
import PageHeader from 'components/common/layout/PageHeader';

import useStyles from './styles';

const DEBOUNCE_TIME = 300;

const Classes = () => {
  const styleClasses = useStyles();
  const iaveToggle = useFeature(toggles.iave);
  const { pageLoading, loaded, reload } = usePageLogic(iaveToggle ? lang.appKeywords.examGroups : lang.appKeywords.classes, true);
  const [getClassesRequest] = useApi(api.getSchoolClasses);
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();
  const [classSearch, setClassSearch] = useState('');
  const placeholder = `${lang.classes.schools.classNameLabel}`;
  const [classes, setClasses] = useState(null);
  const [createClassModal, setCreateClassModal] = useState(null);

  const getClasses = useCallback((inputValue) => {
    const request = {
      params: {
        schoolId: schoolId,
      },
      query: {
        studentCount: 'true',
        classSearch: inputValue,
      },
    };

    getClassesRequest(request, ({ data }) => {
      if (data.status === 0) {
        setClasses(data.classes);
        loaded();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        loaded();
      }
    });
  }, [schoolId, getClassesRequest, setClasses, loaded, toast]);

  const searchClasses = useMemo(
    () => debounce((inputValue) => getClasses(inputValue), DEBOUNCE_TIME),
    [getClasses],
  );

  const classesSearchInput = useCallback((inputValue) => {
    setClassSearch(inputValue);
    searchClasses(inputValue);
  }, [searchClasses]);

  useEffect(() => {
    if (pageLoading) {
      getClasses();
    }
  }, [pageLoading, getClasses]);

  const path = [
    {
      type: 'link',
      text: iaveToggle ? lang.appKeywords.examGroups : lang.appKeywords.classes,
      to: routes.classes.ref(),
    },
  ];

  const actions = [
    {
      label: lang.classes[iaveToggle ? 'iave' : 'schools'].createClass,
      onClick: () => setCreateClassModal(true),
    },
  ];

  return (
    <div>
      <PageHeader
        path={path}
        actions={actions}
      />
      <CreateClassModal
        open={createClassModal}
        close={() => setCreateClassModal(false)}
      />
      <Input
        type="input"
        label={lang.appKeywords.classes}
        placeholder={placeholder}
        value={classSearch}
        onChange={(event) => classesSearchInput(event.target.value)}
        className={styleClasses.classesFilter}
      />
      {classes && (
        <ClassesList
          classes={classes}
          getClasses={() => searchClasses(classSearch)}
          reload={reload}
        />
      )}
    </div>
  );
};

export default Classes;
