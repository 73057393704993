import React from 'react';
import { TableCell, TableRow } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';
import { SIMPLE_TIME_FORMAT, getDuration } from 'utils/datetime';

const ExamRow = ({ exam }) => (
  <TableRow>
    <TableCell>
      {exam.name}
    </TableCell>
    <TableCell>
      {exam.groupName}
    </TableCell>
    <TableCell>
      {exam.totalStudents}
    </TableCell>
    <TableCell>
      {exam.totalGeneratedAttempts}
    </TableCell>
    <TableCell>
      {exam.totalStartedAttempts}
    </TableCell>
    <TableCell>
      {exam.finishedAttempts}
    </TableCell>
    <TableCell>
      <Render when={parseInt(exam.totalAttempts) === 0}>
        {lang.exams.examsTable.noAttempts}
      </Render>
      <Render when={parseInt(exam.totalAttempts) !== 0 && exam.averageTime === 0}>
        {lang.exams.examsTable.examInProgress}
      </Render>
      <Render when={parseInt(exam.totalAttempts) !== 0 && exam.averageTime !== 0}>
        {getDuration(exam.averageTime, 'seconds', SIMPLE_TIME_FORMAT)}
      </Render>
    </TableCell>
  </TableRow>
);

ExamRow.propTypes = {
  exam: PropTypes.object,
};

export default ExamRow;
