import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const OptionCard = ({ option, selected, setSelected, disabled }) => {
  const classes = useStyles();

  const onSelect = () => {
    if (disabled) {
      return;
    }

    setSelected(option);
  };

  return (
    <div
      className={cx(classes.card, { selected: selected.value === option.value, disabled })}
      onClick={onSelect}
    >
      {classes.tip &&
        <div className={classes.tooltip}>
          <Tooltip
            tip={option.tip}
          >
            <Icon icon="info-circle" />
          </Tooltip>
        </div>
      }
      <FontAwesomeIcon icon={option.icon} className={classes.optionIcon} />
      <div className={classes.optionText}>
        {option.label}
      </div>
    </div>
  );
};

OptionCard.propTypes = {
  option: PropTypes.object,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  disabled: PropTypes.bool,
};

export default OptionCard;
