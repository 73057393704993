import React from 'react';
import { TableCell, TableRow } from '@intuitivo/outline';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import lang from 'lang';

import EventDetails from '../EventDetails';
import EventType from '../EventType';

import useStyles from './styles';

const EventsRow = ({ event }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell>
        <EventType
          type={event.type}
        />
      </TableCell>
      <TableCell>
        <div className={classes.eventMessage}>
          {event.category}
        </div>
      </TableCell>
      <TableCell>
        {event.username || lang.events.noUser}
      </TableCell>
      <TableCell>
        <Moment format="DD-MM-YYYY HH:mm:ss">
          {event.createdAt}
        </Moment>
      </TableCell>
      <TableCell textAlign="right">
        <EventDetails
          event={event}
        />
      </TableCell>
    </TableRow>
  );
};

EventsRow.propTypes = {
  event: PropTypes.object,
};

export default EventsRow;
