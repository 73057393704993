import React, { useCallback, useState } from 'react';
import { faSearch, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '@intuitivo-pt/outline-ui';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { ADD_STUDENTS_FORM, ADDING, ADD_STUDENTS_SUMMARY } from 'constants/addStudents';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { readCSV } from 'utils';

import ImportClassStudents from '../ImportClassStudents';
import CardSelector from 'components/common/card-selector/CardSelector';
import Input from 'components/common/Input';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const DEBOUNCE_TIME = 500;

const AddStudentsModal = ({ open, close, classId, refresh }) => {
  const classes = useStyles();
  const [addStudentRequest] = useApi(api.addClassStudents);
  const toast = useToast();
  const [studentLoading, setStudentLoading] = useState(false);
  const schoolId = useSelector(selectUserSpaceId);
  const [getSchoolUsersRequest] = useApi(api.getSchoolUsers);
  const [addOption, setAddOption] = useState('selectInput');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const iaveToggle = useFeature(toggles.iave);
  const [files, setFiles] = useState([]);
  const [importStudentsState, setImportStudentsState] = useState(ADD_STUDENTS_FORM);
  const [importStudentsResults, setImportStudentsResults] = useState([]);
  const [notFoundLines, setNotFoundLines] = useState([]);

  const close_ = () => {
    setTimeout(() => {
      setFiles([]);
      setImportStudentsState(ADD_STUDENTS_FORM);
    }, 300);
    close();
  };

  const confirm = useCallback(async () => {
    const reader = new FileReader();

    reader.onload = async () => {
      const text = reader.result;
      const studentsRaw = readCSV(text);

      const parseStudentRaw = student => ({
        ...{ email: null, username: null },
        ...(
          iaveToggle
            ? {
              username: student[0]?.trim().toLowerCase(),
            }
            : {
              email: student[0]?.trim().toLowerCase(),
            }
        ),
      });

      const uniqueKey = iaveToggle ? 'username' : 'email';
      const hasDuplicates = (students) =>
        (new Set(students.map(student => student[uniqueKey]))).size !== students.length;

      const students = studentsRaw.reduce(
        (acc, studentRaw) =>
          !studentRaw[0] ? acc : [...acc, { ...parseStudentRaw(studentRaw) }],
        [],
      );

      if (hasDuplicates(students)) {
        toast.error(lang.students.importStudents.duplicateError[uniqueKey]);
        return;
      }

      const request = {
        params: {
          schoolId,
          classId,
        },
        data: {
          [iaveToggle ? 'usernames' : 'emails']: students.map(student => student[uniqueKey]),
        },
      };

      setImportStudentsState(ADDING);
      addStudentRequest(request, ({ data }) => {
        if (data.status === 0) {
          toast.success(lang.students.importStudents.successImport);
          refresh();
          setImportStudentsState(ADD_STUDENTS_SUMMARY);
          setImportStudentsResults(data.importResults);

          const linesNotFoundArray = students.reduce((acc, student, index) => {
            if (data.importResults.notFound.includes(student[uniqueKey])) {
              return [...acc, index + 1];
            }
            return acc;
          }, []);

          setNotFoundLines(linesNotFoundArray);

        } else if (data.status !== ERROR && data.status !== CANCELED) {
          setImportStudentsState(ADD_STUDENTS_FORM);
          toast.error(lang.oops);
        }
      });
    };

    reader.readAsText(files[0]);
  }, [files, iaveToggle, schoolId, classId, addStudentRequest, toast, refresh]);

  const save = async () => {
    if (studentLoading) {
      return;
    }

    setStudentLoading(true);

    const students = selectedStudents.map(selectedStudent => selectedStudent.value);
    const request = {
      params: {
        schoolId,
        classId,
      },
      data: {
        ids: students,
      },
    };

    addStudentRequest(request, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          setSelectedStudents([]);
          setStudentLoading(false);
          refresh();
          toast.success(lang.students.addStudents.success);
        }, 300);
        close();
        return;
      }

      setStudentLoading(false);
      toast.error(lang.oops);
    });
  };

  const loadOptions = debounce(useCallback((inputValue, callback) => {
    if (inputValue.trim().length < 3) {
      return;
    }

    const request = {
      params: {
        schoolId: schoolId,
      },
      query: {
        role: 'student',
        userSearch: inputValue,
      },
    };

    getSchoolUsersRequest(request, ({ data }) => {
      if (data.status === 0) {
        callback(data.users.map(user => ({
          value: user.id,
          label: user.fullName,
        })));
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [getSchoolUsersRequest, schoolId, toast]), DEBOUNCE_TIME);

  const actions = [
    {
      name: lang.cancel,
      onClick: addOption === 'uploadFile' ? close_ : close,
      color: 'black',
      hide: addOption === 'uploadFile' && importStudentsState !== ADD_STUDENTS_FORM,
    },
    {
      name: lang.confirm,
      onClick: addOption === 'uploadFile' ? confirm : save,
      loading: studentLoading,
      hide: addOption === 'uploadFile' && importStudentsState !== ADD_STUDENTS_FORM,
    },
  ];

  const ADD_STUDENT_OPTIONS = [
    {
      value: 'selectInput',
      label: lang.students.addStudents.selectInput,
      icon: faSearch,
    },
    {
      value: 'uploadFile',
      label: lang.students.addStudents.uploadFile,
      icon: faUpload,
    },
  ];

  return (
    <Modal
      header={lang.students.addStudents.header}
      open={open}
      close={addOption === 'uploadFile' ? close_ : close}
      center
      transition
      actions={actions}
    >
      <CardSelector
        options={ADD_STUDENT_OPTIONS}
        selected={ADD_STUDENT_OPTIONS.find(el => el.value === addOption)}
        setSelected={(option) => setAddOption(option.value)}
      />
      {addOption === 'selectInput' &&
      <Input
        className={classes.input}
        type="select"
        hint={lang.students.addStudents.hintTargets}
        label={lang.students.addStudents.studentFilter}
        placeholder={lang.students.addStudents.studentFilter}
        multiple
        isAsync
        loadOptions={loadOptions}
        value={selectedStudents}
        onChange={setSelectedStudents}
      />
      }
      {addOption === 'uploadFile' &&
        <ImportClassStudents
          files={files}
          setFiles={setFiles}
          importStudentsResults={importStudentsResults}
          importStudentsState={importStudentsState}
          notFoundLines={notFoundLines}
        />
      }
    </Modal>
  );
};

AddStudentsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  classId: PropTypes.string,
  refresh: PropTypes.func,
};

export default AddStudentsModal;
