import { createUseStyles } from 'react-jss';

export default createUseStyles({
  classesFilter: {
    minWidth: '250px',
    marginTop: '20px',
    padding: '20px',
  },

});
