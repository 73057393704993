import { createUseStyles } from 'react-jss';

export default createUseStyles({
  eventsTable: {
    padding: '40px 40px',
  },
  eventFilters: {
    display: 'flex',
    padding: '20px 40px 0 40px',
  },
  eventFilter: {
    minWidth: '200px',
  },
});
