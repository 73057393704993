import { createUseStyles } from 'react-jss';

export default createUseStyles({
  classesList: {
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  actions: {
    display: 'flex',
    padding: '20px',
  },
});
