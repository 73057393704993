import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  headerItem: {
    paddingBottom: '5px',
  },
  hint: {
    color: theme.textColor3,
    fontSize: '12px',
  },
  errorsContainer: {
    marginTop: '10px',
    fontSize: '14px',
    color: theme.errorColor,
  },
  datepicker: {
    color: '#2d2d2d',
    '& > .otl-inputText': {
      borderRadius: '18px',
      borderColor: theme.inputBorder3,
      '&.invalid': {
        borderColor: '#fb6045',
      },
    },
  },
  optional: {
    color: theme.textColor2,
    fontSize: '13px',
  },
  inputText: {
    borderRadius: '18px !important',
    borderColor: theme.inputBorder3,
    '& .ql-toolbar': {
      borderRadius: '18px 18px 0 0',
    },
    '& .ql-container': {
      borderRadius: '0 0 18px 18px',
    },
  },
}));
