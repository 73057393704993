import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';
import RSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import isEmail from 'validator/lib/isEmail';

import lang from 'lang';

import styles from './styles';

const Select = ({ name, placeholder, value, defaultValue, isAsync, creatable, loadOptions, multiple, options, onChange, onFocus, onBlur, disabled, invalid, dark, _ref, id }) => {
  const theme = useTheme();
  const classes = styles(theme);

  const validateNewOption = (inputValue, selectOptions, options) => {
    return isEmail(inputValue) && !selectOptions.some(elem => elem.label === inputValue) && !options.some(elem => elem.label === inputValue);
  };

  const formatCreateLabel = (inputValue) => {
    return lang.add + ' ' + inputValue;
  };

  if (creatable) {
    return (
      <AsyncCreatableSelect
        name={name}
        placeholder={placeholder || `${lang.select}...`}
        value={value}
        isMulti={multiple}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        isValidNewOption={validateNewOption}
        formatCreateLabel={formatCreateLabel}
        loadOptions={loadOptions}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isDisabled={disabled}
        className={cx({ invalid })}
        styles={classes}
        dark={dark}
        ref={_ref}
        inputId={id}
      />
    );
  } else if (isAsync) {
    return (
      <AsyncSelect
        name={name}
        placeholder={placeholder || `${lang.select}...`}
        value={value}
        isMulti={multiple}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        loadOptions={loadOptions}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isDisabled={disabled}
        className={cx({ invalid })}
        styles={classes}
        dark={dark}
        ref={_ref}
        inputId={id}
      />
    );
  } else {
    return (
      <RSelect
        name={name}
        placeholder={placeholder || `${lang.select}...`}
        value={value}
        defaultValue={defaultValue}
        isMulti={multiple}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        options={options}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isDisabled={disabled}
        className={cx({ invalid })}
        styles={classes}
        dark={dark}
        ref={_ref}
        inputId={id}
      />
    );

  }
};

Select.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  isAsync: PropTypes.bool,
  creatable: PropTypes.bool,
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  dark: PropTypes.bool,
  _ref: PropTypes.object,
  id: PropTypes.string,
};

export default Select;
