import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';
import routes from 'routes';

import PageHeader from 'components/common/layout/PageHeader';
import Loading from 'components/common/Loading';
import AddTeachersModal from 'components/teachers/AddTeachersModal';
import TeacherActions from 'components/teachers/TeacherActions';
import TeacherFilters from 'components/teachers/TeacherFilters';
import TeachersTable from 'components/teachers/TeachersTable';

const PAGE_SIZE = 50;

const Teachers = () => {
  const { loaded } = usePageLogic(lang.teachers.title, true);
  const [getSchoolUsersRequest, loading, abortRequest] = useApi(api.getSchoolUsers);
  const toast = useToast();
  const schoolId = useSelector(selectUserSpaceId);

  const [teachers, setTeachers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [addTeachersModal, setAddTeachersModal] = useState(false);
  const [userFilter, setUserFilter] = useState('');

  const fetchTeachers = useCallback((page) => {
    if (!schoolId) {
      return;
    }

    const request = {
      params: {
        schoolId: schoolId,
      },
      query: {
        page: page,
        pageSize: PAGE_SIZE,
        userSearch: userFilter,
        role: 'teacher',
        relations: ['groups'],
      },
    };

    getSchoolUsersRequest(request, ({ data }) => {
      if (data.status === 0) {
        page !== 1
          ? setTeachers(teachers => [...teachers, ...data.users])
          : setTeachers(data.users);
        setHasMore(data.users.length === PAGE_SIZE);
        setPage(page => page + 1);
        loaded();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        loaded();
      }
    });
  }, [schoolId, userFilter, getSchoolUsersRequest, loaded, toast]);

  const resetTeachers = useCallback(() => {
    setPage(1);
    setTeachers([]);
    fetchTeachers(1);
  }, [fetchTeachers]);

  useEffect(() => {
    resetTeachers();

    return () => {
      abortRequest();
    };
  }, [resetTeachers, abortRequest]);

  const path = [
    {
      type: 'link',
      text: lang.teachers.title,
      to: routes.teachers.ref(),
    },
  ];

  const actions = [
    {
      label: lang.teachers.addTeachers.header,
      onClick: () => setAddTeachersModal(true),
    },
  ];

  return (
    <div>
      <PageHeader
        path={path}
        actions={actions}
      />
      <AddTeachersModal
        open={addTeachersModal}
        close={() => setAddTeachersModal(false)}
        resetTeachers={resetTeachers}
        teachersCount={teachers.length}
      />
      <TeacherFilters
        userFilter={userFilter}
        setUserFilter={setUserFilter}
      />
      <TeacherActions
        teachers={teachers}
        setTeachers={setTeachers}
        resetTeachers={resetTeachers}
      />
      <InfiniteScroll
        dataLength={teachers.length}
        next={() => fetchTeachers(page)}
        hasMore={hasMore}
        loader={<Loading active={loading} />}
      >
        <TeachersTable
          teachers={teachers}
          resetTeachers={resetTeachers}
          setTeachers={setTeachers}
        />
      </InfiniteScroll>
    </div>
  );
};

export default Teachers;
