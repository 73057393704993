import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  classCard: {
    padding: '10px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.cardColor2,
    },
    '& .otl-cardContentContainer': {
      padding: 0,
      width: '158px',
      height: '158px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  classActionsIcon: {
    width: '15px',
    height: '15px',
    position: 'absolute',
  },
  className: {
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '1',
    boxOrient: 'vertical',
    marginTop: '0 !important',
    fontSize: '17px',
    fontFamily: 'Varela Round',
  },
  studentCount: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.textColor10,
    marginTop: '55px',
  },
  settings: {
    top: '10px',
    right: '10px',
    padding: '5px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.grey + '77',
    },
  },
  selector: {
    top: '0',
    left: '0',
    padding: '15px',
    borderRadius: '40% 0 40% 0',
    backgroundColor: theme.grey + '77',
    '& label': {
      padding: 0,
    },
  },
  selectorInput: {
    '& input': {
      cursor: 'pointer',
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    classCard: {
      '& .otl-cardContentContainer': {
        padding: 0,
        width: '100px',
        height: '100px',
      },
    },
  },
}));
