/**
 * Action constants
 */
const APP_LOADED = 'APP_LOADED';
const PAGE_LOADING = 'PAGE_LOADING';
const PAGE_LOADED = 'PAGE_LOADED';
const PAGE_ERROR = 'PAGE_ERROR';
const TOGGLE_DARK = 'TOGGLE_DARK';
const SHOW_LAYOUT = 'SHOW_LAYOUT';
const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
const SET_CLOCK = 'SET_CLOCK';

/**
 * Action creators
 */
const appLoaded = () => ({
  type: APP_LOADED,
});

const pageLoading = () => ({
  type: PAGE_LOADING,
});

const pageLoaded = () => ({
  type: PAGE_LOADED,
});

const pageError = (code) => ({
  type: PAGE_ERROR,
  code: code,
});

const toggleDark = () => {
  if (localStorage.getItem('isDark') === null) {
    localStorage.setItem('isDark', 'maybe');
  } else {
    localStorage.removeItem('isDark');
  }

  return {
    type: TOGGLE_DARK,
  };
};

const showLayout = (layout) => ({
  type: SHOW_LAYOUT,
  layout: layout,
});

const setAuthenticated = (authenticated) => ({
  type: SET_AUTHENTICATED,
  authenticated: authenticated,
});

const setClock = (clockOffset) => ({
  type: SET_CLOCK,
  clockOffset: clockOffset,
});

export {
  APP_LOADED,
  PAGE_LOADING,
  PAGE_LOADED,
  PAGE_ERROR,
  TOGGLE_DARK,
  SHOW_LAYOUT,
  SET_AUTHENTICATED,
  SET_CLOCK,
  appLoaded,
  pageLoading,
  pageLoaded,
  pageError,
  toggleDark,
  showLayout,
  setAuthenticated,
  setClock,
};
