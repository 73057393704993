import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  descriptionText: {
    marginTop: '20px',
  },
  input: {
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  downloadContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    gap: '30px',
    flexDirection: 'column',
  },
  downloadButton: {
    width: '200px',
  },
  downloadButtonIcon: {
    fontSize: '16px',
    marginRight: '5px',
  },
}));
