import { GET, PATCH, POST, PUT } from 'constants/requestMethods';

const api = {
  // General
  clock: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: () => '/clock', method: GET },
  toggles: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: () => '/toggles', method: GET },

  // Me
  me: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me', method: GET },
  updateLang: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/update-lang', method: PUT },
  updateSpaceIdPreference: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/spaceid', method: PUT },

  // Files
  uploadFile: { apiUrl: process.env.REACT_APP_TESTS_API_URL, path: () => '/files/upload-file', method: POST, contentType: 'multipart/form-data' },

  // Schools
  getSchoolEvents: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/events`, method: GET },
  createSchoolStudents: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/students`, method: POST },
  getSchoolClasses: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/classes`, method: GET },
  getSchoolClass: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId, classId }) => `/schools/${schoolId}/classes/${classId}`, method: GET },
  deleteSchoolUsers: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/users/delete`, method: POST },
  closeAttempts: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/attempts-open`, method: PATCH },
  unblockUsers: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/unblock-users`, method: PATCH },
  getSchoolPublications: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/publications`, method: GET },
  addSchoolTeachers: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/teachers`, method: POST },
  removeSchoolTeachers: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/users/remove`, method: POST },
  createClass: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/classes`, method: POST },
  getSchoolUsers: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/users`, method: GET },
  moveClassStudents: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId, classId }) => `/schools/${schoolId}/classes/${classId}/move`, method: POST },
  addClassStudents: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId, classId }) => `/schools/${schoolId}/classes/${classId}`, method: POST },
  deleteSchoolClasses: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId }) => `/schools/${schoolId}/classes/delete`, method: POST },
  removeClassStudents: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId, classId }) => `/schools/${schoolId}/classes/${classId}/remove`, method: PATCH },
  recoverSchoolUserPassword: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId, userId }) => `/schools/${schoolId}/users/${userId}/password`, method: PUT },
  editSchoolClass: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: ({ schoolId, classId }) => `/schools/${schoolId}/classes/${classId}`, method: PATCH },

  // Synchronization
  getSyncSummary: { apiUrl: process.env.REACT_APP_ADMIN_API_URL, path: () => '/synchronization/summary', method: GET },

  // Local
  synchronizeExams: { apiUrl: process.env.REACT_APP_LOCAL_ADMIN_API_URL, path: () => '/local/synchronize-exams', method: POST },
  getLocalSyncSummary: { apiUrl: process.env.REACT_APP_LOCAL_ADMIN_API_URL, path: () => '/local/summary', method: GET },
  synchronizeAttempts: { apiUrl: process.env.REACT_APP_LOCAL_ADMIN_API_URL, path: () => '/local/synchronize-attempts', method: POST },

  // Publications
  exportStudentAttempts: { apiUrl: process.env.REACT_APP_TESTS_API_URL, path: ({ publicationId }) => `/publications/${publicationId}/student-attempts/export`, method: POST },

  // Publication Exports
  getPublicationExport: { apiUrl: process.env.REACT_APP_TESTS_API_URL, path: ({ publicationExportId }) => `/publication-exports/${publicationExportId}`, method: GET },
  zipPublicationExport: { apiUrl: process.env.REACT_APP_TESTS_API_URL, path: ({ publicationExportId }) => `/publication-exports/${publicationExportId}`, method: POST },
};

export default api;
