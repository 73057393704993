import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import lang from 'lang';

import EventType from '../EventType';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const EventDetailsModal = ({ open, close, event }) => {
  const classes = useStyles();

  const fields = [
    {
      label: lang.events.eventId,
      value: event.id,
    },
    {
      label: lang.events.eventType,
      value: (
        <EventType type={event.type} />
      ),
    },
    {
      label: lang.events.category,
      value: event.category,
    },
    {
      label: lang.events.message,
      value: event.details.message,
    },
    {
      label: lang.events.user,
      value: event.fullName,
    },
    {
      label: lang.events.date,
      value: moment(event.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      label: lang.events.service,
      value: event.details.service,
    },
    {
      label: lang.events.url,
      value: event.details.url,
    },
    {
      label: lang.events.query,
      value: (
        <pre className={classes.codeField}>
          {JSON.stringify(event.details.query, null, 2)}
        </pre>
      ),
    },
    {
      label: lang.events.body,
      value: (
        <pre className={classes.codeField}>
          {JSON.stringify(event.details.body, null, 2)}
        </pre>
      ),
    },
    {
      label: lang.events.stack,
      value: event.details.stack,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.events.details}
      center
      transition
      medium
    >
      <div className={classes.eventFieldContainer}>
        {fields.map(field => (
          <>
            <div className={classes.eventFieldLabel}>
              {field.label}
              :
            </div>
            <div className={classes.eventFieldValue}>
              {field.value}
            </div>
          </>
        ))}
      </div>
    </Modal>
  );
};

EventDetailsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  event: PropTypes.object,
};

export default EventDetailsModal;
