import React, { useState } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow, Tooltip } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { deepCopy } from 'utils';

import RemoveTeachersModal from '../RemoveTeachersModal';
import Input from 'components/common/Input';

import useStyles from './styles';

const TeacherRow = ({ teacher, setTeachers, resetTeachers }) => {
  const classes = useStyles();
  const iaveToggle = useFeature(toggles.iave);

  const [removeTeacherModal, setRemoveTeacherModal] = useState(false);

  const onSelect = (event) => {
    setTeachers(teachers => {
      const newTeachers = deepCopy(teachers);

      const newTeacher = newTeachers.find(newTeacher => newTeacher.id === teacher.id);
      newTeacher.selected = event.target.checked;

      return newTeachers;
    });
  };

  return (
    <TableRow>
      <TableCell colSpan={1}>
        <Input
          type="checkbox"
          value={teacher.selected}
          onChange={onSelect}
        />
      </TableCell>
      <Render when={!iaveToggle}>
        <TableCell>
          <div>
            {teacher.fullName}
          </div>
        </TableCell>
      </Render>
      <TableCell>
        {iaveToggle ? teacher.username : teacher.email}
      </TableCell>
      <TableCell>
        {teacher.groups?.map(group => group.name).join(', ')}
      </TableCell>
      <TableCell textAlign="center">
        <RemoveTeachersModal
          open={removeTeacherModal}
          close={() => setRemoveTeacherModal(false)}
          teacherIds={[teacher.id]}
          resetTeachers={resetTeachers}
        />
        <Tooltip tip={iaveToggle ? lang.teachers.removeTeacher.deleteTitle : lang.teachers.removeTeacher.removeTitle}>
          <FontAwesomeIcon
            icon={faXmark}
            className={classes.removeIcon}
            onClick={() => setRemoveTeacherModal(true)}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

TeacherRow.propTypes = {
  teacher: PropTypes.object,
  setTeachers: PropTypes.func,
  resetTeachers: PropTypes.func,
};

export default TeacherRow;
