import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { pageError } from 'actions/pageActions';
import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';

import useStyles from './styles';

const Error = ({ code }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const getErrorMessage = () => {
    switch (code) {
      case '403':
        return lang.unauthorizedMessage;
      case '404':
        return lang.notFoundMessage;
      case '500':
        return lang.errorMessage;
      default:
        return '';
    }
  };

  const _onClick = () => {
    dispatch(pageError(null));

    const redirectContext = routes.home.ref();

    if (code === '500') {
      window.location.replace(redirectContext);
    } else {
      history.replace(redirectContext);
    }
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.code}>
        {code}
      </div>
      <div className={classes.message}>
        {getErrorMessage()}
      </div>
      <Button
        className={classes.homeButton}
        onClick={() => _onClick()}
      >
        <FontAwesomeIcon
          icon={faHome}
          className={classes.homeButtonIcon}
        />
        {lang.home}
      </Button>
    </div>
  );
};

Error.propTypes = {
  code: PropTypes.string.isRequired,
};

export default Error;
