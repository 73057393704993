import { createUseStyles } from 'react-jss';

export default createUseStyles({
  studentFilters: {
    display: 'flex',
    padding: '20px 40px 0 40px',
    gap: '20px',
  },
  actions: {
    display: 'flex',
    gap: '20px',
  },
});
