import React, { useState } from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Loading from 'components/common/Loading';

import useStyles from './styles';

const SecondaryPageHeader = ({ header, extraInformations, actions, extraActions }) => {
  const classes = useStyles();

  const [dropdown, setDropdown] = useState(false);

  const getExtraInformations = () => {
    return extraInformations
      .filter(info => !info.hide)
      .map((info) => (
        <div
          className={classes.description}
          key={info.label}
        >
          {info.icon &&
            <div className={classes.descriptionIcon}>
              <FontAwesomeIcon
                icon={info.icon}
              />
            </div>
          }
          {info.label}
        </div>
      ));
  };

  const getActions = () => {
    return actions
      .filter(action => !action.hide)
      .map((action) => (
        <div
          key={action.label}
          className={cx(classes.actionWrap, { active: action.active, disabled: action.disabled || action.loading })}
          onClick={action.disabled || action.loading ? () => { } : action.onClick}
        >
          <Loading active={action.loading} className={classes.actionLoading} />
          {!action.loading &&
          <FontAwesomeIcon
            icon={action.icon}
          />
          }
          <div className={classes.actionTitle}>
            {action.label}
          </div>
        </div>
      ));
  };

  const getExtraActions = () => {
    if (extraActions.length === 0) {
      return;
    }

    return (
      <Dropdown
        close={() => setDropdown(false)}
        left
      >
        <div
          className={cx(classes.extraActions, { hide: extraActions.filter(action => !action.hide).length === 0 })}
          onClick={() => setDropdown(!dropdown)}
        >
          <FontAwesomeIcon
            icon={faEllipsisV}
          />
        </div>
        <DropdownMenu
          open={dropdown}
          className={classes.extraActionsDropdownMenu}
        >
          {actions.map((action) => (
            <DropdownMenuItem
              key={action.label}
              className={cx(classes.dropdownItemAction, { isPublished: action.active })}
              onClick={action.onClick}
            >
              <div className={classes.dropdownItemBtn}>
                <FontAwesomeIcon
                  icon={action.icon}
                />
                {action.label}
              </div>
            </DropdownMenuItem>
          ))}
          {extraActions.filter(action => !action.hide).map((action) => (
            <DropdownMenuItem
              key={action.label}
              className={classes.extraActionsDropdownItem}
              onClick={action.onClick}
            >
              {action.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        {header}
      </div>
      <div className={classes.headerSplitter}>
        <div className={classes.headerLeft}>
          {getExtraInformations()}
        </div>
        <div className={classes.headerRight}>
          <div className={classes.headerActions}>
            {getActions()}
            {getExtraActions()}
          </div>
        </div>
      </div>
    </div>
  );
};

SecondaryPageHeader.propTypes = {
  header: PropTypes.string.isRequired,
  extraInformations: PropTypes.array,
  actions: PropTypes.array,
  extraActions: PropTypes.array,
};

SecondaryPageHeader.defaultProps = {
  extraInformations: [],
  actions: [],
  extraActions: [],
};

export default SecondaryPageHeader;
