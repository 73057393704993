import React, { useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserData, selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import ExamsDownloadTable from '../ExamsDownloadTable';

const ExamsDownload = ({ loaded }) => {
  const [getSchoolPublicationsRequest] = useApi(api.getSchoolPublications);
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();
  const user = useSelector(selectUserData);

  const [exams, setExams] = useState([]);

  useEffect(() => {
    const request = {
      params: {
        schoolId: schoolId,
      },
      query: {
        adminUsername: user.username,
      },
    };

    getSchoolPublicationsRequest(request, ({ data }) => {
      if (data.status === 0) {
        setExams(data.publications);
        loaded();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [schoolId, user, getSchoolPublicationsRequest, loaded, toast]);

  return (
    <ExamsDownloadTable
      exams={exams}
    />
  );
};

ExamsDownload.propTypes = {
  loaded: PropTypes.func,
};

export default ExamsDownload;
