import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  card: {
    border: `3px solid ${theme.shade0}`,
    borderRadius: '10px',
    minWidth: '134px',
    minHeight: '94px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    padding: '0',
    cursor: 'pointer',
    transition: 'background-color 0.25s ease-in-out, border 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: `${theme.buttonBackgroundHoverGrey}66`,
    },
    '&.selected': {
      border: `3px solid ${theme.buttonBackground2}`,
    },
    '&.disabled': {
      opacity: 0.6,
      cursor: 'default',
      backgroundColor: theme.white,
    },
  },
  tooltip: {
    position: 'absolute !important',
    top: '10px',
    right: '10px',
  },
  optionIcon: {
    fontSize: '2.25em',
    opacity: '0.8',
  },
  optionText: {
    marginTop: '0.5em',
  },
}));
