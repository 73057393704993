import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {

  },
  items: {
    display: 'flex',
    gap: '0.5em',
    justifyContent: 'center',
    marginTop: '0.25em',
  },
  mobileOption: {
    display: 'none',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    wrapper: {
      display: 'none',
    },
    mobileOption: {
      display: 'unset',
    },
  },
}));
