import React, { useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';

import useStyles from './styles';

const StudentFilters = ({ userFilter, setUserFilter, classesFilter, setClassesFilter }) => {
  const classes = useStyles();
  const iaveToggle = useFeature(toggles.iave);
  const [classOptions, setClassOptions] = useState([]);
  const schoolId = useSelector(selectUserSpaceId);
  const [getSchoolClassesRequest] = useApi(api.getSchoolClasses);
  const toast = useToast();

  useEffect(() => {
    const request = {
      params: {
        schoolId: schoolId,
      },
    };

    getSchoolClassesRequest(request, ({ data }) => {
      if (data.status === 0) {
        const newClassOptions = data.classes.map(class_ => ({
          value: class_.id,
          label: class_.name,
        }));
        setClassOptions(newClassOptions);
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [getSchoolClassesRequest, schoolId, toast]);

  return (
    <div className={classes.studentFilters}>
      <Input
        type="select"
        label={iaveToggle ? lang.students.studentFilters.groups : lang.students.studentFilters.classes}
        options={classOptions}
        multiple
        value={classesFilter}
        onChange={newValue => setClassesFilter(newValue)}
        className={classes.studentFilter}
      />
      <Input
        type="input"
        label={iaveToggle ? lang.students.studentFilters.username : lang.students.studentFilters.email}
        placeholder={iaveToggle ? lang.students.studentFilters.username : lang.students.studentFilters.email}
        value={userFilter}
        onChange={(event) => setUserFilter(event.target.value)}
        className={classes.studentFilter}
      />
    </div>
  );
};

StudentFilters.propTypes = {
  userFilter: PropTypes.string,
  setUserFilter: PropTypes.func,
  classesFilter: PropTypes.string,
  setClassesFilter: PropTypes.func,
};

export default StudentFilters;
