const toggles = {
  iave: 'iave',
  adminAttemptsExport: 'admin-attempts-export',
  adminGroups: 'admin-groups',
  classes: 'classes',
  attemptsCache: 'attempts-cache',
  validateAttempts: 'validate-attempts',
  studentAccounts: 'student-accounts',
};

export default toggles;
