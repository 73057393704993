export const SET_STUDENTS_LOADING = 'SET_STUDENTS_LOADING';
export const SET_STUDENTS = 'SET_STUDENTS';
export const ADD_STUDENTS = 'ADD_STUDENTS';
export const SET_STUDENTS_HAS_MORE = 'SET_STUDENTS_HAS_MORE';
export const SET_STUDENTS_PAGE = 'SET_STUDENTS_PAGE';
export const SET_USER_SEARCH = 'SET_USER_SEARCH';

export const setStudentsLoading = (loading) => ({
  type: SET_STUDENTS_LOADING,
  loading,
});
export const setStudents = (students) => ({
  type: SET_STUDENTS,
  students,
});
export const addStudents = (students) => ({
  type: ADD_STUDENTS,
  students,
});
export const setStudentsHasMore = (hasMore) => ({
  type: SET_STUDENTS_HAS_MORE,
  hasMore,
});
export const setStudentsPage = (page) => ({
  type: SET_STUDENTS_PAGE,
  page,
});
export const setUserSearch = (userSearch) => ({
  type: SET_USER_SEARCH,
  userSearch,
});

export const classReducer = (_class, action) => {
  switch (action.type) {
    case SET_STUDENTS_LOADING:
      return {
        ..._class,
        students: {
          ..._class.students,
          loading: action.loading,
        },
      };
    case SET_STUDENTS:
      return {
        ..._class,
        students: {
          ..._class.students,
          data: action.students,
        },
      };
    case ADD_STUDENTS:
      return {
        ..._class,
        students: {
          ..._class.students,
          data: [..._class.students.data, ...action.students],
        },
      };
    case SET_STUDENTS_HAS_MORE:
      return {
        ..._class,
        students: {
          ..._class.students,
          hasMore: action.hasMore,
        },
      };
    case SET_STUDENTS_PAGE:
      return {
        ..._class,
        students: {
          ..._class.students,
          page: action.page,
        },
      };
    case SET_USER_SEARCH:
      return {
        ..._class,
        students: {
          ..._class.students,
          userSearch: action.userSearch,
        },
      };
    default:
      return _class;
  }
};

export const INITIAL_STATE = {
  students: {
    data: [],
    userSearch: '',
    loading: true,
    hasMore: true,
    page: 1,
  },
};
