import { createUseStyles } from 'react-jss';

export default createUseStyles({
  studentsList: {
    padding: '20px',
  },
  studentsTable: {
    padding: '40px 40px',
  },
  studentFilter: {
    minWidth: '250px',
    marginBottom: '20px',
  },
});
