import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { getFileIcon, getFileType } from 'utils';

import useStyles from './styles';

const Dropzone = ({ placeholder, value, minSize, maxSize, invalid, disabled, onDrop, multiple, accept }) => {
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({ multiple: multiple || false, onDrop, disabled, minSize, maxSize, accept });

  const getFile = (filename) => {
    if (getFileType(filename.split('.').pop()) === 'image') {
      return (
        <div>
          <div className={classes.imageContainer}>
            <img
              src={filename}
              alt={filename}
              className={cx(classes.image, 'zoom-image')}
            />
          </div>
          <div className={classes.fileName}>
            {filename.split('/').pop()}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <FontAwesomeIcon
            icon={getFileIcon(filename.split('.').pop())}
            className={classes.fileIcon}
          />
          <div className={classes.fileName}>
            {filename}
          </div>
        </div>
      );
    }
  };

  const getFiles = () => {
    if (!value) {
      return false;
    }

    return value.map(file => {
      const fileName = typeof file === 'string' ? file : file.name;
      return (
        <Col
          key={fileName}
          className={classes.file}
          xl={3}
        >
          {getFile(fileName)}
        </Col>
      );
    });
  };

  return (
    <div>
      <div
        {...getRootProps({
          className: cx(classes.dropzone, { invalid, disabled }),
        })}
      >
        <input
          {...getInputProps()}
        />
        {placeholder}
      </div>
      <Row>
        {getFiles()}
      </Row>
    </div>
  );
};

Dropzone.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.array,
};

export default Dropzone;
