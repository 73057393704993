import React, { useCallback, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { saveAs } from 'file-saver';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import { read } from 'xlsx';

import { selectUserSchool, selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { readWorkbook } from 'utils';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const ADD_FORM = 'ADD_FORM';
const ADDING = 'ADDING';
const ADD_SUCCESS = 'ADD_SUCCESS';

const AddTeachersModal = ({ open, close, resetTeachers, teachersCount }) => {
  const [files, setFiles] = useState([]);
  const schoolId = useSelector(selectUserSpaceId);
  const [addSchoolTeachersRequest] = useApi(api.addSchoolTeachers);
  const toast = useToast();
  const iaveToggle = useFeature(toggles.iave);
  const classes = useStyles();
  const school = useSelector(selectUserSchool);

  const [importState, setImportState] = useState(ADD_FORM);
  const [credentialsCSVFile, setCredentialsCSVFile] = useState(null);
  const [teacherErrors, setTeacherErrors] = useState([]);

  const onDrop = (files) => {
    setFiles(files);
  };

  const close_ = () => {
    setTimeout(() => {
      setFiles([]);
      setCredentialsCSVFile(null);
      setImportState(ADD_FORM);
    }, 300);
    close();
  };

  const createCredentialsFile = useCallback((newTeachers) => {
    const credentialsHeader = `${lang.teachers.addTeachers.fullName},${lang.teachers.addTeachers.username},${lang.teachers.addTeachers.password}\n`;

    const credentialsCSV = newTeachers.reduce((credentialsCSV, teacher) => {
      if (!teacher.password) {
        return credentialsCSV;
      }

      credentialsCSV += `${teacher.fullName};${teacher.username};${teacher.password}\n`;
      return credentialsCSV;
    }, credentialsHeader);

    const csvFile = new Blob([credentialsCSV], { type: 'text/csv;charset=utf-8' });
    setCredentialsCSVFile(csvFile);
  }, []);

  const validateTeachers = useCallback((teachers) => {
    const newTeacherErrors = [];

    teachers.forEach((teacher, teacherIndex) => {
      if (!teacher.fullName && !newTeacherErrors.includes(lang.teachers.addTeachers.nameMissingError)) {
        newTeacherErrors.push(lang.teachers.addTeachers.nameMissingError);
      }

      if (!iaveToggle && !teacher.email && !newTeacherErrors.includes(lang.teachers.addTeachers.emailMissingError)) {
        newTeacherErrors.push(lang.teachers.addTeachers.emailMissingError);
      }

      if (!iaveToggle && teacher.email && !isEmail(teacher.email, { allow_utf8_local_part: false }) && !newTeacherErrors.includes(lang.teachers.addTeachers.emailWrongFormatError)) {
        newTeacherErrors.push(lang.teachers.addTeachers.emailWrongFormatError);
      }

      if (iaveToggle && !teacher.username && !newTeacherErrors.includes(lang.teachers.addTeachers.usernameMissingError)) {
        newTeacherErrors.push(lang.teachers.addTeachers.usernameMissingError);
      }

      const emails = teachers.map(teacher => teacher.email);
      const hasDuplicateEmails = emails.find((email, emailIndex) => email === teacher.email && teacherIndex !== emailIndex);
      if (!iaveToggle && hasDuplicateEmails && !newTeacherErrors.includes(lang.teachers.addTeachers.emailDuplicateError)) {
        newTeacherErrors.push(lang.teachers.addTeachers.emailDuplicateError);
      }

      const usernames = teachers.map(teacher => teacher.username);
      const hasDuplicateUsernames = usernames.find((username, usernameIndex) => username === teacher.username && teacherIndex !== usernameIndex);
      if (iaveToggle && hasDuplicateUsernames && !newTeacherErrors.includes(lang.teachers.addTeachers.usernameDuplicateError)) {
        newTeacherErrors.push(lang.teachers.addTeachers.usernameDuplicateError);
      }
    });

    if (school.maxTeachers && teachers.length + teachersCount > school.maxTeachers) {
      const extraTeachers = teachers.length + teachersCount - school.maxTeachers;
      newTeacherErrors.push(lang.teachers.addTeachers.totalTeachersSurpassedError(school.maxTeachers, extraTeachers));
    }

    if (newTeacherErrors.length !== 0) {
      setTeacherErrors(newTeacherErrors);
      return false;
    }

    return true;
  }, [school, teachersCount, iaveToggle]);

  const confirm = useCallback(() => {
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result;
      const workbook = read(text, { type: 'binary', encoding: 'utf-8' });
      const teachersRaw = readWorkbook(workbook);
      const teachers = teachersRaw.reduce((teachers, teacher, index) => {
        if ((!teacher[0] && !teacher[1]) || index === 0) {
          return teachers;
        }

        return [
          ...teachers,
          iaveToggle
            ? {
              fullName: teacher[0]?.trim(),
              email: null,
              username: teacher[1]?.trim(),
            }
            : {
              fullName: teacher[0]?.trim(),
              email: teacher[1]?.trim(),
              username: null,
            },
        ];
      }, []);

      const isValid = validateTeachers(teachers);

      if (!isValid) {
        return;
      }

      const request = {
        params: {
          schoolId: schoolId,
        },
        data: {
          teachers: teachers,
        },
      };

      setImportState(ADDING);
      setTeacherErrors([]);
      addSchoolTeachersRequest(request, ({ data }) => {
        if (data.status === 0) {
          toast.success(lang.teachers.addTeachers.teachersAddedSuccess);
          resetTeachers();
          if (iaveToggle) {
            createCredentialsFile(data.newUsers);
          }
          setImportState(ADD_SUCCESS);
          return;
        }

        if (data.status !== ERROR && data.status !== CANCELED) {
          setImportState(ADD_FORM);
          toast.error(lang.oops);
        }
      });
    };

    reader.readAsBinaryString(files[0]);
  }, [iaveToggle, addSchoolTeachersRequest, files, resetTeachers, schoolId, toast, createCredentialsFile, validateTeachers]);

  const saveFile = () => {
    let fileName = `${lang.credentials}.csv`;
    if (iaveToggle) {
      fileName = `${lang.credentials}_${moment().format('YYMMDDHHmmss')}.csv`;
    }

    saveAs(credentialsCSVFile, fileName);
  };

  const actions = [
    {
      name: lang.cancel,
      onClick: close_,
      color: 'black',
      hide: importState !== ADD_FORM,
    },
    {
      name: lang.confirm,
      onClick: confirm,
      hide: importState !== ADD_FORM,
    },
  ];

  return (
    <Modal
      open={open}
      close={close_}
      header={lang.teachers.addTeachers.header}
      actions={actions}
      center
      transition
      medium
    >
      <Render when={importState === ADD_FORM}>
        <div>
          {iaveToggle ? lang.teachers.addTeachers.descriptionIAVE : lang.teachers.addTeachers.description}
        </div>
        <div className={classes.downloadContainer}>
          <Button
            onClick={() => window.open(`${process.env.REACT_APP_S3_URL}/resources/${lang.appKeywords.teachers}.xlsx`, '_blank')}
            className={classes.downloadButton}
          >
            <FontAwesomeIcon
              icon={faDownload}
              className={classes.downloadButtonIcon}
            />
            {lang.teachers.addTeachers.exampleDownload}
          </Button>
        </div>
        <Spacer px={20} />
        <Input
          type="file"
          label={lang.teachers.addTeachers.teachersFileLabel}
          placeholder={lang.teachers.addTeachers.teachersFilePlaceholder}
          value={files}
          onDrop={onDrop}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <Spacer px={20} />
        <Render when={teacherErrors.length !== 0}>
          <h4 className={classes.errors}>
            {lang.errors}
            :
          </h4>
          <ul className={classes.errors}>
            {teacherErrors.map(error => (
              <li key={error}>
                {error}
              </li>
            ))}
          </ul>
        </Render>
        <Spacer px={20} />
      </Render>
      <Render when={importState === ADDING}>
        <Loading active />
      </Render>
      <Render when={importState === ADD_SUCCESS}>
        <div className={classes.downloadContainer}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={classes.addSuccessIcon}
          />
          <div>
            {iaveToggle ? lang.teachers.addTeachers.addSuccessDescriptionIAVE : lang.teachers.addTeachers.addSuccessDescription}
          </div>
          <Render when={iaveToggle}>
            <Button
              onClick={saveFile}
              className={classes.downloadButton}
            >
              {lang.download}
            </Button>
          </Render>
        </div>
      </Render>
    </Modal>
  );
};

AddTeachersModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  resetTeachers: PropTypes.func,
  teachersCount: PropTypes.number,
};

export default AddTeachersModal;
