import { createUseStyles } from 'react-jss';

export default createUseStyles({
  examsTable: {
    padding: '40px 40px',
  },
  optionsCol: {
    textAlign: 'start',
    paddingLeft: '23%',
  },
});
