import React, { useCallback, useEffect } from 'react';
import { Table, TableCell, TableRow } from '@intuitivo/outline';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import ClassStudentsActions from '../ClassStudentsActions';
import ClassStudentsRow from 'components/classes/ClassStudentsRow';
import Input from 'components/common/Input';
import Loading from 'components/common/Loading';
import { useClassContext } from 'pages/Class/context';

import useStyles from './styles';

const ClassStudentsList = ({ getClass }) => {
  const classes = useStyles();
  const iaveToggle = useFeature(toggles.iave);

  const { fetchStudents, setStudents, students, setUserSearch } = useClassContext();

  const placeholder = `${lang.students.studentFilters.fullName} / ${iaveToggle ? lang.students.studentFilters.username : lang.students.studentFilters.email}`;

  useEffect(() => {
    fetchStudents(1);
  }, [fetchStudents]);

  const onRowSelect = useCallback((studentId, selected) => {
    setStudents(students.data.map(student =>
      student.id === studentId ? { ...student, selected } : student,
    ));
  }, [setStudents, students.data]);

  return (
    <div className={classes.studentsList}>
      <Input
        type="input"
        label={lang.appKeywords.students}
        placeholder={placeholder}
        value={students.userSearch}
        onChange={(event) => setUserSearch(event.target.value)}
        className={classes.studentFilter}
      />
      <ClassStudentsActions getClass={getClass} />
      <InfiniteScroll
        dataLength={students.data.length}
        next={() => fetchStudents(students.page)}
        hasMore={students.hasMore}
        loader={<Loading active={students.loading} />}
      >
        <Table className={classes.studentsTable}>
          <TableRow header>
            <TableCell>
              {lang.select}
            </TableCell>
            <TableCell>
              {iaveToggle ? lang.appKeywords.username : lang.appKeywords.name}
            </TableCell>
            {!iaveToggle && (
              <TableCell>
                {lang.appKeywords.email}
              </TableCell>
            )}
            <TableCell>
              {lang.students.studentsTable.actions}
            </TableCell>
          </TableRow>
          {students.data.map((student) => (
            <ClassStudentsRow
              key={student.id}
              student={student}
              onSelect={onRowSelect}
              checked={student.selected}
              getClass={getClass}
            />
          ))}
        </Table>
      </InfiniteScroll>
    </div>
  );
};

ClassStudentsList.propTypes = {
  getClass: PropTypes.func,
};
export default ClassStudentsList;
