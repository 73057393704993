import React, { useCallback, useMemo } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Modal from 'components/common/Modal';

const RemoveTeachersModal = ({ open, close, teacherIds, resetTeachers }) => {
  const [removeSchoolTeachersRequest, removeLoading] = useApi(api.removeSchoolTeachers);
  const [deleteSchoolUsersRequest, deleteLoading] = useApi(api.deleteSchoolUsers);
  const schoolId = useSelector(selectUserSpaceId);
  const toast = useToast();
  const iaveToggle = useFeature(toggles.iave);

  const multipleTeachers = useMemo(() => {
    return teacherIds?.length > 1;
  }, [teacherIds]);

  const removeTeachers = useCallback(() => {
    const request = {
      params: {
        schoolId: schoolId,
      },
      data: {
        userIds: teacherIds,
      },
    };

    removeSchoolTeachersRequest(request, ({ data }) => {
      if (data.status === 0) {
        toast.success(multipleTeachers ? lang.teachers.removeTeacher.removeSuccessMultiple : lang.teachers.removeTeacher.removeSuccess);
        resetTeachers();
        close();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [schoolId, teacherIds, removeSchoolTeachersRequest, close, toast, resetTeachers, multipleTeachers]);

  const deleteTeachers = useCallback(() => {
    const request = {
      params: {
        schoolId: schoolId,
      },
      data: {
        userIds: teacherIds,
      },
    };

    deleteSchoolUsersRequest(request, ({ data }) => {
      if (data.status === 0) {
        toast.success(multipleTeachers ? lang.teachers.removeTeacher.deleteSuccessMultiple : lang.teachers.removeTeacher.deleteSuccess);
        resetTeachers();
        close();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
      }
    });
  }, [schoolId, teacherIds, deleteSchoolUsersRequest, close, toast, resetTeachers, multipleTeachers]);

  const actions = [
    {
      name: lang.cancel,
      onClick: close,
      color: 'black',
    },
    {
      name: iaveToggle ? lang.delete : lang.remove,
      onClick: iaveToggle ? deleteTeachers : removeTeachers,
      color: 'red',
      loading: removeLoading || deleteLoading,
    },
  ];

  let header = '';
  if (iaveToggle) {
    header = multipleTeachers ? lang.teachers.removeTeacher.deleteTitleMultiple : lang.teachers.removeTeacher.deleteTitle;
  } else {
    header = multipleTeachers ? lang.teachers.removeTeacher.removeTitleMultiple : lang.teachers.removeTeacher.removeTitle;
  }

  let description = '';
  if (iaveToggle) {
    description = multipleTeachers ? lang.teachers.removeTeacher.deleteTeacherDescriptionMultiple : lang.teachers.removeTeacher.deleteTeacherDescription;
  } else {
    description = multipleTeachers ? lang.teachers.removeTeacher.removeTeacherDescriptionMultiple : lang.teachers.removeTeacher.removeTeacherDescription;
  }

  return (
    <Modal
      open={open}
      close={close}
      header={header}
      actions={actions}
      center
      transition
      small
    >
      {description}
    </Modal>
  );
};

RemoveTeachersModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  teacherIds: PropTypes.array,
  resetTeachers: PropTypes.func,
};

export default RemoveTeachersModal;
