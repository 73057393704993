import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  navbar: {
    height: '56px',
    zIndex: '1001',
    display: 'none',
    backgroundColor: theme.sidebarColor,
    alignItems: 'center',
    '&.open': {
      zIndex: '101',
    },
  },
  mobileLogoWrapper: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    '&.fullWidth': {
      width: '100%',
    },
  },
  overlay: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000',
    opacity: '0%',
    position: 'fixed',
    right: '-100vw',
    '&.open': {
      zIndex: '102',
      right: '0',
      opacity: '58%',
    },
    transition: 'opacity 300ms ease-in-out',
  },
  hamburger: {
    backgroundColor: theme.sidebarColor,
    color: theme.sidebarText,
    borderRadius: 'unset',
    cursor: 'pointer',
    width: '44px',
    height: '44px',
  },
  hamburgerIcon: {
    transform: 'scale(1.40)',
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    navbar: {
      display: 'flex',
    },
  },
}));
